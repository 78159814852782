import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'theming';

import { Svg } from 'MoshtixShared/component-element';

import styles from './styles';
import icons from './icons';

const IconComponent = (props) => {
  const chosenType = icons[props.type];

  if (!chosenType) {
    return null;
  }

  return (
    <Svg
      id={props.id}
      name={props.name}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${chosenType.width} ${chosenType.height}`}
      type={props.type}
      css={styles.containerCss({
        props: {
          ...props,
        },
      })}
    >
      {chosenType.html}
    </Svg>
  );
};

IconComponent.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

IconComponent.defaultProps = {
  className: '',
  id: '',
  name: '',
  css: {},
  type: 'menu',
};

const ThemedIcon = withTheme(IconComponent);
export const Icon = (props) => <ThemedIcon {...props} />;
Icon.propTypes = IconComponent.propTypes;
Icon.defaultProps = IconComponent.defaultProps;

export default Icon;
