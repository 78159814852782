import React, { useState, Fragment } from 'react';
import { withTheme } from 'theming';
import * as glamor from 'glamor';
import { get } from 'object-path';
import { ThemeInterface } from 'Types/theme';
import { Div, Span, H1, H2, H3, P, Button as ButtonElement } from 'MoshtixShared/component-element';
import { Button } from 'MoshtixShared/component-button';
import { QRCode, BarcodeComponent } from 'MoshtixShared/component-qr-code';
import { DropDownButtonOption, DropDownButtonWrapper } from 'MoshtixShared/component-drop-down-button';
import { Icon } from 'MoshtixShared/component-icon';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { ResponsiveGridClass } from 'MoshtixShared/component-responsive-grid';
import { getRegionDateFromUTCDateAndRegion } from 'MoshtixShared/helper-timezone';

import { GetMobileDialog } from '../../../component-get-mobile-dialog';
import Logo from './moshtix-logo.svg';
import NoBarcode from './no-barcode.svg';
import PrintTickets from './print-tickets.svg';
import { EventInterface, TicketInterface } from '../../../../types/app';
import { TicketActions } from '../ticket-actions';
import styles from './styles';

const largeQuery = configuration.getMediaQuery('large');
const { Hide } = new ResponsiveGridClass({ configuration });
interface TicketTitleProps {
  theme: ThemeInterface;
  event: EventInterface;
  ticket: TicketInterface;
  toggleTicketDetails: () => void;
  onCloseClick: () => void;
}

const testcss = glamor.css.keyframes({
  '0%': { opacity: 1, transform: 'scale3d(0.25, 0.25,1)' },
  '100%': { opacity: 0, transform: 'scale3d(1.5,1.5,1)' },
});

const animationContainer = {
  position: 'absolute',
  height: pixelsToRem(596),
  width: '150%',
  top: -447,
  left: '50%',
  transform: 'translateX(-50%)',
  overflow: 'hidden',
  borderRadius: '50%',
  zIndex: -1,
  backgroundImage: 'linear-gradient(113deg, #00C900 45%, #9D47FF 97%)',
  [largeQuery]: {
    width: pixelsToRem(596),
  },
};

const ripples2 = {
  '&::before': {
    content: '""',
    opacity: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -8,
    left: -8,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    boxShadow: '1px 1px 9px 1px rgba(0,0,0,0.2)',
    animation: `${testcss} 6s linear 1.5s infinite`,
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    opacity: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -8,
    left: -8,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    boxShadow: '1px 1px 9px 1px rgba(0,0,0,0.2)',
    animation: `${testcss} 6s linear 3s infinite`,
    zIndex: 1,
  },
};

const ripples = {
  '&::before': {
    content: '""',
    opacity: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -8,
    left: -8,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    boxShadow: '1px 1px 9px 1px rgba(0,0,0,0.2)',
    animation: `${testcss} 6s linear 4.5s infinite`,
    zIndex: 1,
  },
  '&::after': {
    content: '""',
    opacity: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -8,
    left: -8,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    boxShadow: '1px 1px 9px 1px rgba(0,0,0,0.2)',
    animation: `${testcss} 6s linear 6s infinite`,
    zIndex: 1,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TTP_PENDING_TEXT = 'We’ll make this ticket available to you once your payment plan has finalised.';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RESALE_PENDING_TEXT = 'This ticket isn’t available for you to use while your ticket is up for resale.';
const SUPPRESSED_TICKET_TEXT = 'If this ticket needs scanning, we’ll release the barcode closer to the event.';

const renderExplanation = ({
  ticket,
}: {
  scanned: boolean;
  valid: boolean;
  ticketType: { suppressDistribution: boolean };
}): string => {
  if (ticket.ticketType.suppressDistribution === true) {
    return SUPPRESSED_TICKET_TEXT;
  }
  return '';
};

const isTicketResold = ({ ticket }: { resale: { status: string } }): boolean => {
  if (ticket.resale !== null && ticket.resale.status === 'REFUNDED') {
    return false;
  }
  return true;
};

const TicketTitleWithoutTheme: React.SFC<TicketTitleProps> = ({
  event,
  ticket,
  theme,
  order,
  toggleTicketDetails,
  onCloseClick,
}) => {
  const { pdfDownloadUrl, walletDownloadUrl, onTicketMessage = null } = ticket;
  const [getMobileVisible, setGetMobileVisible] = useState(false);
  const entryDate = get(ticket, 'ticketType.entryDate', null);
  const region = get(event, 'venue.address.region', null);
  // check if digital tickets only
  const { digitalTicketsOnly } = event;
  const isUsingBarcode = false;

  const isValidTicket =
    !ticket.scanned && ticket.valid && !ticket.ticketType.suppressDistribution && isTicketResold({ ticket });
  const isPaperTicketOnly = get(ticket, 'ticketType.paperTicketOnly', false);
  const Barcode = () =>
    isUsingBarcode ? (
      <BarcodeComponent value={ticket.barcode || 'INVALID BARCODE'} />
    ) : (
      <Fragment>
        <Hide below="large">
          <QRCode value={ticket.barcode || 'INVALID BARCODE'} renderAs="svg" size={125} level="H" />
        </Hide>
        <Hide above="large">
          <QRCode value={ticket.barcode || 'INVALID BARCODE'} renderAs="svg" size={150} level="H" />
        </Hide>
      </Fragment>
    );

  const BarcodeSection = () => (isValidTicket ? <Barcode /> : <NoBarcode width={150} height={150} />);

  const MustPrintSection = () => <PrintTickets width={150} height={150} />;

  return (
    <Fragment>
      {getMobileVisible && (
        <GetMobileDialog
          onClose={() => {
            setGetMobileVisible(false);
          }}
        />
      )}
      <Div css={styles.ticketTitleContainerCss({ theme })}>
        <Div css={animationContainer}>
          <Div css={ripples} />
          <Div css={ripples2} />
        </Div>
        <Logo style={styles.logoCss} width={72} />
        <Div css={styles.ticketDetailsMobileControlContainer}>
          <Button css={styles.ticketDetailsBackToTicket} onClick={toggleTicketDetails}>
            View Details
            <Icon type="info" color={theme.palette.white} />
          </Button>
          <Button css={styles.ticketDetailsBackToEvent} onClick={onCloseClick}>
            Back to Event
            <Icon type="close" color={theme.palette.white} />
          </Button>
        </Div>

        <Div css={styles.ticketTitleDetailsContainer({ isUsingBarcode })}>
          <Span
            css={styles.qrcodeContainerCss({
              isUsingBarcode,
              theme,
              scanned: ticket.scanned,
            })}
          >
            {isPaperTicketOnly ? <MustPrintSection /> : <BarcodeSection />}
          </Span>
          {isPaperTicketOnly && (
            <Div css={styles.mustPrintWarning({ theme })}>
              <Div css={styles.mustPrintWarningIcon()}>
                <Icon type="print" color="#ffffff" />
              </Div>
              <P css={styles.mustPrintWarningText()}>This ticket must be printed and presented for entry.</P>
            </Div>
          )}
          {!isValidTicket && renderExplanation({ ticket }) !== '' && (
            <P css={styles.noBarcodeExplanation({ theme })}>{renderExplanation({ ticket })}</P>
          )}
          <Div css={styles.downloadButtonCss({ isUsingBarcode })}>
            <DropDownButtonWrapper
              options={[
                <DropDownButtonOption
                  iconLeft="mobile"
                  iconColor={theme.palette.success}
                  onClick={() => {
                    window.open(walletDownloadUrl, '_blank');
                  }}
                >
                  Add to wallet
                </DropDownButtonOption>,
                isValidTicket && (!digitalTicketsOnly || isPaperTicketOnly) && (
                  <DropDownButtonOption
                    iconLeft="pdf"
                    iconColor={theme.palette.success}
                    onClick={() => {
                      window.open(pdfDownloadUrl, '_blank');
                    }}
                  >
                    View / Print PDF
                  </DropDownButtonOption>
                ),
              ]}
            >
              <ButtonElement css={styles.textIndentCss}>
                Download ticket <Icon type="download" css={styles.downloadIconCss({ theme })} />
              </ButtonElement>
            </DropDownButtonWrapper>
          </Div>
          <P css={styles.mobileNote({ theme })}>
            <Icon type="icons-brightness" color={theme.palette.darkerShadow} css={{ marginRight: '10px' }} />
            Up your screen brightness
          </P>
          <H1 css={styles.ticketTypeCss({ theme })}>{ticket.ticketType.name}</H1>
          {entryDate && (
            <H3 css={styles.eventNameCss({ theme })}>
              {getRegionDateFromUTCDateAndRegion({
                utcDate: entryDate,
                region,
                format: 'ddd DD MMM hh:mm a',
              })}
            </H3>
          )}
          <H2 css={styles.eventNameCss({ theme })}>{event.name}</H2>
          {onTicketMessage && <P css={styles.mobileOnTicketMessage({ theme })}>{onTicketMessage}</P>}
          <Div css={styles.ticketActionsTitle}>
            <TicketActions event={event} order={order} ticket={ticket} />
          </Div>
        </Div>
      </Div>
    </Fragment>
  );
};

export const TicketTitle = withTheme(TicketTitleWithoutTheme);
