import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const checkBoxHeightWidth = pixelsToRem(24);

const containerCss = ({ props, state }) =>
  merge(
    {
      position: 'relative',
    },
    !!props.theme &&
      !!props.theme.checkBox &&
      resolveToObject({ input: props.theme.checkBox.containerCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const inputCss = ({ props, state }) =>
  merge(
    {
      appearance: 'none',
      background: props.theme.palette.white,
      display: 'inline-block',
      verticalAlign: 'bottom',
      boxSizing: 'border-box',
      borderStyle: 'solid',
      borderWidth: pixelsToRem(1),
      margin: 0,
      width: checkBoxHeightWidth,
      height: checkBoxHeightWidth,
      ':active': {
        boxShadow: `inset 0 0 ${pixelsToRem(4)} ${props.theme.palette.darkShadow}`,
      },
      ':focus': {
        outline: `transparent solid ${pixelsToRem(3)}`,
      },
    },
    !!props.label && props.labelPosition === 'right' && { marginRight: pixelsToRem(16) },
    !!props.label && props.labelPosition === 'left' && { marginLeft: pixelsToRem(16) },
    !!props.theme &&
      !!props.theme.checkBox &&
      resolveToObject({ input: props.theme.checkBox.inputCss, params: { props, state } }),
    resolveToObject({ input: props.inputCss, params: { props, state } }),
  );

const labelCss = ({ props, state }) =>
  merge(
    !!props.theme &&
      !!props.theme.checkBox &&
      resolveToObject({ input: props.theme.checkBox.labelCss, params: { props, state } }),
    resolveToObject({ input: props.labelCss, params: { props, state } }),
  );

const iconCss = ({ props, state }) =>
  merge(
    {
      position: 'absolute',
      height: checkBoxHeightWidth,
      width: checkBoxHeightWidth,
      pointerEvents: 'none',
    },
    props.labelPosition === 'left' && { marginLeft: pixelsToRem(16) },
    !!props.theme &&
      !!props.theme.checkBox &&
      resolveToObject({ input: props.theme.checkBox.iconCss, params: { props, state } }),
    resolveToObject({ input: props.iconCss, params: { props, state } }),
  );

const root = {
  containerCss,
  iconCss,
  inputCss,
  labelCss,
};

export default root;
