import React from 'react';
import PropTypes from 'prop-types';

interface ContextProviderProps {
  children: JSX.Element[] | JSX.Element;
  contextName: string;
  stateContext: React.Context<any>;
  initialState: object;
}

export class ContextProvider extends React.Component<ContextProviderProps, object> {
  static propTypes = {
    children: PropTypes.node.isRequired,
    contextName: PropTypes.string.isRequired,
    stateContext: PropTypes.shape({}).isRequired,
    initialState: PropTypes.shape({}).isRequired,
  };

  state = { ...this.props.initialState };

  setStatePassThrough = (param: any, _callback?: () => void) => {
    this.setState(param);
  };

  actions = {
    setState: this.setStatePassThrough,
  };

  render() {
    const { stateContext, contextName, children } = this.props;
    const StateContext = stateContext;
    const value = {
      [contextName]: {
        state: this.state,
        actions: this.actions,
      },
    };

    return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
  }
}
