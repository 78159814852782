import { get } from 'object-path';
import { ResalePeriodInterface } from 'Types/resale-period';
import { TicketTypeInterface } from 'Types/ticket-type';
import moment from 'moment';

export const ticketCanResell = (
  {
    resalePeriods = [],
    ticketTypeId = null,
  }: {
    resalePeriods: ResalePeriodInterface[];
    ticketTypeId: number;
  } = { resalePeriods: [], ticketTypeId: null },
) => {
  let resalePeriodDates: { startDate: string; endDate: string } | null = null;
  if (resalePeriods && ticketTypeId) {
    resalePeriods.forEach((resalePeriod: ResalePeriodInterface) => {
      const resalePeriodTicketTypes = get(resalePeriod, 'ticketTypes.items', []);
      const resalePeriodStartDate = get(resalePeriod, 'startDate', null);
      const resalePeriodEndDate = get(resalePeriod, 'endDate', null);
      // check if ticketType is within resale period
      const ticketTypeIsInResalePeriod = resalePeriodTicketTypes.find(
        (ticketTypeConnection: TicketTypeInterface) => ticketTypeConnection.ticketType.id === ticketTypeId,
      );

      // if it is then store the resale period in here
      resalePeriodDates =
        ticketTypeIsInResalePeriod && !resalePeriodDates
          ? { startDate: resalePeriodStartDate, endDate: resalePeriodEndDate }
          : null;
    });
  }
  // see if right now is between the resale start and end dates
  if (resalePeriodDates) {
    return moment().isBetween(moment(resalePeriodDates.startDate), moment(resalePeriodDates.endDate));
  }

  // not in resale period
  return false;
};

export const orderCanResell = (
  {
    orders = [],
    resalePeriods = [],
  }: {
    orders: OrderInterface[];
    resalePeriods: ResalePeriodInterface[];
  } = { orders: [], resalePeriods: [] },
) => {
  const ordersThatCanResell = [];
  orders.forEach((order) => {
    const ticketsInOrder = order.tickets.items;
    ticketsInOrder.forEach((ticket) => {
      if (ticketCanResell({ resalePeriods, ticketTypeId: ticket.ticketType.id })) {
        ordersThatCanResell.push(order);
      }
    });
  });

  return ordersThatCanResell;
};
