import React from 'react';
import { get } from 'object-path';
import { withTheme } from 'theming';

import { Div } from 'MoshtixShared/component-element';
import { TextBox } from 'MoshtixShared/component-text-box';
import { DateBox } from 'MoshtixShared/component-date-box';
import { NumberBox } from 'MoshtixShared/component-number-box';
import { DropDownSimple } from 'MoshtixShared/component-drop-down';
import Label from 'MoshtixShared/component-label';
import CheckBox from 'MoshtixShared/component-check-box';
import { Alert } from 'MoshtixShared/component-alert';
import { styles } from './styles';

interface FormItem {
  type: 'text-box' | 'number-box' | 'date-box' | 'text-area' | 'checkbox';
  id?: string;
  placeholderText?: string;
  label?: string;
}

interface FormConfig {
  title?: string;
  form: FormItem[];
}

interface DynamicFormProps {
  formConfig: FormConfig;
  initialChanges: {};
  onChange: () => void;
}

interface DynamicFormState {
  changes: {};
}

class DynamicFormWithoutTheme extends React.Component<DynamicFormProps, DynamicFormState> {
  state: DynamicFormState = {
    changes: this.props.initialChanges || {},
  };

  handleChange: () => {} = ({ newValue, id }: { newValue: string | number; id: string }) => {
    // override current change object with new change

    this.setState((prevState: DynamicFormState) => {
      const nextState = prevState;
      nextState.changes[id] = newValue;
      this.props.onChange(nextState);
      return nextState;
    });
  };

  renderFormItem: () => void = ({ item }: { item: FormItem }) => {
    const { changes } = this.state;
    const { theme } = this.props;

    let formItem = [];

    if (item.showIfChecked && !item.showOverride) {
      // item has conditional showIfChecked that needs to be met
      // eslint-disable-next-line no-extra-boolean-cast
      if (!Boolean(changes[item.showIfChecked])) {
        return null;
      }
    }
    switch (item.type) {
      case 'content':
        formItem.push(item.content);
        break;
      case 'text-box':
        formItem.push(
          <Div>
            <TextBox
              {...item}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) =>
                this.handleChange({ newValue, id: item.id })
              }
            />
            {item.errorState && <Alert type="error" text={item.errorState} />}
          </Div>,
        );
        break;
      case 'number-box':
        formItem.push(
          <NumberBox
            {...item}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: number }) =>
              this.handleChange({ newValue, id: item.id })
            }
          />,
        );
        break;
      case 'date-box':
        formItem.push(
          <DateBox
            {...item}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) =>
              this.handleChange({ newValue, id: item.id })
            }
          />,
        );
        break;
      case 'text-area':
        formItem.push(
          <TextBox
            multiline
            {...item}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) =>
              this.handleChange({ newValue, id: item.id })
            }
          />,
        );
        break;
      case 'checkbox':
        formItem.push(
          <CheckBox
            {...item}
            checked={changes[item.id] ? changes[item.id] : item.checked}
            onChange={(_event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) =>
              this.handleChange({ newValue, id: item.id })
            }
          />,
        );
        break;
      case 'dropdown':
        // eslint-disable-next-line no-case-declarations
        const selectedItems = get(this.state, `changes.${item.id}`, {});
        formItem.push(
          <Div>
            {item.label && <Label required={item.required}>{item.label}</Label>}
            <DropDownSimple
              {...item}
              onChange={(_event: React.ChangeEvent<HTMLInputElement>, { selectedItem }: { selectedItem: string }) =>
                this.handleChange({ newValue: selectedItem, id: item.id })
              }
              selectedItems={selectedItems}
            />
          </Div>,
        );
        break;
      case 'row':
        // recursive render nested items inside a row
        formItem.push(
          <Div css={styles.rowContainerCss}>
            {item.items.map((rowItem: FormItem) => (
              <Div css={styles.flexColumnCss}>{this.renderFormItem({ item: rowItem })}</Div>
            ))}
          </Div>,
        );
        break;
      default:
        break;
    }

    // eslint-disable-next-line no-extra-boolean-cast
    if (item.showIfChecked && Boolean(changes[item.showIfChecked])) {
      formItem = <Div css={styles.showingHiddenRowCss({ theme })}>{formItem.map((child: JSX.Element) => child)}</Div>;
    }

    return formItem;
  };

  render() {
    const { form } = this.props.formConfig;
    return <Div css={styles.formCss}>{form.map((item: FormItem) => this.renderFormItem({ item }))}</Div>;
  }
}

export const DynamicForm = withTheme(DynamicFormWithoutTheme);
