import { ThemeInterface } from 'Types/theme';

const gradient = {
  attention: `linear-gradient(to right, #00B300 0%, #008000 50%, #008000 100%)`,
};

const palette = {
  darkerBanner: '#E0E0E0',
  darkBanner: '#F0F0F0',
  lightBanner: '#EAEAEA',
  black: '#181818',
  white: '#FFFFFF',
  darkerShadow: '#6B6B6B',
  darkShadow: '#919191',
  shadow: '#D0D0D0',
  lightShadow: '#F0F0F0',
  lighterShadow: '#FAFAFA',
  attention: '#008000',
  lightAttention: '#D1F5D1',
  darkAttention: '#006E00',
  highlight: '#00C900',
  uber: '#9d47ff',
  error: '#D10051',
  lightError: '#FFE7F0',
  warning: '#D64000',
  lightWarning: '#FADCD6',
  success: '#00A500',
  lightSuccess: '#E4FFE4',
  info: '#006AD0',
  lightInfo: '#E7F3FE',
  gradient,
};

const root: ThemeInterface = {
  palette,
  dialog: {},
};

export const theme = root;

export default root;
