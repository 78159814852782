import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { Div } from 'MoshtixShared/component-element';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const browserSupportsGrid = () => (typeof window.CSS !== 'undefined' ? window.CSS.supports('display', 'grid') : false);

const getCellColumnStyle = (columnsString) => {
  if (!columnsString || !columnsString.match(/^\d+of\d+$/)) {
    return {};
  }
  const columnValue = columnsString.split('of')[0];
  const totalValue = columnsString.split('of')[1];
  // !important must override the Grid components set widths
  return merge(
    {},
    !browserSupportsGrid() && { width: `${(100 / totalValue) * columnValue}% !important` },
    browserSupportsGrid() && { gridColumn: `span ${columnValue}` },
  );
};

const getAllBreakpointsCellColumnsStyle = (props) => {
  const returnStyle = (!!props.columns && getCellColumnStyle(props.columns)) || {};

  // return returnStyle with all breakpoints styles added using the reducer below
  return Object.keys(props.configuration.breakpoints).reduce((returnStyleIterating, breakpointName) => {
    const updatingReturnStyle = Object.assign({}, returnStyleIterating);
    const breakpointColumns = props[`${breakpointName}Columns`];
    if (!breakpointColumns) {
      return updatingReturnStyle;
    }
    const mediaQuery = props.configuration.getMediaQuery(breakpointName);
    updatingReturnStyle[mediaQuery] = getCellColumnStyle(breakpointColumns);
    return updatingReturnStyle;
  }, returnStyle);
};

/* istanbul ignore next */
export const gridCellStyle = (props) =>
  merge(
    {},
    !browserSupportsGrid() && props.align === 'top' && { alignSelf: 'flex-start' },
    !browserSupportsGrid() && props.align === 'bottom' && { alignSelf: 'flex-end' },
    !browserSupportsGrid() && props.align === 'center' && { alignSelf: 'center' },
    getAllBreakpointsCellColumnsStyle(props),
    resolveToObject({ input: props.css, params: { props } }),
  );

const Cell = (props) => (
  <Div className="mt-grid-cell" css={gridCellStyle(props)}>
    {props.children}
  </Div>
);

Cell.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  align: PropTypes.oneOf(['', 'top', 'bottom', 'center']), // eslint-disable-line react/no-unused-prop-types
  columns: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.node,
};

Cell.defaultProps = {
  css: {},
  align: '',
  columns: '',
  children: undefined,
};

export default Cell;
