import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Icon from 'MoshtixShared/component-icon';
import { Button as EmotionButton, Span } from 'MoshtixShared/component-element';
import { withTheme } from 'theming';

import styles from './styles';

const ButtonComponent = (props) => (
  <EmotionButton
    css={styles.buttonCss({ props })}
    {...R.omit(['css'])(props)} // rest of the props without css (otherwise it would override line above)
  >
    {props.loading && (
      <Span css={styles.spanIconContainerLeftCss({ props })}>
        <Icon
          className="moshtix-button-icon"
          type="spinner"
          css={{
            ...styles.iconCss({ props }),
            ...styles.spin,
          }}
        />
      </Span>
    )}

    {props.iconLeft && (
      <Span css={styles.spanIconContainerLeftCss({ props })}>
        <Icon
          className="moshtix-button-icon"
          type={props.iconLeft}
          css={{
            ...styles.iconCss({ props }),
          }}
        />
      </Span>
    )}

    {props.buttonType !== 'iconOnly' && (props.children || props.label)}

    {props.iconRight && (
      <Span css={styles.spanIconContainerRightCss({ props })}>
        <Icon
          className="moshtix-button-icon"
          type={props.iconRight}
          css={{
            ...styles.iconCss({ props }),
          }}
        />
      </Span>
    )}
  </EmotionButton>
);

ButtonComponent.propTypes = {
  behaviour: PropTypes.oneOf(['submit', 'reset', 'button']),
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconContainerLeftCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  iconContainerRightCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  iconCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  invertHoverOpacity: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'primary',
    'secondary',
    'secondaryInverted',
    'tertiary',
    'tertiaryAlternate',
    'uber',
    'iconOnly',
  ]),
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  children: PropTypes.node,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  capitalise: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  behaviour: 'button',
  label: undefined,
  css: {},
  iconContainerLeftCss: {},
  iconContainerRightCss: {},
  iconCss: {},
  loading: false,
  disabled: false,
  invertHoverOpacity: false,
  buttonType: 'primary',
  iconLeft: '',
  iconRight: '',
  children: undefined,
  iconColor: null,
  className: '',
  capitalise: true,
};

const ThemedButton = withTheme(ButtonComponent);
export const Button = (props) => <ThemedButton {...props} />;
Button.propTypes = ButtonComponent.propTypes;
Button.defaultProps = ButtonComponent.defaultProps;

export default Button;
