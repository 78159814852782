import React from 'react';
import { Div, Span } from 'MoshtixShared/component-element';
import Label from 'MoshtixShared/component-label';
import { withTheme } from 'theming';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import Chip from 'MoshtixShared/component-chip';
import Icon from 'MoshtixShared/component-icon';
import { TextBoxRaw as TextBox } from 'MoshtixShared/component-text-box';
import ClickOutsideWrapper from 'MoshtixShared/component-click-outside';
import DropDownSelection from './drop-down-selection';
import { mapToDropDown, mapGroupToDropDown } from './drop-down-mapper';
import styles from './styles';
// refactor
const ChipDivider = (props) => <Span css={{ opacity: 0 }}>{props.useSpace ? ' ' : ','}</Span>;
ChipDivider.propTypes = { useSpace: PropTypes.bool };
ChipDivider.defaultProps = { useSpace: false };

// The way this component builds state is very confusing so
// if you're running into issues where a look up version of this component is not correctly building the value try removing the selected value first
// see control-room/src/pages/event/components/event-container/components/component-venue/component.js
// TODO: REFACTOR THIS COMPONENT TO NOT BUILD STATE WITH STATE
class DropDownComponent extends React.Component {
  constructor(props) {
    super(props);

    this.fetchWithDebounce = debounce(this.handleSearchTextEntered, props.searchThrottle).bind(this);

    const selectedItemsAsArray = this.buildSelectedItemsFromMapping({ selectedItems: props.selectedItems });

    const intialState = {
      canFetchMoreData: props.onSearchTextEntered !== null,
      dropDownPosition: props.autoHighlight ? 0 : -1,
      focus: false, // eslint-disable-line react/no-unused-state
      ignoreMouseEvents: false,
      lastKeyCode: null,
      lastPosition: -1,
      matchingIndexes: [],
      placeholderText:
        (selectedItemsAsArray && selectedItemsAsArray.length === 0) || !this.props.multipleShowChips
          ? props.placeholderText
          : '',
      selectedItems: selectedItemsAsArray,
      value: '',
      page: 0,
      loading: false,
      fetchingMore: false,
    };

    this.state = intialState;

    const value = this.buildInitialInputValue();

    const listState = this.buildList({
      selectedItems: selectedItemsAsArray,
      items: props.items,
      historyItems: props.historyItems,
      lastPosition: -1,
      autoHighlight: props.autoHighlight,
    });

    this.state = {
      ...this.state,
      displayItems: listState.displayItems,
      dropDownPosition: listState.dropDownPosition,
      mappedItems: listState.mappedItems,
      value,
    };

    this.input = null;
    this.bindCss();

    this.keys = {
      backSpace: 'Backspace',
      down: 'ArrowDown',
      enter: 'Enter',
      escape: 'Escape',
      space: ' ',
      tab: 'Tab',
      up: 'ArrowUp',
      comma: ',',
    };
  }

  componentDidMount = () => {
    document.addEventListener('mousemove', this.handleMouseMove);
  };

  componentWillReceiveProps = (nextProps) => {
    const itemListChanged = !isEqual(nextProps.items, this.props.items);
    const historyItemListChanged = !isEqual(nextProps.historyItems, this.props.historyItems);
    const selectedItemsListChanged = !isEqual(nextProps.selectedItems, this.props.selectedItems);
    const selectedItemsAsArray = this.buildSelectedItemsFromMapping({ selectedItems: nextProps.selectedItems });

    this.setState({
      loading: false,
      fetchingMore: false,
    });

    if (itemListChanged) {
      const listState = this.buildList({
        selectedItems: selectedItemsAsArray,
        items: nextProps.items,
        historyItems: nextProps.historyItems,
        lastPosition: this.state.dropDownPosition,
        showNoMatch: nextProps.showNoMatch,
        showAdd: nextProps.showAdd,
        autoHighlight: nextProps.autoHighlight,
      });
      this.setState(
        {
          displayItems: listState.displayItems,
          dropDownPosition: listState.dropDownPosition,
          mappedItems: listState.mappedItems,
          selectedItems: selectedItemsAsArray,
        },
        () => {
          const value = this.buildInitialInputValue();
          this.setState({ value });
        },
      );
    } else if (historyItemListChanged || selectedItemsListChanged) {
      if (historyItemListChanged) {
        const historyItems = this.buildHistoryValues({
          historyItems: nextProps.historyItems,
          items: nextProps.items,
        });
        this.setState({
          mappedItems: historyItems,
        });
      }
      if (selectedItemsListChanged) {
        const items = this.buildSelectedItems({
          selectedItems: selectedItemsAsArray,
          items: this.state.mappedItems,
        });
        const dropDownPosition = this.buildDropDownPosition({
          selectedItems: selectedItemsAsArray,
          items,
        });

        this.setState(
          {
            displayItems: items,
            dropDownPosition,
            mappedItems: items,

            placeholderText:
              (selectedItemsAsArray && selectedItemsAsArray.length === 0) || !this.props.multipleShowChips
                ? this.props.placeholderText
                : '',
            selectedItems: selectedItemsAsArray,
          },
          () => {
            // because list has changed, an empty selected items list means drop down has no selected value
            this.setState({ value: this.buildInitialInputValue() });
          },
        );
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
  };

  onRemoveInternal = ({ event, index, item }) => {
    this.props.onRemove(event, { index, item });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  buildDropDownPosition = ({ selectedItems, items, showNoMatch, showAdd, autoHighlight, lastPosition = -1 }) => {
    let index = -1;

    const { simple } = this.props;

    if (this.state.canFetchMoreData && items.length > 0 && !autoHighlight) {
      // when fetching data as the user scrolls, need to return to the last position
      index = lastPosition;
    } else if ((simple && selectedItems.length === 1) || (this.isTypeahead() && selectedItems.length === 1)) {
      // load drop down with the selected item at the top of the scrolling drop down area
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].key === selectedItems[0].key) {
          index = i;
          break;
        }
      }
    } else if (showNoMatch && showAdd && items.length === 0) {
      index = 1;
    } else if (showAdd && items.length === 0) {
      index = 0;
    } else {
      index = autoHighlight ? 0 : -1;
    }

    return index;
  };

  buildSelectedItemsFromMapping = ({ selectedItems }) => {
    let result = [];

    if (selectedItems !== null && selectedItems !== undefined) {
      // covert to array if object passed in
      result = Array.isArray(selectedItems) ? selectedItems : [selectedItems];

      // if all simple types ie selectedItems = ['a', 'b', 'c'] => [{key: 'a', value: 'a'}, {key: 'b', value: 'b'},]
      // allows attachment of simple list to drop down
      if (!result.some((item) => typeof item === 'object')) {
        result = result.map((item) => ({
          key: item,
          value: item,
        }));
      }
      // filter out bad values in the array
      // these have been logged as warnings in the drop-down-mapper
      result = result.filter(
        (item) =>
          item !== undefined &&
          item !== null &&
          item[this.props.dataKeyFieldName] !== null &&
          item[this.props.dataDisplayFieldName] !== null &&
          item[this.props.dataKeyFieldName] !== undefined &&
          // item[this.props.dataDisplayFieldName] !== undefined &&
          item[this.props.dataKeyFieldName].toString().length > 0,
        // &&
        // item[this.props.dataDisplayFieldName].toString().length > 0,
      );

      result = result.map((item) =>
        mapToDropDown({
          item,
          key: this.props.dataKeyFieldName,
          value: this.props.dataDisplayFieldName,
          text: this.props.dataTextFieldName,
        }),
      );
    }

    return result;
  };

  bindCss = () => {
    this.inputCss = () =>
      styles.inputCss({
        props: this.props,
        state: this.state,
      });
    this.containerCss = () =>
      styles.containerCss({
        props: this.props,
        state: this.state,
      });
    this.inputContainerCss = () =>
      styles.inputContainerCss({
        props: this.props,
        state: this.state,
      });
  };

  buildInitialInputValue = () => {
    const { selectedItems } = this.state;

    const getSingleDisplayItem = () => {
      let result = '';
      if (selectedItems[0].value) {
        result = selectedItems[0].value;
      } else if (this.state.displayItems) {
        result = this.state.displayItems.find((item) => item.key === selectedItems[0].key).value;
      } else {
        const matchingItem = this.props.items.find(
          (item) => item[this.props.dataKeyFieldName] === selectedItems[0].key,
        );
        if (matchingItem) {
          result = matchingItem[this.props.dataDisplayFieldName];
        }
      }
      return result;
    };

    let result = '';
    if (!this.props.multiple) {
      if (this.state.canFetchMoreData) {
        // if fetching more the list can change whilst typing.
        // this wiil ensure we don't overwrite users input
        if (this.state.value.length > 0) {
          // need to check if we havent selected an item already though
          // as we can reach this state from the dialog of adding a new venue
          result = this.state.value;
        } else if (selectedItems.length === 1) {
          result = getSingleDisplayItem();
        }
      } else if (selectedItems.length === 1) {
        result = getSingleDisplayItem();
      }
    } else if (this.props.multiple && this.state.value.length > 0) {
      result = this.state.value;
    }
    return result;
  };

  isTypeahead = () => !this.props.simple && !this.props.multiple;

  itemsDisplayCount = () => {
    let result = 0;
    if (this.props.showAdd && this.props.showNoMatch && this.state.displayItems.length === 0) {
      result = 2;
    } else if (this.props.showAdd) {
      result = this.state.displayItems.length + 1;
    } else if (this.props.showNoMatch && this.state.displayItems.length === 0) {
      result = 1;
    } else {
      result = this.state.displayItems.length;
    }
    return result;
  };

  buildSelectedItems = ({ selectedItems, items }) => {
    let newList = items || [];

    const selectedItemsLowerCase = selectedItems
      .filter((item) => item !== null)
      .map((item) => item.key.toString().toLowerCase());

    newList = newList.map((item) => ({
      ...item,
      selected: selectedItemsLowerCase.indexOf(item.key.toString().toLowerCase()) !== -1,
    }));

    return newList;
  };

  buildHistoryValues = ({ historyItems, items }) => {
    const historyItemsLowerCase = historyItems.map((item) => item.key.toString().toLowerCase());
    const newList = items.map((item) => ({
      ...item,
      isHistory: historyItemsLowerCase.indexOf(item.key.toString().toLowerCase()) !== -1,
    }));

    return newList;
  };

  placeFocusOnInput = () => {
    this.input.focus();
  };

  moveFocusFromInput = () => {
    this.input.blur();
  };

  handleSearchTextEntered = () => {
    const { value } = this.state;
    const hasValue = value && value.trim().length > 0;

    if (this.state.canFetchMoreData) {
      this.setState({ page: 0, dropDownPosition: -1, loading: true }, () => {
        this.props.onSearchTextEntered({
          searchText: value,
          page: 0,
        });
        if (hasValue) {
          this.showDropDown();
        } else {
          // JM - removed this as it was firing (after search text delete) onRemove even when no items are in there
          // having to handle error in page
          // this.props.onRemove(null, { index: 0 });
          this.closeDropDown();
        }
      });
    } else {
      // run internal filter on the data as the list is static
      const lowerCaseValue = value.toLowerCase();
      const items = this.state.mappedItems.filter(
        (mappedItem) => mappedItem.isGroup || mappedItem.value.toLowerCase().indexOf(lowerCaseValue) !== -1,
      );
      const dropDownPosition = this.buildDropDownPosition({
        showNoMatch: this.props.showNoMatch,
        showAdd: this.props.showAdd,
        autoHighlight: this.props.autoHighlight,
        selectedItems: [],
        items,
      });

      if (hasValue || (!hasValue && !this.props.multipleQuickAdd)) {
        this.setState({ displayItems: items, dropDownPosition }, this.showDropDown);
      }
    }
  };

  handleTextChange = (event, { newValue }) => {
    this.setState({ value: newValue, fetchPosition: -1, lastPosition: -1 }, () => {
      if (!this.props.simple) {
        this.fetchWithDebounce();
      }
    });
  };

  handleFetchMoreOnScroll = ({ fetchPosition }) => {
    this.setState(
      {
        page: this.state.page + 1,
        lastPosition: this.state.dropDownPosition,
        fetchPosition,
        fetchingMore: true,
      },
      () => {
        const { value } = this.state;
        let searchText = value;

        // if selected item and text matches selected item run default search
        if (this.state.selectedItems.length > 0 && this.state.selectedItems[0].value === value) {
          searchText = '';
        }

        this.props.onSearchTextEntered({
          searchText,
          page: this.state.page,
        });
      },
    );
  };

  handlePositionChange = ({ position }) => {
    this.setState(() => ({ dropDownPosition: position }));
  };

  resetStateOnBlur = () => {
    if (!this.props.multiple) {
      let currentSelected =
        this.state.selectedItems && this.state.selectedItems.length > 0 && this.state.selectedItems[0]
          ? this.state.selectedItems[0].value
          : null;

      if (!currentSelected) {
        if (this.state.displayItems[this.state.dropDownPosition]) {
          currentSelected = this.state.displayItems[this.state.dropDownPosition].value;
        } else {
          currentSelected = '';
        }
      }
      this.setState({
        value: currentSelected,
      });
    }
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      event.stopPropagation();
      this.resetStateOnBlur();
      this.closeDropDown();
      this.moveFocusFromInput();
      // seems like a bug here have to clear out focus
    } else {
      event.preventDefault();
      this.placeFocusOnInput();
    }
  };

  handleMouseMove = () => {
    if (this.state.ignoreMouseEvents) {
      this.setState({ ignoreMouseEvents: false });
    }
  };

  buildList = ({ lastPosition, selectedItems, items, historyItems, showNoMatch, showAdd, autoHighlight }) => {
    // apply selected flags
    const mapper = ({ itemsToMap }) =>
      itemsToMap.map((item) =>
        mapToDropDown({
          item,
          key: this.props.dataKeyFieldName,
          value: this.props.dataDisplayFieldName,
          text: this.props.dataTextFieldName,
          groupKey: this.props.dataGroupKeyFieldName,
        }),
      );

    let mappedItems = items ? mapper({ itemsToMap: items }) : [];

    if (this.props.groups && this.props.groups.length > 0) {
      const groupItemsMapped = this.props.groups.map((groupItem) =>
        mapGroupToDropDown({
          item: groupItem,
          key: this.props.dataKeyFieldName,
          value: this.props.dataDisplayFieldName,
          text: this.props.dataTextFieldName,
        }),
      );
      const sortGroups = (a, b) => {
        // sort by order group appears in groups property
        const indexOfItemA = groupItemsMapped.findIndex((group) => group.key === a.groupKey);
        const indexOfItemB = groupItemsMapped.findIndex((group) => group.key === b.groupKey);
        if (indexOfItemA < indexOfItemB) return -1;
        if (indexOfItemA > indexOfItemB) return 1;
        return 0;
      };

      const sortedByGroupsItems = mappedItems.sort(sortGroups);

      const itemsWithGroupsAdded = [];
      let currentGroupKey = null;
      for (let i = 0; i < sortedByGroupsItems.length; i += 1) {
        const currentItem = sortedByGroupsItems[i];
        if (currentGroupKey !== currentItem.groupKey) {
          const matchingGroup = groupItemsMapped.find((group) => group.key === currentItem.groupKey);
          if (matchingGroup) {
            itemsWithGroupsAdded.push(matchingGroup);
            currentGroupKey = matchingGroup.key;
          }
        }

        itemsWithGroupsAdded.push(currentItem);
      }
      mappedItems = uniqBy(itemsWithGroupsAdded, 'key');
    } else {
      mappedItems = uniqBy(mappedItems, 'key');
    }

    let builtList = this.buildSelectedItems({
      selectedItems,
      items: mappedItems,
      showNoMatch,
      showAdd,
    });

    // apply history flags
    let mappedHistoryItems = historyItems ? mapper({ itemsToMap: historyItems }) : [];
    mappedHistoryItems = uniqBy(mappedHistoryItems, 'key');
    builtList = this.buildHistoryValues({ historyItems: mappedHistoryItems, items: builtList });
    // set drop down position to selected value when simple or not multiple

    const position = this.buildDropDownPosition({
      items: builtList,
      selectedItems,
      lastPosition,
      showNoMatch,
      showAdd,
      autoHighlight,
    });

    return {
      displayItems: builtList,
      mappedItems: builtList,
      dropDownPosition: position,
    };
  };

  handleSelection = (event, params) => {
    const { position } = params;
    const selectedItem = this.state.displayItems[position];
    if (params.isNoMatch || (selectedItem && selectedItem.isGroup)) {
      // do nothing
    } else if (params.isAddNew && !!this.state.value) {
      this.closeDropDown();
      this.props.onAddNew(event, { value: this.state.value });
      this.setState({ value: '' });
    } else {
      this.closeDropDown();

      if (!selectedItem.selected) {
        this.setState({ lastPosition: -1 });
        if (this.isTypeahead() || this.props.multiple) {
          this.setState({ value: '' });
        }
        if (this.props.multiple) {
          this.props.onAdd(event, { selectedItem: selectedItem.item });
          // clear text on multiple after selection
        } else {
          this.props.onChange(event, { selectedItem: selectedItem.item });
        }
      }
    }

    this.placeFocusOnInput();
  };

  handleOnKeyDown = (event) => {
    this.setState({ ignoreMouseEvents: true });

    const { dropDownPosition: position, showDropDown: dropDownShowing } = this.state;
    const isSimple = this.props.simple;
    const isMultiple = this.props.multiple;

    const textEntered = !this.props.simple && this.state.value.length > 0;

    if (event.key === this.keys.tab) {
      this.resetStateOnBlur();
      this.closeDropDown();
      this.moveFocusFromInput(event);
    } else if (isMultiple && !textEntered && event.key === this.keys.backSpace) {
      // remove last item in selected values,
      const index = this.state.selectedItems.length - 1;
      const item = this.state.selectedItems[index];
      this.props.onRemove(event, { index, item });
    } else if (isSimple && event.keyCode > 64 && event.keyCode < 91) {
      // (keycode > 64 && keycode < 91)   || // letter keys
      this.handleTextEnteredOnSimple(event, { position, dropDownShowing });
    } else if (
      (event.key === this.keys.enter ||
        event.key === this.keys.comma ||
        (this.props.enableSpaceSeparator && event.key === this.keys.space)) &&
      this.props.multiple &&
      this.props.multipleQuickAdd
    ) {
      // selection made with multiple drop down but with nothing to select from just quickly add to selected items
      // on enter keypress
      if (this.props.multipleQuickAdd) {
        const matchingItemIndex = this.state.displayItems.findIndex((item) => item.value === this.state.value);
        if (matchingItemIndex !== -1) {
          this.handleSelection(event, { position: matchingItemIndex });
        } else if (this.state.dropDownPosition !== -1) {
          this.handleSelection(event, { position: this.state.dropDownPosition });
        } else {
          this.handleSelection(event, { isAddNew: true });
        }
        this.closeDropDown();
      } else {
        this.handleSelection(event, { isAddNew: true });
      }

      event.preventDefault();
    } else if (dropDownShowing) {
      this.handleKeyEventsWhenDropDownShowing({ event });
    } else {
      this.handleKeyEventsWhenDropDownNotShowing({ event });
    }
  };

  handleKeyEventsWhenDropDownShowing = ({ event }) => {
    const { dropDownPosition: position } = this.state;
    const isSimple = this.props.simple;
    const textEntered = !isSimple && this.state.value.length > 0;
    if (
      position >= 0 &&
      (event.key === this.keys.enter ||
        (!textEntered && event.key === this.keys.space) ||
        (isSimple && event.key === this.keys.space))
    ) {
      // selection made
      const noMatchSelected = this.props.showNoMatch && position === 0 && this.props.items.length === 0;
      if (!noMatchSelected) {
        const isAddNew = position === this.itemsDisplayCount() - 1 && this.props.showAdd;
        this.handleSelection(event, { position, isAddNew });
      }
      event.preventDefault();
    } else if (event.key === this.keys.down) {
      // scrolling down list
      event.preventDefault();
      let nextPosition = position + 1;

      if (this.state.displayItems && this.state.displayItems.length > nextPosition) {
        if (this.state.displayItems[nextPosition].isGroup) {
          nextPosition += 1;
        }
      }
      const maxLength = this.itemsDisplayCount();
      if (nextPosition < maxLength) {
        this.handlePositionChange({ position: nextPosition });
      }
    } else if (event.key === this.keys.up && position >= 0) {
      // scrolling up the list
      event.preventDefault();
      if (isSimple && position === 0) {
        // do nothing, in simple mode don't allow focus back into input
      } else {
        let nextPosition = position - 1;
        if (this.state.displayItems && this.state.displayItems[nextPosition]) {
          if (this.state.displayItems[nextPosition].isGroup) {
            nextPosition -= 1;
          }
        }
        this.handlePositionChange({ position: nextPosition });
      }
    } else if (event.key === this.keys.escape) {
      // close dialog when escape pressed
      this.closeDropDown();
      this.resetStateOnBlur();
    }
  };

  handleKeyEventsWhenDropDownNotShowing = ({ event }) => {
    if (
      (this.props.simple && event.key === this.keys.space) ||
      event.key === this.keys.down ||
      event.key === this.keys.up
    ) {
      // trigger drop down
      event.preventDefault();

      this.showDropDown();
    }
  };

  handleTextEnteredOnSimple = (event, { position, dropDownShowing }) => {
    // simple drop down key entered
    const keyLower = event.key.toString().toLowerCase();
    // lastPosition used so the system can cycle through items matching the same
    // letter, but then reset position when a new letter is selected
    const scrollToNextMatch = () => {
      const firstMatchIndex =
        this.state.matchingIndexes.find((item) => item > position) || this.state.matchingIndexes[0];

      if (firstMatchIndex >= 0) {
        // match found,
        this.handlePositionChange({ position: firstMatchIndex });
        if (!dropDownShowing) {
          // make selection
          this.handleSelection(event, { position: firstMatchIndex });
        }
      } else {
        this.setState({ lastKeyCode: null });
      }
    };

    if (this.state.lastKeyCode !== event.keyCode) {
      // new letter entered, start search from scratch
      const matchingIndexes = [];
      for (let index = 0; index < this.state.mappedItems.length; index += 1) {
        const item = this.state.mappedItems[index];
        if (item.value.toLowerCase().startsWith(keyLower)) {
          matchingIndexes.push(index);
        }
      }
      this.setState({ lastKeyCode: event.keyCode, matchingIndexes }, scrollToNextMatch);
    } else {
      scrollToNextMatch();
    }
  };

  handleOnHoverChange = (event, { index }) => {
    if (!isEqual(this.state.dropDownPosition, index)) {
      this.setState({ dropDownPosition: index });
    }
  };

  showDropDown = () => {
    this.setState(
      {
        showDropDown: true,
        ignoreMouseEvents: true,
      },
      this.placeFocusOnInput,
    );
  };

  closeDropDown = () => {
    this.setState({ showDropDown: false });
  };

  handleInputContainerClick = () => {
    if (this.props.disabled) {
      return;
    }

    if (!this.state.showDropDown && this.props.showDropDownOnFocus) {
      // automatically show items when a fixed list of items

      this.showDropDown();
    } else if (this.props.simple && this.state.showDropDown) {
      this.closeDropDown();
    } else {
      this.placeFocusOnInput();
    }
  };

  handleFocus = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ focus: true }); // eslint-disable-line react/no-unused-state
    if (this.isTypeahead()) {
      this.input.setSelectionRange(this.state.value.length, this.state.value.length);
    } else if (this.props.simple) {
      this.input.setSelectionRange(0, 0);
    }
    if (this.props.autoShowDropDown) {
      this.showDropDown();
    }
  };

  handleBlur = (event) => {
    this.setState({ focus: false }); // eslint-disable-line react/no-unused-state
    if (this.props.multiple && this.props.multipleQuickAdd && this.state.value) {
      const matchingItemIndex = this.state.displayItems.findIndex((item) => item.value === this.state.value);
      if (matchingItemIndex !== -1) {
        this.handleSelection(event, { position: matchingItemIndex });
      } else if (this.state.dropDownPosition !== -1) {
        this.handleSelection(event, { position: this.state.dropDownPosition });
      } else {
        this.props.onAddNew(event, { value: this.state.value });
        this.setState({ value: '' });
      }
    }
  };

  handleClearIconClick = (event) => {
    this.closeDropDown();

    // clear entered text
    this.setState({ value: '' });
    if (this.state.selectedItems.length > 0) {
      // remove selected item
      this.props.onRemove(event, { index: 0 });
    }
  };

  render() {
    return [
      <Div id={this.props.id} css={this.containerCss()} className={this.props.className} innerRef={this.setWrapperRef}>
        <ClickOutsideWrapper onClickOutside={this.handleClickOutside}>
          <Div onMouseUp={this.handleInputContainerClick} css={this.inputContainerCss()}>
            {this.props.multiple &&
              this.props.multipleShowChips &&
              this.state.selectedItems.map((item, index) => (
                <Chip
                  label={[item.value, <ChipDivider useSpace={this.state.selectedItems.length - 1 === index} />]}
                  onRemove={(event) => this.onRemoveInternal({ event, index, item })}
                  disabled={this.props.disabled}
                  onMouseUp={(event) => event.stopPropagation()}
                  key={`chip-in-downdown-${this.props.id}-${item.value}`}
                />
              ))}
            <TextBox
              idInput={this.props.idInput}
              containerInnerCss={styles.containerInnerCss({ props: this.props, state: this.state })}
              css={this.inputCss()}
              disabled={this.props.disabled}
              innerRef={(input) => {
                this.input = input;
              }}
              errorState={this.props.errorState}
              inputCss={styles.innerInputCss({ props: this.props, state: this.state })}
              placeholderCss={styles.placeholderCss({ props: this.props, state: this.state })}
              onBlur={this.handleBlur}
              onChange={this.handleTextChange}
              onFocus={this.handleFocus}
              onKeyDown={this.handleOnKeyDown}
              placeholderText={this.state.placeholderText}
              readOnly={this.props.simple}
              value={this.state.value}
              width="100%"
              onPaste={(event) => {
                if (this.props.onAddNewMultiple) {
                  const pastedText = event.clipboardData.getData('text/plain');
                  // if it contains commas/semicolons then we're assuming someone wants to paste in
                  if (this.props.multiple && !!pastedText.match(/,|;/)) {
                    event.preventDefault();
                    const newValues = pastedText.split(/;|,/);
                    this.props.onAddNewMultiple(event, { newValues });
                  }
                }
              }}
              autoComplete="off"
              autoFocus={this.props.inputFocus}
            />
            {(this.state.loading || this.state.fetchingMore) && (
              <Span css={styles.iconContainerCss({ props: this.props, state: this.state })}>
                <Icon
                  type="spinner"
                  css={{
                    ...styles.iconCss({ props: this.props }),
                    ...styles.spin,
                  }}
                />
              </Span>
            )}
            {this.isTypeahead() &&
              !this.state.loading &&
              !this.state.fetchingMore &&
              this.state.value.length > 0 && (
                <Span onClick={this.handleClearIconClick}>
                  <Icon type="cross" css={styles.iconCss({ props: this.props })} />
                </Span>
              )}
            {this.props.simple && <Icon type="down-arrow" css={styles.iconCss({ props: this.props })} />}
          </Div>
          {this.state.showDropDown &&
            !this.state.loading && (
              <DropDownSelection
                css={this.props.dropDownSelectCss}
                dropDownItemCss={this.props.dropDownItemCss}
                dropDownContainerCss={this.props.dropDownContainerCss}
                dropDownItemTextCss={this.props.dropDownItemTextCss}
                dropDownItemDescriptiveTextCss={this.props.dropDownItemDescriptiveTextCss}
                fetchMore={!!this.props.onSearchTextEntered}
                fetchPosition={this.state.fetchPosition}
                ignoreMouseEvents={this.state.ignoreMouseEvents}
                itemCss={this.props.dropDownSelectItemCss}
                items={this.state.displayItems}
                onFetchMore={this.handleFetchMoreOnScroll}
                onHoverChange={this.handleOnHoverChange}
                onSelection={this.handleSelection}
                position={this.state.dropDownPosition}
                showNoMatch={this.props.showNoMatch}
                noMatchText={this.props.noMatchText}
                showAdd={this.props.showAdd && this.state.value.length > 0}
                showAddText={`ADD "${this.state.value}"`}
                displayClientId={this.props.displayClientId}
              />
            )}
          {this.props.minimal &&
            !this.props.disabled && <Div css={styles.minimalBorderCss({ props: this.props, state: this.state })} />}
        </ClickOutsideWrapper>
      </Div>,

      this.props.footNoteText && <Label type="footNote">{this.props.footNoteText}</Label>,
    ];
  }
}

DropDownComponent.propTypes = {
  id: PropTypes.string,
  idInput: PropTypes.string,
  showAdd: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  className: PropTypes.string,
  css: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  dropDownItemCss: PropTypes.shape(),
  dropDownContainerCss: PropTypes.shape(),
  dropDownItemDescriptiveTextCss: PropTypes.shape(),
  dropDownItemTextCss: PropTypes.shape(),
  dataKeyFieldName: PropTypes.string,
  dataDisplayFieldName: PropTypes.string,
  dataTextFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dataGroupKeyFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  dropDownSelectCss: PropTypes.shape(),
  dropDownSelectItemCss: PropTypes.shape(),
  errorState: PropTypes.bool,
  footNoteText: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  historyItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  iconClearCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  inputContainerCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  inputCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  simple: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  minimal: PropTypes.bool,
  multiple: PropTypes.bool,
  multipleQuickAdd: PropTypes.bool,
  multipleShowChips: PropTypes.bool,
  noMatchText: PropTypes.string,
  onAdd: PropTypes.func,
  onAddNew: PropTypes.func,
  onAddNewMultiple: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onSearchTextEntered: PropTypes.func,
  placeholderText: PropTypes.string,
  searchThrottle: PropTypes.number,
  selectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
    PropTypes.shape(PropTypes.object),
  ]),
  showDropDownOnFocus: PropTypes.bool,
  showNoMatch: PropTypes.bool,
  enableSpaceSeparator: PropTypes.bool,
  inputFocus: PropTypes.bool,
  autoShowDropDown: PropTypes.bool,
  displayClientId: PropTypes.bool,
};

DropDownComponent.defaultProps = {
  autoHighlight: false,
  className: '',
  css: {},
  dropDownItemCss: {},
  dropDownItemDescriptiveTextCss: {},
  dropDownItemTextCss: {},
  dataDisplayFieldName: 'value',
  dataKeyFieldName: 'key',
  dataTextFieldName: 'text',
  disabled: false,
  dropDownSelectCss: {},
  dropDownSelectItemCss: {},
  footNoteText: null,
  groups: null,
  historyItems: [],
  iconClearCss: {},
  id: '',
  idInput: '',
  inputContainerCss: {},
  dropDownContainerCss: {},
  inputCss: {},
  items: [],
  minimal: false,
  multiple: false,
  multipleShowChips: true,
  multipleQuickAdd: false,
  noMatchText: '',
  onAdd: () => {},
  onAddNew: () => {},
  onAddNewMultiple: undefined, // if not provided then normal pasting
  onChange: () => {},
  onRemove: () => {},
  onSearchTextEntered: null,
  placeholderText: '',
  searchThrottle: 300,
  selectedItems: [],
  showAdd: false,
  showDropDownOnFocus: false,
  showNoMatch: false,
  simple: false,
  enableSpaceSeparator: false,
  inputFocus: false,
  autoShowDropDown: false,
  displayClientId: false,
};

const ThemedDropDown = withTheme(DropDownComponent);
const DropDown = (props) => <ThemedDropDown {...props} />;
DropDown.propTypes = DropDownComponent.propTypes;
DropDown.defaultProps = DropDownComponent.defaultProps;

export const ComponentRaw = DropDownComponent;
export default DropDown;
