import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { hexToRgbA } from 'MoshtixShared/helper-html-colour-encoder/src/index';

const largeQuery = configuration.getMediaQuery('large');
const medium = configuration.getMediaQuery('medium');

const ticketTitleContainerCss = ({ theme }: { theme: ThemeInterface }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.white,
  backfaceVisibility: 'hidden',
  zIndex: 2,
  overflow: 'hidden',
  [largeQuery]: {
    position: 'relative',
    height: 'auto',
    zIndex: 0,
    width: '50%',
  },
});

const ticketDetailsMobileControlContainer = {
  position: 'absolute',
  top: pixelsToRem(25),
  left: pixelsToRem(15),
  right: pixelsToRem(15),
  display: 'flex',
  justifyContent: 'space-between',
  [largeQuery]: {
    display: 'none',
  },
};
const ticketDetailsBackToEvent = {
  textIndent: -99999,
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
  minHeight: 0,
  backgroundImage: 'none',
  boxShadow: 'none',
  padding: 0,
  borderColor: 'transparent',
  width: pixelsToRem(24),
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
const ticketDetailsBackToTicket = {
  textIndent: -99999,
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
  minHeight: 0,
  backgroundImage: 'none',
  boxShadow: 'none',
  padding: 0,
  borderColor: 'transparent',
  width: pixelsToRem(24),
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
const ticketTitleDetailsContainer = ({ isUsingBarcode }: { isUsingBarcode: boolean }) => ({
  zIndex: 2,
  position: 'relative',
  margin: isUsingBarcode ? pixelsToRem(96, 17, 30) : pixelsToRem(96, 30, 30),
});
const qrcodeContainerCss = ({
  isUsingBarcode,
  theme,
  scanned,
}: {
  isUsingBarcode: boolean;
  theme: ThemeInterface;
  scanned: boolean;
}) => ({
  zIndex: 2,
  padding: pixelsToRem(12.5),
  backgroundColor: scanned ? theme.palette.darkerShadow : theme.palette.white,
  display: 'inline-block',
  borderRadius: pixelsToRem(4),
  height: isUsingBarcode ? pixelsToRem(143) : pixelsToRem(175),
  width: isUsingBarcode ? pixelsToRem(270) : pixelsToRem(175),
  boxSizing: isUsingBarcode ? 'content-box' : 'border-box',
  position: 'relative',
  svg: {
    display: 'block',
  },
});

const downloadIconCss = ({ theme }: { theme: ThemeInterface }) => ({
  height: pixelsToRem(24),
  width: pixelsToRem(24),
  color: theme.palette.success,
  position: 'absolute',
  top: 0,
  left: 0,
});

const downloadButtonCss = ({ isUsingBarcode }: { isUsingBarcode: boolean }) => ({
  position: 'absolute',
  height: pixelsToRem(24),
  width: pixelsToRem(24),
  top: isUsingBarcode ? pixelsToRem(178) : pixelsToRem(80),
  right: pixelsToRem(20),
  zIndex: 2,
});

const textIndentCss = {
  textIndent: -99999,
  border: 'none',
  background: 'transparent',
};

const ticketTypeCss = ({ theme }: { theme: ThemeInterface }) => ({
  color: theme.palette.black,
  fontSize: pixelsToRem(24),
  marginBottom: pixelsToRem(8),
});

const logoCss = {
  position: 'absolute',
  top: pixelsToRem(25),
  left: '50%',
  transform: 'translateX(-50%)',
};

const eventNameCss = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(18),
  color: hexToRgbA({ hex: theme.palette.black, alpha: 0.6 }),
});

const mobiledownloadButton = {
  display: 'none',
  [largeQuery]: {
    display: 'block',
    width: '100%',
  },
};

const ticketActionsTitle = {
  display: 'block',
  [largeQuery]: {
    display: 'none',
  },
};

const mobileOnTicketMessage = ({ theme }: { theme: ThemeInterface }) => ({
  backgroundColor: theme.palette.black,
  color: theme.palette.white,
  fontSize: pixelsToRem(12),
  padding: pixelsToRem(20),
  borderRadius: pixelsToRem(4),
  textAlign: 'left',
  [largeQuery]: {
    display: 'none',
  },
});

const mobileNote = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(12),
  color: theme.palette.darkerShadow,
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: pixelsToRem(10),
  [largeQuery]: {
    display: 'none',
  },
});

const noBarcodeExplanation = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(14),
  color: theme.palette.white,
  backgroundColor: theme.palette.black,
  padding: pixelsToRem(15, 20),
  borderRadius: pixelsToRem(4),
  [largeQuery]: {
    display: 'none',
  },
});

const mustPrintWarning = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(14),
  color: theme.palette.white,
  backgroundColor: theme.palette.black,
  padding: pixelsToRem(15, 20),
  borderRadius: pixelsToRem(4),
  display: 'flex',
  marginBottom: pixelsToRem(14),
  justifyContent: 'center',
  [largeQuery]: {
    display: 'none',
  },
});

const mustPrintWarningIcon = () => ({
  alignSelf: 'center',
});

const mustPrintWarningText = () => ({
  marginBottom: 0,
  textAlign: 'center',
  marginLeft: pixelsToRem(8),
  [medium]: {
    marginLeft: pixelsToRem(14),
  },
});

export default {
  ticketTitleDetailsContainer,
  ticketTitleContainerCss,
  ticketTypeCss,
  eventNameCss,
  qrcodeContainerCss,
  downloadIconCss,
  downloadButtonCss,
  logoCss,
  textIndentCss,
  ticketActionsTitle,
  mobiledownloadButton,
  ticketDetailsBackToTicket,
  ticketDetailsBackToEvent,
  ticketDetailsMobileControlContainer,
  mobileNote,
  mobileOnTicketMessage,
  noBarcodeExplanation,
  mustPrintWarning,
  mustPrintWarningIcon,
  mustPrintWarningText,
};
