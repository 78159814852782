import React from 'react';
import { A, Div, Ul, Li, Span } from 'MoshtixShared/component-element';
import { withTheme } from 'theming';
import Icon from 'MoshtixShared/component-icon';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import styles from './styles';
// refactor
class DropDownSelectionComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.component = [];
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnHover = this.handleOnHover.bind(this);
    this.handleOnScroll = props.fetchMore ? this.handleOnScroll.bind(this) : null;
    this.fetch = props.fetchMore ? this.fetch.bind(this) : null;
    this.renderListItem = this.renderListItem.bind(this);
    this.isShowingNoMatch = this.isShowingNoMatch.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.scrollIntoViewIfNeeded = this.scrollIntoViewIfNeeded.bind(this);

    this.state = {
      showingNoMatch: this.isShowingNoMatch({ props }),
    };

    this.scrollDirection = {
      up: 'up',
      down: 'down',
    };

    this.dropDownItemCss = ({ item, index }) =>
      styles.dropDownItemCss({
        props: {
          ...this.props,
          currentPosition: this.props.position,
          index,
          isAddNew: item.isAddNew,
          isHistory: item.isHistory,
          isNoMatch: item.isNoMatch,
          isGroup: item.isGroup,
          text: item.text,
        },
      });

    this.dropDownItemTextCss = ({ item }) =>
      styles.dropDownItemTextCss({
        props: {
          ...this.props,
          selected: item.selected,
          isGroup: item.isGroup,
          isNoMatch: item.isNoMatch,
        },
      });

    this.dropDownItemDescriptiveTextCss = () =>
      styles.dropDownItemDescriptiveTextCss({
        props: {
          ...this.props,
        },
      });

    this.dropDownContainerCss = () =>
      styles.dropDownContainerCss({
        props: this.props,
      });

    this.dropDownContainerAddCss = () =>
      styles.dropDownContainerAddCss({
        props: this.props,
      });

    this.subTextCss = () =>
      styles.subTextCss({
        props: this.props,
      });
  }

  componentDidMount() {
    const { position } = this.props;
    const current = this.component[position] || null;

    if (current) {
      current.parentElement.scrollTop = current.offsetTop;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.position < this.props.position) {
      // moving up

      this.scrollIntoView({ scrollDirection: this.scrollDirection.up, props: nextProps });
    } else if (nextProps.position > this.props.position) {
      // moving down

      this.scrollIntoView({ scrollDirection: this.scrollDirection.down, props: nextProps });
    }

    if (!isEqual(this.props.items, nextProps.items) || !isEqual(this.props.showNoMatch, nextProps.showNoMatch)) {
      this.setState({
        showingNoMatch: this.isShowingNoMatch({ props: nextProps }),
      });
    }
  }

  getShowAddIndex({ props }) {
    let result = props.items.length;
    if (this.isShowingNoMatch({ props })) {
      result = +1;
    }
    return result;
  }

  isShowingNoMatch({ props }) {
    return props.items.length === 0 && props.showNoMatch;
  }

  scrollIntoView({ scrollDirection, props }) {
    const { position } = props;
    const previous = this.component[position - 1] ? this.component[position - 1] : null;
    const current = this.component[position] ? this.component[position] : null;
    const next = this.component[position + 1] ? this.component[position + 1] : null;

    if (current && props.ignoreMouseEvents === true) {
      this.scrollIntoViewIfNeeded({
        scrollDirection,
        previous,
        current,
        next,
      });
    }
  }

  scrollIntoViewIfNeeded({ previous, current, scrollDirection, next }) {
    if (
      !next || // allow last entry in drop down when next is null
      (next && // otherwise top of next item greater then the drop down height plus distance scrolled at top not showing
        scrollDirection === this.scrollDirection.down &&
        next.offsetTop >= current.parentElement.getBoundingClientRect().height + current.parentElement.scrollTop)
    ) {
      // scrolling down
      if (current.scrollIntoView) current.scrollIntoView(false);
    } else if (scrollDirection === this.scrollDirection.up && previous) {
      if (current.offsetTop - current.parentElement.scrollTop <= 0) {
        previous.parentNode.scrollTop = previous.offsetTop; // eslint-disable-line no-param-reassign
      }
    }
  }

  handleOnHover(event, { index }) {
    if (!this.props.ignoreMouseEvents) {
      this.props.onHoverChange(event, { index });
    }
  }

  handleOnClick(event, { index, isAddNew, isNoMatch, selected, isGroup }) {
    if (!isGroup) {
      this.props.onSelection(event, {
        isAddNew,
        isNoMatch,
        position: index,
        selected,
      });
    }
  }

  fetch() {
    this.props.onFetchMore({ fetchPosition: this.props.items.length });
  }

  handleOnScroll(event) {
    const startFetchingBeforeBottom = 15;
    const distanceFromBottom = 44 * startFetchingBeforeBottom;
    const reachedScrollTarget =
      event.target.scrollTop + event.target.clientHeight + distanceFromBottom > event.target.scrollHeight; // TODO: calculate this value from height and number of items
    const pastPreviousFetchPoint = this.props.items.length !== this.props.fetchPosition;
    const fetchMore = reachedScrollTarget && pastPreviousFetchPoint;

    if (fetchMore) {
      this.fetch();
    }
  }

  renderListItem(item, index) {
    return (
      <Li css={this.dropDownItemCss({ index, item })} key={item.key}>
        {item.isHistory && <Icon type="history" css={styles.iconHistoryCss({ props: this.props })} />}
        <Div
          innerRef={(component) => {
            this.component[index] = component;
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleOnClick(event, {
              index,
              isAddNew: item.isAddNew,
              isNoMatch: item.isNoMatch,
              isSelected: item.selected,
              isGroup: item.isGroup,
            });
          }}
          onMouseOver={(event) => this.handleOnHover(event, { index })}
          onFocus={(event) => this.handleOnHover(event, { index })}
          css={this.dropDownItemTextCss({ item })}
        >
          {item.value}
        </Div>
        {this.props.displayClientId && !item.isNoMatch && (
          <Span css={this.dropDownItemDescriptiveTextCss({ props: this.props })}>ID {item.key}</Span>
        )}
        {item.selected && <Icon type="tick-alternate" css={styles.iconSelectedCss({ props: this.props })} />}
        {item.text && <Div css={this.subTextCss()}>{item.text}</Div>}
        {item.url && (
          <A href={item.url} target="_blank" css={styles.linkStyleCss({ props: this.props })}>
            <Icon type="external-link" alt="open link in new window" width={20} />
          </A>
        )}
      </Li>
    );
  }

  render() {
    return (
      <Span
        css={styles.dropDownSelectionSpanCss({ props: this.props })}
        onScroll={this.handleOnScroll}
        className={this.props.className}
      >
        <Ul css={this.dropDownContainerCss()} tabIndex="-1">
          {this.props.items.map(this.renderListItem)}
          {this.state.showingNoMatch &&
            this.renderListItem(
              {
                key: uniqueId(),
                value: this.props.noMatchText,
                isNoMatch: true,
              },
              0,
            )}
        </Ul>
        {this.props.showAdd && (
          <Ul css={this.dropDownContainerAddCss()} tabIndex="-1">
            {this.renderListItem(
              {
                key: uniqueId(),
                value: this.props.showAddText,
                isAddNew: true,
                selected: false,
                isShowingNoMatch: false,
              },
              this.getShowAddIndex({ props: this.props }),
            )}
          </Ul>
        )}
      </Span>
    );
  }
}

DropDownSelectionComponent.propTypes = {
  className: PropTypes.string,
  dropDownItemCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  dropDownItemTextCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  fetchMore: PropTypes.bool,
  fetchPosition: PropTypes.number,
  iconHistoryCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  iconSelectedCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  ignoreMouseEvents: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      isAdd: PropTypes.bool,
      isNoMatch: PropTypes.bool,
    }),
  ),
  onFetchMore: PropTypes.func,
  onHoverChange: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  css: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  subTextItemCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  showAdd: PropTypes.bool,
  showAddText: PropTypes.string,
  showNoMatch: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  noMatchText: PropTypes.string,
  displayClientId: PropTypes.bool,
};

DropDownSelectionComponent.defaultProps = {
  className: '',
  fetchMore: false,
  iconHistoryCss: {},
  iconSelectedCss: {},
  ignoreMouseEvents: false,
  dropDownItemCss: {},
  dropDownItemTextCss: {},
  fetchPosition: -1,
  items: [],
  onFetchMore: null,
  css: {},
  subTextItemCss: {},
  showAdd: false,
  showAddText: '',
  showNoMatch: false,
  noMatchText: '',
  displayClientId: false,
};

const ThemedDropDownSelection = withTheme(DropDownSelectionComponent);
const DropDownSelection = (props) => <ThemedDropDownSelection {...props} />;
DropDownSelection.propTypes = DropDownSelectionComponent.propTypes;
DropDownSelection.defaultProps = DropDownSelectionComponent.defaultProps;

export const ComponentRaw = DropDownSelectionComponent;
export default DropDownSelection;
