import React from 'react';
import appConfiguration from 'Configuration';
import { withTheme } from 'theming';

import { Div, A, Img } from 'MoshtixShared/component-element';
import Icon from 'MoshtixShared/component-icon';
import styles from './styles';

const accountPageUrl = `//${appConfiguration.publicWebsiteOriginalUrl}/v2/account`;
const moshtixLogoUrl = `//${appConfiguration.staticAssetsUrl}/images/moshtix-logo-nowhitespace.svg`;

const HeaderWithoutTheme = (props) => (
  <header>
    <A href={accountPageUrl} css={styles.returnLinkCss({ props })}>
      <Div css={styles.returnToAccountBarCss({ props })}>
        <Icon css={styles.returnToAccountIconCss({ props })} type="return" />
        <span>Return to Moshtix Account</span>
      </Div>
    </A>
    <Div css={styles.moshtixLogoContainerCss({ props })}>
      <a href={`//${appConfiguration.publicWebsiteOriginalUrl}`}>
        <Img alt="Moshtix" src={moshtixLogoUrl} css={styles.moshtixLogoCss({ props })} />
      </a>
    </Div>
  </header>
);

export const Header = withTheme(HeaderWithoutTheme);

export default Header;
