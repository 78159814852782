import { TimeUnit } from 'MoshtixShared/helper-time-calc';
import moment from 'moment-timezone';
// This version of spacetime is defaulted to unix formatting which is different from moment
// https://unicode.org/reports/tr35/tr35-25.html#Date_Format_Patterns for reference
export type TimeZones =
  | 'ACT'
  | 'NSW'
  | 'NT'
  | 'QLD'
  | 'SA'
  | 'TAS'
  | 'VIC'
  | 'WA'
  | 'Auckland'
  | 'Bay of Plenty'
  | 'Canterbury'
  | 'Gisborne'
  | "Hawke's Bay"
  | 'Marlborough'
  | 'Manawatu-Wanganui'
  | 'Nelson'
  | 'Northland'
  | 'Otago'
  | 'Southland'
  | 'Tasman'
  | 'Taranaki'
  | 'Wellington'
  | 'Waikato'
  | 'West Coast'
  | 'Fiji'
  | 'Singapore'
  | 'Aceh'
  | 'Bali'
  | 'Bangka Belitung'
  | 'Bengkulu'
  | 'Banten'
  | 'Gorontalo'
  | 'Jambi'
  | 'Jawa Barat'
  | 'Jawa Timur'
  | 'Jakarta Raya'
  | 'Jawa Tengah'
  | 'Kalimantan Barat'
  | 'Kalimantan Timur'
  | 'Kepulauan Riau'
  | 'Kalimantan Selatan'
  | 'Kalimantan Tengah'
  | 'Lampung'
  | 'Maluku'
  | 'Maluku Utara'
  | 'Nusa Tenggara Barat'
  | 'Nusa Tenggara Timur'
  | 'Papua'
  | 'Riau'
  | 'Sulawesi Utara'
  | 'Sumatera Barat'
  | 'Sulawesi Tenggara'
  | 'Sulawesi Selatan'
  | 'Sumatera Selatan'
  | 'Sulawesi Tengah'
  | 'Sumatera Utara'
  | 'Yogyakarta';

// region names - <moshtix state name>: <spacetime region>
export const timeZoneMap = {
  ACT: 'Australia/ACT',
  NSW: 'Australia/NSW',
  NT: 'Australia/North',
  QLD: 'Australia/Queensland',
  SA: 'Australia/South',
  TAS: 'Australia/Tasmania',
  VIC: 'Australia/Victoria',
  WA: 'Australia/West',

  Auckland: 'Pacific/Auckland',
  'Bay of Plenty': 'Pacific/Auckland',
  Canterbury: 'Pacific/Auckland',
  Gisborne: 'Pacific/Auckland',
  "Hawke's Bay": 'Pacific/Auckland',
  Marlborough: 'Pacific/Auckland',
  'Manawatu-Wanganui': 'Pacific/Auckland',
  Nelson: 'Pacific/Auckland',
  Northland: 'Pacific/Auckland',
  Otago: 'Pacific/Auckland',
  Southland: 'Pacific/Auckland',
  Tasman: 'Pacific/Auckland',
  Taranaki: 'Pacific/Auckland',
  Wellington: 'Pacific/Auckland',
  Waikato: 'Pacific/Auckland',
  'West Coast': 'Pacific/Auckland',
  'Chatham Islands': 'Pacific/Chatham',

  Fiji: 'Pacific/Fiji',

  Singapore: 'Asia/Singapore',

  Aceh: 'Asia/Jakarta',
  Bali: 'Asia/Pontianak',
  'Bangka Belitung': 'Asia/Jakarta',
  Bengkulu: 'Asia/Jakarta',
  Banten: 'Asia/Jakarta',
  Gorontalo: 'Asia/Pontianak',
  Jambi: 'Asia/Jakarta',
  'Jawa Barat': 'Asia/Jakarta',
  'Jawa Timur': 'Asia/Jakarta',
  'Jakarta Raya': 'Asia/Jakarta',
  'Jawa Tengah': 'Asia/Jakarta',
  'Kalimantan Barat': 'Asia/Jakarta',
  'Kalimantan Timur': 'Asia/Pontianak',
  'Kepulauan Riau': 'Asia/Jakarta',
  'Kalimantan Selatan': 'Asia/Pontianak',
  'Kalimantan Tengah': 'Asia/Jakarta',
  Lampung: 'Asia/Jakarta',
  Maluku: 'Asia/Jayapura',
  'Maluku Utara': 'Asia/Jayapura',
  'Nusa Tenggara Barat': 'Asia/Pontianak',
  'Nusa Tenggara Timur': 'Asia/Pontianak',
  Papua: 'Asia/Jayapura',
  Riau: 'Asia/Jakarta',
  'Sulawesi Utara': 'Asia/Pontianak',
  'Sumatera Barat': 'Asia/Jakarta',
  'Sulawesi Tenggara': 'Asia/Pontianak',
  'Sulawesi Selatan': 'Asia/Pontianak',
  'Sumatera Selatan': 'Asia/Jakarta',
  'Sulawesi Tengah': 'Asia/Pontianak',
  'Sumatera Utara': 'Asia/Jakarta',
  Yogyakarta: 'Asia/Jakarta',
  'America/Los_Angeles': 'America/Los_Angeles',
};

const standardFormat = 'YYYY-MM-DDTHH:mm:ss.000';
const standardFormatMomentUTC = 'YYYY-MM-DD[T]HH:mm:ss[.000Z]';
const currentTimeZoneName = moment.tz().zoneName();

const getSpaceTimeRegion = ({ region }: { region: TimeZones }): string => timeZoneMap[region] || currentTimeZoneName;

export const getUTCOffsetFromUTCDateAndRegion = ({
  utcDate,
  region,
}: {
  utcDate: string;
  region: TimeZones;
}): string | null => {
  if (utcDate === null) {
    return null;
  }
  const spacetimeRegionName = getSpaceTimeRegion({ region });
  const result = moment
    .utc(utcDate)
    .tz(spacetimeRegionName)
    .format('Z');

  return `(UTC ${result})`;
};

export const getISOFormatFromUTCDateAndRegion = ({
  utcDate,
  region,
}: {
  utcDate: string;
  region: TimeZones;
}): string | null => {
  if (utcDate === null) {
    return null;
  }

  const spacetimeRegionName = getSpaceTimeRegion({ region });
  const result = moment
    .utc(utcDate)
    .tz(spacetimeRegionName)
    .format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');

  return result;
};

export const getUTCDateFromRegionDateAndRegion = ({
  regionDate,
  region,
}: {
  regionDate: string;
  region: TimeZones;
}): string | null => {
  const spacetimeRegionName = getSpaceTimeRegion({ region });
  const regionDateWithoutUTC = regionDate.substring(
    0,
    regionDate.length - +(regionDate.lastIndexOf('Z') === regionDate.length - 1),
  );

  const utcDate = moment
    .tz(regionDateWithoutUTC, spacetimeRegionName)
    .utc()
    .format(standardFormatMomentUTC);
  return utcDate;
};

export const getRegionDateFromUTCDateAndRegion = ({
  utcDate,
  region,
  format,
}: {
  utcDate: string;
  region: TimeZones;
  format: string;
}): string | null => {
  if (utcDate === null) {
    return null;
  }

  const spacetimeRegionName = getSpaceTimeRegion({ region });
  const result = moment
    .utc(utcDate)
    .tz(spacetimeRegionName)
    .format(format || standardFormat);

  return result;
};

export const getStartOfDayInRegion = ({
  region,
  /* istanbul ignore next */
  format = standardFormat,
}: {
  region: TimeZones;
  format: string;
}): string | null => {
  const spacetimeRegionName = getSpaceTimeRegion({ region });
  const date = moment()
    .tz(spacetimeRegionName)
    .startOf('day')
    .format(format);
  return date;
};

export const getMinuteDifferenceBetweenRegions = ({
  oldRegion,
  newRegion,
  oldDate,
}: {
  oldRegion: TimeZones;
  newRegion: TimeZones;
  oldDate: string;
}): number => {
  const oldSpaceTimeRegion = getSpaceTimeRegion({ region: oldRegion });
  const newSpaceTimeRegion = getSpaceTimeRegion({ region: newRegion });
  const dateAtOldRegion = moment.utc(oldDate).tz(oldSpaceTimeRegion);
  const dateAtNewRegion = moment.utc(oldDate).tz(newSpaceTimeRegion);
  const dateWithOffsetOld = moment.utc(dateAtOldRegion.format(standardFormat));
  const dateWithOffsetNew = moment.utc(dateAtNewRegion.format(standardFormat));
  // const difference = dateWithOffsetOld.epoch - dateWithOffsetNew.epoch;
  const difference = dateWithOffsetOld.diff(dateWithOffsetNew, 'minute');
  return difference;
};

export const getNewDateFromRegionChange = ({
  oldRegion,
  newRegion,
  oldDate,
}: {
  oldRegion: TimeZones;
  newRegion: TimeZones;
  oldDate: string;
}): string => {
  const difference = getMinuteDifferenceBetweenRegions({ oldRegion, newRegion, oldDate });
  return `${moment
    .utc(oldDate)
    .add(difference, 'minutes')
    .format(standardFormat)}Z`;
};

export const epochToRelativeTime = ({
  epochDiff,
  pastMessage = 'has already started',
}: {
  epochDiff: number;
  pastMessage: string;
}): string => {
  const years = Math.floor(epochDiff / 31556926000);
  const months = Math.floor(epochDiff / 2629743000);
  const weeks = Math.floor(epochDiff / 604800000);
  const days = Math.floor(epochDiff / 86400000);
  const hours = Math.floor(epochDiff / 3600000);
  const mins = Math.floor(epochDiff / 60000);

  /* eslint-disable */
  return years > 0
    ? `${years} Year${years > 1 ? 's' : ''}`
    : months > 0
    ? `${months} Month${months > 1 ? 's' : ''}`
    : weeks > 0
    ? `${weeks} Week${weeks > 1 ? 's' : ''}`
    : days > 0
    ? `${days} Day${days > 1 ? 's' : ''}`
    : hours > 0
    ? `${hours} Hour${hours > 1 ? 's' : ''}`
    : mins > 0
    ? `${mins} Min${mins > 1 ? 's' : ''}`
    : pastMessage;
  /* eslint-enable */
};

export const getStartInRelativeTime = ({
  startTimeUTC,
  prefix = 'Starts in',
  finishedText = 'This event has already started',
}: {
  startTimeUTC: string;
  prefix: string;
  finishedText: string;
}): string => {
  const currentTime = new Date().getTime();
  const startTime = moment
    .utc(startTimeUTC)
    .toDate()
    .getTime();

  // if  currentTime is greater then finishedText
  return currentTime > startTime
    ? finishedText
    : `${prefix} ${epochToRelativeTime({ epochDiff: startTime - currentTime, pastMessage: finishedText })}`;
};

export const formatDateTimeForCalendar = ({ utcDate, region }: { utcDate: string; region: TimeZones }): string | null =>
  getRegionDateFromUTCDateAndRegion({ utcDate, region, format: 'YYYYMMDDTHHmmss' });

export const calculateDuration = ({
  startDate,
  endDate,
  units = 'hour',
  absolute = true,
}: {
  startDate: string;
  endDate: string;
  units: TimeUnit;
  absolute: bool;
}): number => {
  const diff = moment(startDate).diff(moment(endDate), units);
  return absolute ? Math.abs(diff) : diff;
};

export const getTimeAtTimeZone = ({ tz }: { tz: string }): Date =>
  moment()
    .tz(tz)
    .toDate();
export const getTimeAtRegion = ({ region }: { region: TimeZones }): Date => {
  const tz = getSpaceTimeRegion({ region });
  return moment()
    .tz(tz)
    .toDate();
};

/* istanbul ignore next */
export const getNow = (region: TimeZones): Date =>
  moment()
    .tz(region)
    .toDate();

/* istanbul ignore next */
export const getUtcNow = (): Date => moment.utc().toDate();

export const addTimeToDate = (date: string, amount: number, unit: moment.unitOfTime.DurationConstructor): string =>
  moment(date)
    .add(amount, unit)
    .toISOString();

export const subtractTimeFromDate = (
  date: string,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor,
): string =>
  moment(date)
    .subtract(amount, unit)
    .toISOString();

const root = {
  calculateDuration,
  getUTCDateFromRegionDateAndRegion,
  getRegionDateFromUTCDateAndRegion,
  getStartOfDayInRegion,
  getMinuteDifferenceBetweenRegions,
  getStartInRelativeTime,
  epochToRelativeTime,
  formatDateTimeForCalendar,
  getTimeAtTimeZone,
  getTimeAtRegion,
  addTimeToDate,
  subtractTimeFromDate,
};

export default root;
