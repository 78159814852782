import React from 'react';
import { Div, Button } from 'MoshtixShared/component-element';

import styles from './styles';
import { TicketDetails } from './component-ticket-details';
import { TicketTitle } from './component-ticket-title';

interface TicketProps {
  ticket: any;
  event: any;
  css: any;
  onCloseClick: () => void;
}

interface TicketState {
  ticketDetailsVisible: boolean;
}

export class Ticket extends React.Component<TicketProps, TicketState> {
  state = {
    ticketDetailsVisible: false,
  };

  toggleTicketDetails = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      ticketDetailsVisible: !prevState.ticketDetailsVisible,
    }));
  };

  render() {
    const { ticket, event, order, onCloseClick } = this.props;
    const { ticketDetailsVisible } = this.state;
    return (
      <Div key={ticket.ticketNumber} css={styles.ticketContainerCss}>
        <Div css={styles.innerContainerCss({ ticketDetailsVisible })}>
          <TicketTitle
            ticket={ticket}
            event={event}
            order={order}
            toggleTicketDetails={this.toggleTicketDetails}
            onCloseClick={onCloseClick}
          />
          <TicketDetails
            ticket={ticket}
            event={event}
            order={order}
            toggleTicketDetails={this.toggleTicketDetails}
            onCloseClick={onCloseClick}
          />
        </Div>
      </Div>
    );
  }
}
