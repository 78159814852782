import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

import { DropdownButtonWrapperProps } from './index';

const mediumQuery: string = configuration.getMediaQuery('medium');

const containerCss: {} = ({ props }: { props: DropdownButtonWrapperProps }) =>
  merge(
    {
      position: 'relative',
    },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.containerCss, params: { props } }),
    resolveToObject({ input: props.css, params: { props } }),
  );

const menuContainerCss: {} = ({
  props,
  isMenuVisible,
}: {
  props: DropdownButtonWrapperProps;
  isMenuVisible: boolean;
}) =>
  merge(
    {
      position: 'absolute',
      top: '100%',
      right: 0,
      zIndex: 1,
      borderRadius: pixelsToRem(4),
      boxShadow: `${pixelsToRem(2)} ${pixelsToRem(2)} ${pixelsToRem(10)} 0 rgba(0, 0, 0, 0.1)`,
    },
    {
      'button:first-child:last-child': {
        borderRadius: '4px 4px 4px 4px',
        paddingTop: pixelsToRem(10),
        paddingBottom: pixelsToRem(10),
      },
      'button:first-child': {
        borderRadius: '4px 4px 0 0',
        paddingTop: pixelsToRem(20),
      },
      'button:last-child': {
        borderRadius: '0 0 4px 4px',
        paddingBottom: pixelsToRem(20),
      },
      'button:last-child span': {
        marginBottom: pixelsToRem(0),
      },
    },
    // eslint-disable-next-line prettier/prettier
    props.leftAlignOnSmallViewport && { left: 0, right: 'auto', [mediumQuery]: { right: 0, left: 'auto' } },
    props.alignLeft && { left: 0, right: 'auto', [mediumQuery]: { left: 0, right: 'auto' } },
    props.lowerMenu && { top: `calc(100% + ${pixelsToRem(3)})` }, // if tertiary button then it looks much better if menu is outside the outline
    props.dropDownMenuWidth && { width: '100%', [mediumQuery]: { width: `${pixelsToRem(props.dropDownWidth)}` } },
    isMenuVisible && { zIndex: 9999 },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.menuContainerCss, params: { props } }),
    resolveToObject({ input: props.menuContainerCss, params: { props } }),
  );

export const styles: {} = {
  containerCss,
  menuContainerCss,
};
