import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const containerCss = ({ theme }: { theme: ThemeInterface }): {} => ({
  height: pixelsToRem(44),
  borderRadius: pixelsToRem(4),
  boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.2)',
  backgroundImage: 'linear-gradient(98deg, #00c900 13%, #9d47ff 87%)',
  position: 'fixed',
  bottom: pixelsToRem(25),
  left: pixelsToRem(25),
  zIndex: 8,
  width: 'calc(100% - 50px)',
  border: 0,
  fontSize: pixelsToRem(16),
  fontWeight: 600,
  color: theme.palette.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    opacity: 1,
  },
});

const iconCss = ({ theme }: { theme: ThemeInterface }): {} => ({
  marginRight: pixelsToRem(9),
  color: theme.palette.white,
});

export const styles = {
  containerCss,
  iconCss,
};
