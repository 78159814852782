import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import fetch from 'unfetch';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { get } from 'object-path';

const hasUnauthorisedError = (response) => {
  const errorMessage = get(response, 'errors.0.message', '');

  if (errorMessage.includes('Unauthorised') || errorMessage.includes('expired')) {
    return true;
  }

  return false;
};

const root = ({ graphqlUrl, redirectUrl }) => {
  const cache = new InMemoryCache();

  const stateLink = withClientState({
    cache,
    resolvers: {},
    defaults: {},
  });

  const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename); // eslint-disable-line no-param-reassign
    }

    return forward(operation);
  });

  const checkAuthorisedLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      if (hasUnauthorisedError(response)) {
        if (window.location.search.includes('debug')) {
          console.log('UNAUTHORISED REQUEST - redirecting'); // eslint-disable-line no-console
        } else {
          window.location.href = redirectUrl;
        }
      }
      return response;
    }),
  );

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      cleanTypenameLink,
      stateLink,
      checkAuthorisedLink,
      new HttpLink({ uri: graphqlUrl, fetch }),
    ]),
  });

  return client;
};

export default root;
