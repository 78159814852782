import { css } from 'glamor';

css.global(
  'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, audio, time, mark, video',
  {
    margin: '0',
    padding: '0',
    border: '0',
    fontSize: '100%',
    fontFamily: 'inherit',
    verticalAlign: 'baseline',
  },
);

css.global('html, body', {
  fontFamily: "'Open Sans', sans-serif;",
  fontWeight: 400,
  margin: 0,
  padding: 0,
  touchAction: 'manipulation',
  textSizeAdjust: '100%',
});
