import React from 'react';
import { History } from 'history';
import { history } from '../../app/history';

interface WithHistoryProps {
  history: History;
}

interface WithHistoryState {
  historyLengthOnMount: number;
}

export const withHistory = <P extends WithHistoryProps>(WrappedComponent: React.ComponentType<P>) =>
  class WithHistory extends React.Component<P, WithHistoryState> {
    static WrappedComponent = WrappedComponent;

    handleClose = () => {
      const { entries } = history;
      let eventIndex: number | null = null;
      const eventPageRegex = RegExp('^(/event/[0-9]*)$');
      // need to reverse as its latest first
      entries
        .slice(0)
        .reverse()
        // eslint-disable-next-line array-callback-return
        .map((entry: { pathname: string }, index: number) => {
          // find the event page
          if (eventPageRegex.test(entry.pathname) && eventIndex === null) {
            eventIndex = index;
          }
        });

      if (eventIndex) {
        history.go(eventIndex * -1);
      } else {
        const eventUrlRegex = RegExp('^(/event/[0-9]*/)');
        // couldnt find it in history so just replace current URL
        // This can happen if linked to a ticket without being on the event page first
        const eventUrl = entries[entries.length - 1].pathname.match(eventUrlRegex);
        // eslint-disable-next-line prefer-destructuring
        window.location.href = eventUrl[0];
      }
    };

    render() {
      return <WrappedComponent {...this.props} onCloseClick={this.handleClose} />;
    }
  };
