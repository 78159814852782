import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import glamorous from '@emotion/styled';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const browserSupportsGrid = () => (typeof window.CSS !== 'undefined' ? window.CSS.supports('display', 'grid') : false);

const getAllBreakpointsMarginStyle = (props) =>
  Object.keys(props.configuration.breakpoints).reduce((returnStyleIterating, breakpointName) => {
    const updatingReturnStyle = Object.assign({}, returnStyleIterating);
    const rowMargin = props.rowMargin || pixelsToRem(props.configuration.breakpoints[breakpointName].gutterWidth);
    const colMargin = props.colMargin || pixelsToRem(props.configuration.breakpoints[breakpointName].gutterWidth);
    const mediaQuery = props.configuration.getMediaQuery(breakpointName);
    updatingReturnStyle[mediaQuery] = merge(
      {},
      !browserSupportsGrid() && {
        margin: `0 0 -${rowMargin} -${colMargin}`,
        ' > .mt-grid-cell': {
          padding: `0 0 ${rowMargin} ${colMargin}`,
        },
      },
      browserSupportsGrid() && {
        gridRowGap: rowMargin,
        gridColumnGap: colMargin,
      },
    );
    return updatingReturnStyle;
  }, {});

const getAllBreakpointsGridColumnsStyle = (props) => {
  const noBrowserSupportAndColumns = {
    ' > .mt-grid-cell': {
      flex: 'none',
      width: `calc(100%/${props.columns})`,
    },
  };
  const returnStyle = merge(
    { overflow: 'visible' },
    !browserSupportsGrid() && !!props.columns && noBrowserSupportAndColumns,
    browserSupportsGrid() && {
      gridTemplateColumns: `repeat(${props.columns || props.children.length}, 1fr)`,
    },
  );

  return Object.keys(props.configuration.breakpoints).reduce((returnStyleIterating, breakpointName) => {
    const updatingReturnStyle = Object.assign({}, returnStyleIterating);
    const breakpointColumns = props[`${breakpointName}Columns`];
    if (!breakpointColumns) {
      return updatingReturnStyle;
    }
    const mediaQuery = props.configuration.getMediaQuery(breakpointName);
    updatingReturnStyle[mediaQuery] = merge(
      {},
      !browserSupportsGrid() && {
        ' > .mt-grid-cell': {
          flex: 'none',
          width: `calc(100%/${breakpointColumns})`,
        },
      },
      browserSupportsGrid() && {
        gridTemplateColumns: `repeat(${breakpointColumns}, 1fr)`,
      },
    );
    return updatingReturnStyle;
  }, returnStyle);
};

export const gridGridStyle = (props) =>
  merge(
    {},
    !browserSupportsGrid() && {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'hidden',
      alignItems: 'flex-start', // aligned to the top by default
      ' > .mt-grid-cell': {
        boxSizing: 'border-box',
        flex: 1,
      },
    },
    !browserSupportsGrid() && props.align === 'bottom' && { alignItems: 'flex-end' },
    browserSupportsGrid() && {
      display: 'grid',
    },
    browserSupportsGrid() && props.align === 'bottom' && { alignItems: 'end' },
    props.align === 'center' && { alignItems: 'center' }, // works for both
    getAllBreakpointsMarginStyle(props),
    getAllBreakpointsGridColumnsStyle(props),
    resolveToObject({ input: props.css, params: { props } }),
  );

const Grid = glamorous('div')(gridGridStyle);

Grid.propTypes = {
  css: PropTypes.oneOfType([
    // eslint-disable-line react/no-unused-prop-types
    PropTypes.object,
    PropTypes.func,
  ]),
  rowMargin: PropTypes.string,
  colMargin: PropTypes.string,
  align: PropTypes.oneOf(['', 'bottom', 'center']),
  columns: PropTypes.string,
  children: PropTypes.node,
};

Grid.defaultProps = {
  css: {},
  rowMargin: undefined,
  colMargin: undefined,
  align: '',
  columns: '',
  children: undefined,
};

export default Grid;
