import React from 'react';
import { NakedDialog } from './naked-dialog';
import { DialogComponentProps, DialogWithBody } from './dialog-with-body';

interface DialogProps extends DialogComponentProps {
  history?: History;
  title?: string;
}

export const Dialog: React.FunctionComponent<DialogProps> = (props: DialogProps) => {
  // eslint-disable-next-line react/prop-types
  const DialogComponent = props.title ? DialogWithBody : NakedDialog;
  return <DialogComponent {...props} />;
};

export default Dialog;
