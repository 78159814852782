import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const iconCss = {
  marginRight: pixelsToRem(10),
};

const headerCss = {
  alignItems: 'start',
};

export default {
  headerCss,
  iconCss,
};
