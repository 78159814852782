import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

const largeQuery = configuration.getMediaQuery('large');
const smallQuery = configuration.getMediaQuery('small');

const ticketContainerCss = {
  height: '100vh',
  width: '100vw',
  [largeQuery]: {
    height: pixelsToRem(440),
    width: pixelsToRem(720),
  },
};

const innerContainerCss = ({ ticketDetailsVisible }: { ticketDetailsVisible: boolean }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  paddingBottom: pixelsToRem(80),
  transition: 'transform 0.8s ease-in-out',
  transformStyle: 'preserve-3d',
  transform: ticketDetailsVisible ? 'rotateY(180deg)' : 'rotateY(0deg)',
  [largeQuery]: {
    display: 'flex',
    ' > *': {
      width: '50%',
    },
  },
  ' > *': {
    width: '100%',
  },
});

const detailsHeadingCss = ({ theme }: { theme: ThemeInterface }) => ({
  fontFamily: "'Oswald', 'Open Sans', sans-serif",
  fontSize: pixelsToRem(18),
  color: theme.palette.darkerShadow,
  marginBottom: 0,
  display: 'none',
  [largeQuery]: {
    display: 'block',
  },
});

const noBarcodeExplanation = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(14),
  color: theme.palette.white,
  backgroundColor: theme.palette.black,
  padding: pixelsToRem(15, 20),
  borderRadius: pixelsToRem(4),
  marginTop: pixelsToRem(24),
  [smallQuery]: {
    display: 'none',
  },
  [largeQuery]: {
    display: 'block',
  },
});

const mustPrintWarning = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(14),
  color: theme.palette.white,
  backgroundColor: theme.palette.black,
  padding: pixelsToRem(15, 20),
  borderRadius: pixelsToRem(4),
  marginTop: pixelsToRem(24),
  justifyContent: 'space-between',
  [smallQuery]: {
    display: 'none',
  },
  [largeQuery]: {
    display: 'flex',
  },
});

const mustPrintWarningIcon = () => ({
  alignSelf: 'center',
});

const mustPrintWarningText = () => ({
  marginBottom: 0,
  textAlign: 'center',
});

const actionContainer = {
  display: 'flex',
  marginTop: pixelsToRem(30),
  textAlign: 'left',
  textDecoration: 'none',
  '> *:last-child': {
    marginLeft: pixelsToRem(16),
    flex: 1,
  },
};
const iconCircle = ({ active, theme }: { active: boolean; theme: ThemeInterface }) => ({
  backgroundColor: active ? theme.palette.success : theme.palette.white,
  height: 36,
  width: 36,
  borderRadius: '50%',
  position: 'relative',
  border: `2px solid ${active ? theme.palette.success : theme.palette.black}`,
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    path: {
      fill: active ? theme.palette.white : theme.palette.black,
    },
  },
});
const ticketDetailsLabelCss = {
  fontWeight: 600,
  marginBottom: 0,
  textTransform: 'uppercase',
};

const ticketDetailsLabelCssSentenceCase = ({ theme }: { theme: ThemeInterface }) => ({
  fontWeight: 600,
  marginBottom: 0,
  color: theme.palette.black,
});

const ticketActionTextCss = ({ theme }: { theme: ThemeInterface }) => ({
  fontSize: pixelsToRem(14),
  color: theme.palette.darkerShadow,
  marginBottom: 0,
});

const onTicketMessage = ({ theme }: { theme: ThemeInterface }) => ({
  backgroundColor: theme.palette.black,
  color: theme.palette.white,
  fontSize: pixelsToRem(12),
  padding: pixelsToRem(20),
  borderRadius: pixelsToRem(4),
  marginTop: pixelsToRem(16),
  textAlign: 'left',
  display: 'none',
  [largeQuery]: {
    display: 'block',
  },
});

export default {
  ticketDetailsLabelCss,
  ticketActionTextCss,
  ticketContainerCss,
  ticketDetailsLabelCssSentenceCase,
  actionContainer,
  detailsHeadingCss,
  innerContainerCss,
  iconCircle,
  onTicketMessage,
  noBarcodeExplanation,
  mustPrintWarning,
  mustPrintWarningIcon,
  mustPrintWarningText,
};
