import React, { useState } from 'react';
import { get } from 'object-path';
import { compose } from 'react-apollo';
import { withTheme } from 'theming';
import Configuration from 'Configuration';
import { withState } from 'MoshtixShared/component-context-provider';
import CheckBox from 'MoshtixShared/component-check-box';
import Button from 'MoshtixShared/component-button';
import Section from 'MoshtixShared/component-section';
import { H2, Div, P, A } from 'MoshtixShared/component-element';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { ResponsiveGridClass } from 'MoshtixShared/component-responsive-grid';

import { TicketDistroContext } from '../../../app/contexts';
import { GetMobileDialog } from '../../../components/component-get-mobile-dialog';
import { ResellDialog } from '../../../components/component-resell-dialog';
import { TicketListItem } from '../component-ticket-list-item';
import { TicketDialogBar } from '../component-ticket-dialog-bar-mobile';
import { ticketCanResell, orderCanResell } from '../helper-resale-checker';
import styles from './styles';

const { Hide } = new ResponsiveGridClass({ configuration });

const TicketListWithoutThemeAndState = (props) => {
  const [selected, setSelected] = useState([]);
  const [getMobileVisible, setGetMobileVisible] = useState(false);
  const [resellVisible, setResellVisible] = useState(false);

  const handleToggleTicketItem = ({ ticketId }) => {
    const newSelected = selected.includes(ticketId)
      ? selected.filter((val) => val !== ticketId)
      : selected.concat(ticketId); // staying away from mutating selected with push
    setSelected(newSelected);
  };

  const allAvailableTickets = props.ticketDistroContext.state.getOrders.items.reduce(
    (acc, curr) => acc.concat(curr.tickets.items),
    [],
  );

  const allTicketIds = props.ticketDistroContext.state.getOrders.items;
  const { event } = props.ticketDistroContext.state.getOrders.items[0];

  const resalePeriods = get(event, 'resalePeriods.items', []);
  const orders = get(props, 'ticketDistroContext.state.getOrders.items', []);
  const ordersThatCanResell = orderCanResell({ orders, resalePeriods });
  const clientId = get(event, 'client.id', 0);
  // enable download pdf for ATC since they don't want to use ticket modal
  const disableTicketDialog = clientId === 9154;

  const handleToggleAll = ({ ticketIds }) => (selected.length ? setSelected([]) : setSelected(ticketIds));
  return (
    <Section css={styles.backgroundCss({ props })} innerContainerCss={styles.innerBackgroundCss({ props })}>
      {getMobileVisible && (
        <GetMobileDialog
          onClose={() => {
            setGetMobileVisible(false);
          }}
        />
      )}
      {resellVisible && (
        <ResellDialog
          orders={ordersThatCanResell}
          onClose={() => {
            setResellVisible(false);
          }}
        />
      )}
      {!disableTicketDialog && (
        <Hide above="medium">
          <TicketDialogBar tickets={allAvailableTickets} event={event} />
        </Hide>
      )}
      <Div css={styles.buttonBarCss({ props })}>
        {ordersThatCanResell.length > 0 && (
          <CheckBox
            css={styles.selectAllCheckCss({ props })}
            onChange={() =>
              handleToggleAll({
                ticketIds: allAvailableTickets.map((ticket) => ticket.id),
              })
            }
            checked={Boolean(selected.length)}
            indeterminate={Boolean(selected.length && selected.length !== allAvailableTickets.length)}
          />
        )}

        {/*
          REMOVED FOR PHASE 1
        <Button
          buttonType="tertiary"
          iconLeft="curved-arrow-right"
          css={styles.buttonBarItemCss({ props })}
          iconColor={props.theme.palette.success}
        >
          SEND
        </Button> */}
        {ordersThatCanResell.length > 0 && (
          <Button
            buttonType="tertiary"
            iconLeft="hand-with-coin"
            css={styles.buttonBarItemCss({ props })}
            iconColor={props.theme.palette.success}
            onClick={() => {
              setResellVisible(true);
            }}
          >
            RESELL
          </Button>
        )}
      </Div>
      {props.ticketDistroContext.state.getOrders.items.map((order) =>
        order.tickets.items.map((ticket) => (
          <TicketListItem
            ticket={ticket}
            order={order}
            event={event}
            disableTicketDialog={disableTicketDialog}
            canResell={ticketCanResell({ resalePeriods, ticketTypeId: ticket.ticketType.id })}
            checked={selected.includes(ticket.id)}
            key={ticket.id}
            onChange={() => handleToggleTicketItem({ ticketId: ticket.id })}
          />
        )),
      )}

      <Div css={styles.tempSpaceCss({ props })} />
      <Div css={styles.furtherInformation}>
        <H2 css={styles.headingCss({ props })}>Further information</H2>
        {event.ageRestriction === 'OVER18' && (
          <Div css={styles.ageWarningCss({ props })}>You must be 18 or over to attend this event.</Div>
        )}
        <Div>{event.ticketFinePrint}</Div>
        <P>
          View the{' '}
          <A href={`https://${Configuration.publicWebsiteOriginalUrl}/v2/event/${event.id}/terms-and-conditions`}>
            Terms &amp; Conditions
          </A>{' '}
          for this event
        </P>
      </Div>
    </Section>
  );
};

export const TicketList = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  withTheme,
)(TicketListWithoutThemeAndState);
