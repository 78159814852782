const breakpoints = {
  small: {
    minimumWidth: 1,
    columns: 4,
    gutterWidth: 16,
  },
  medium: {
    minimumWidth: 600,
    columns: 8,
    gutterWidth: 20,
  },
  large: {
    minimumWidth: 840,
    columns: 12,
    gutterWidth: 20,
  },
  extraLarge: {
    minimumWidth: 1240,
    columns: 12,
    gutterWidth: 20,
  },
  extraExtraLarge: {
    minimumWidth: 1440,
    columns: 12,
    gutterWidth: 20,
  },
};

const getMediaQuery = (breakpointName) => `@media(min-width: ${breakpoints[breakpointName].minimumWidth}px)`;

export const configuration = {
  breakpoints,
  getMediaQuery,
};

const root = {
  configuration,
};

export default root;
