import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

import { ThemeInterface } from 'Types/theme';

const cardCss = ({ theme, checked }: { theme: ThemeInterface; checked: boolean }) => ({
  height: pixelsToRem(108),
  borderRadius: pixelsToRem(4),
  backgroundColor: checked ? theme.palette.info : theme.palette.white,
  color: checked ? theme.palette.white : theme.palette.black,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  transition: 'background-color 250ms',
  textDecoration: 'none',
  paddingLeft: pixelsToRem(40),
  // ':hover': {
  //   backgroundColor: convertHexToRgb(theme.palette.white, 0.4),
  // },
  '::before': {
    content: '""',
    position: 'absolute',
    top: -4,
    left: -11,
    height: 19,
    width: 31,
    transform: 'rotate(-45deg)',
    backgroundImage: 'linear-gradient(180deg, #00C900 0%, #9D47FF 100%)',
    zIndex: 2,
  },
});

const cardContainerCss = ({ isFocused }: { isFocused: boolean }) => ({
  position: 'relative',
  marginTop: pixelsToRem(18, 25),
  marginBottom: pixelsToRem(30),
  boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
  zIndex: isFocused ? 999 : 1,
});

const flagCss = ({ theme }: { theme: ThemeInterface }) => ({
  minWidth: pixelsToRem(22),
  minHeight: pixelsToRem(22),
  backgroundImage: 'linear-gradient(135deg, #00000000, #00000000)', // JELLY TODO: chagne this to SVG or rotated div
  alignSelf: 'start',
});

const checkBoxCss = ({ theme }: { theme: ThemeInterface }) => ({
  position: 'absolute',
  top: '50%',
  left: pixelsToRem(20),
  transform: 'translateY(-50%)',
  zIndex: 2,
});

const nameAndIconCss = ({ theme }: { theme: ThemeInterface }) => ({
  display: 'flex',
  alignItems: 'center',
});

const ticketTypeNameCss = ({ theme }: { theme: ThemeInterface }) => ({
  fontFamily: 'Oswald',
});

const nameContainerCss = ({ theme }: { theme: ThemeInterface }) => ({
  width: '100%',
  position: 'relative',
  zIndex: 0,
});

const ticketNameCss = ({ theme, checked }: { theme: ThemeInterface; checked: boolean }) => ({
  fontFamily: 'Open Sans',
  fontSize: pixelsToRem(14),
  fontWeight: 600,
  color: checked ? theme.palette.white : theme.palette.darkerShadow,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '+ *': {
    marginLeft: pixelsToRem(4),
  },
});

const ticketIconCss = ({ theme, checked }: { theme: ThemeInterface; checked: boolean }) => ({
  width: '15px',
  marginRight: pixelsToRem(8),
  color: checked ? theme.palette.white : theme.palette.darkerShadow,
});

const menuButtonCss = ({ theme }: { theme: ThemeInterface }) => ({
  width: pixelsToRem(30),
  marginRight: pixelsToRem(30),
  color: theme.palette.darkerShadow,
});

const cardButtonCss = {
  position: 'absolute',
  overflow: 'hidden',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  borderColor: 'transparent',
  boxShadow: 'none',
  transition: 'none',
  '::before': {
    content: '""',
    position: 'absolute',
    top: -4,
    left: -11,
    height: 19,
    width: 31,
    transform: 'rotate(-45deg)',
    backgroundImage: 'linear-gradient(180deg, #00C900 0%, #9D47FF 100%)',
  },
};

const extraOptionsCss = {
  position: 'absolute',
  right: -5,
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
};

export default {
  cardButtonCss,
  cardContainerCss,
  cardCss,
  ticketNameCss,
  ticketTypeNameCss,
  ticketIconCss,
  nameAndIconCss,
  flagCss,
  checkBoxCss,
  menuButtonCss,
  nameContainerCss,
  extraOptionsCss,
};
