import React from 'react';
import { compose } from 'react-apollo';
import { withTheme } from 'theming';
import { withState } from 'MoshtixShared/component-context-provider';
import { Dialog } from 'MoshtixShared/component-dialog';
import { Carousel } from 'MoshtixShared/component-carousel';
import { ThemeInterface } from 'Types/theme';
import { configuration as responsiveConfiguration } from 'MoshtixShared/helper-responsive-configuration';
// eslint-disable-next-line import/no-named-as-default
import ResponsiveGridClass from 'MoshtixShared/component-responsive-grid';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

import { TicketInterface } from '../../types/app';
import { TicketDistroContext } from '../../app/contexts';
import { history } from '../../app/history';

import { Ticket } from './component-ticket';
import styles from './styles';

const { Hide } = new ResponsiveGridClass({ configuration: responsiveConfiguration });

interface TicketDialogProps {
  theme: ThemeInterface;
  match: {
    isExact: boolean;
    params: {
      id: string;
      eventId: string;
      ticketId: string;
    };
    path: string;
    url: string;
  };
  onCloseClick: () => void;
}

const TicketDialogWithoutTheme: React.SFC<TicketDialogProps> = (props) => {
  const tickets = props.ticketDistroContext.state.getOrders.items.reduce(
    (acc: [], curr: any) =>
      acc.concat(
        curr.tickets.items.map((ticket: TicketInterface) => (
          <Ticket
            ticket={ticket}
            key={ticket.id}
            order={props.ticketDistroContext.state.getOrders.items[0]}
            event={props.ticketDistroContext.state.getOrders.items[0].event}
            onCloseClick={props.onCloseClick}
          />
        )),
      ),
    [],
  );

  const handleCarouselChange = ({ index, selectedChild }: { index: number; selectedChild: React.ReactElement }) => {
    const eventId = props.ticketDistroContext.state.getOrders.items[0].event.id;
    const ticketId = selectedChild.key;
    history.push(`/event/${eventId}/ticket/${ticketId}`);
  };

  const commonCarouselProps = {
    key: props.match.params.ticketId,
    childCss: styles.cardBackground,
    selectedKey: props.match.params.ticketId,
    onChange: handleCarouselChange,
  };

  return (
    <Dialog onCloseClick={props.onCloseClick} mobileBreakpoint={800}>
      <Hide below="medium">
        {
          // desktop carousel
        }
        <Carousel
          {...commonCarouselProps}
          carouselHeight={pixelsToRem(440)}
          carouselWidth={pixelsToRem(720)}
          carouselPadding={pixelsToRem(0)}
          noScrolling
        >
          {tickets}
        </Carousel>
      </Hide>
      <Hide above="medium">
        {
          // mobile carousel
        }
        <Carousel
          {...commonCarouselProps}
          carouselHeight="100vh"
          carouselWidth="100vw"
          carouselPadding={pixelsToRem(0)}
        >
          {tickets}
        </Carousel>
      </Hide>
    </Dialog>
  );
};

export const TicketDialog = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  withTheme,
)(TicketDialogWithoutTheme);
