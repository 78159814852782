import React, { useState, useEffect } from 'react';
import { graphql, compose } from 'react-apollo';
import tokenStore from 'MoshtixShared/helper-token-store';
import configuration from 'Configuration/index';
import { withState } from 'MoshtixShared/component-context-provider';
import noop from 'lodash/noop';
import { get } from 'object-path';

import { history } from '../../app/history';
import { loginWithAccessToken } from './data-definition';
import { EventPage } from '../../pages/event';
import { TicketDistroContext } from '../../app/contexts';

const loginWithAccessTokenMutation = graphql(loginWithAccessToken, {
  props: ({ mutate }) => ({
    login: (variables) => mutate({ variables }),
  }),
});

interface AccessTokenProps {
  match: {
    params: {
      accessToken: string;
    };
  };
  login: () => void;
}

const loginAsync = async ({
  accessToken,
  login,
}: {
  accessToken: string;
  login: ({ accessToken }: { accessToken: string }) => void;
}) => {
  const returnData: string = await login({ accessToken });
  return returnData;
};

const AccessTokenLoginWithoutState: React.FunctionComponent<AccessTokenProps> = ({
  match,
  login,
  location,
  ticketDistroContext,
}: AccessTokenProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const setState = get(ticketDistroContext, 'actions.setState', noop);
  useEffect(() => {
    if (!isLoggedIn) {
      const { accessToken } = match.params;
      loginAsync({ accessToken, login }).then(({ data }: { data: { login: string } }) => {
        const jwt = data.login || null;
        if (jwt) {
          tokenStore.setTicketDistroToken({ token: jwt, domain: configuration.cookieDomain });
          tokenStore.setAccessToken({ token: accessToken, domain: configuration.cookieDomain });
        }
        setIsLoggedIn(true);
      });
    } else {
      if (location.search.includes('walkUpMode=1')) {
        setState({ walkUpMode: true });
      }
      history.push('/');
    }
  }, [isLoggedIn]);

  return <EventPage />;
};

export const AccessTokenLogin = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  loginWithAccessTokenMutation,
)(AccessTokenLoginWithoutState);
