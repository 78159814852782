import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { ThemeInterface } from 'Types/theme';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { hexToRgbA } from 'MoshtixShared/helper-html-colour-encoder';

const mediumQuery = configuration.getMediaQuery('medium');

const viewportCss = {
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const carouselFrameCss = ({
  carouselHeight,
  carouselWidth,
  carouselPadding,
  theme,
}: {
  carouselHeight: number | string;
  carouselWidth: number | string;
  carouselPadding: number | string;
  theme: ThemeInterface;
}): {} => ({
  backgroundColor: theme.palette.white,
  borderRadius: pixelsToRem(4),
  overflowY: 'scroll',
  overflowX: 'hidden',
  width: `calc(${carouselWidth} + (2 * ${carouselPadding}))`,
  height: `calc(${carouselHeight} + (2 * ${carouselPadding}))`,
  [mediumQuery]: {
    overflow: 'visible',
  },
});

const carouselCss = ({
  carouselHeight,
  carouselWidth,
}: {
  carouselHeight: number | string;
  carouselWidth: number | string;
}): {} => ({
  width: carouselWidth,
  height: carouselHeight,
});

const scrollContainerCss = {
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  overflowY: 'hidden',
  width: '100%',
  height: '100%',
  // '-webkit-overflow-scrolling': 'touch',
  margin: 0,
  padding: 0,
  paddingLeft: 0,
  listStyle: 'none',
  '@supports (scroll-snap-align: start)': {
    'scroll-snap-type': 'x mandatory',
  },
  '@supports not (scroll-snap-align: start)': {
    '-webkit-scroll-snap-type': 'mandatory',
    'scroll-snap-type': 'mandatory',
    '-webkit-scroll-snap-destination': '0% center',
    'scroll-snap-destination': '0% center',
    '-webkit-scroll-snap-points-x': 'repeat(100%)',
    'scroll-snap-points-x': 'repeat(100%)',
  },
  [mediumQuery]: {
    display: 'block',
    overflow: 'visible',
    position: 'relative',
    '@supports (scroll-snap-align: start)': {
      'scroll-snap-type': 'none',
    },
    '@supports not (scroll-snap-align: start)': {
      '-webkit-scroll-snap-type': 'none',
      'scroll-snap-type': 'none',
      '-webkit-scroll-snap-destination': 'none',
      'scroll-snap-destination': 'none',
      '-webkit-scroll-snap-points-x': 'none',
      'scroll-snap-points-x': 'none',
    },
  },
};

const scrollItemOuterCss = ({
  selected,
  next,
  prev,
  theme,
}: {
  selected: boolean;
  next: boolean;
  prev: boolean;
  theme: ThemeInterface;
}): {} => ({
  width: '100%',
  height: '100%',
  display: 'list-item',
  '@supports (scroll-snap-align: start)': {
    'scroll-snap-align': 'center',
    'scroll-snap-stop': 'always',
  },
  '@supports not (scroll-snap-align: start)': {
    'scroll-snap-coordinate': '0 0',
    'scroll-snap-stop': 'always',
  },
  [mediumQuery]: {
    ':before': {
      content: '""',
      display: selected ? 'none' : 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: hexToRgbA({ hex: theme.palette.black, alpha: 0.3 }),
    },
    zIndex: selected ? 3 : 1,
    position: 'absolute',
    top: '50%',
    // eslint-disable-next-line no-nested-ternary
    left: next ? '60%' : prev ? '40%' : '50%',
    transform: `translate(-50%, -50%) ${!selected ? 'scale(0.9)' : ''}`,
    '@supports (scroll-snap-align: start)': {
      'scroll-snap-align': 'none',
      'scroll-snap-stop': 'none',
    },
    '@supports not (scroll-snap-align: start)': {
      'scroll-snap-coordinate': 'none',
      'scroll-snap-stop': 'none',
    },
  },
});

const scrollItemCss = {
  width: '40vw',
  height: '100%',
};

const navigationDots = ({ selected, theme }: { selected: boolean; theme: ThemeInterface }): {} => ({
  textIndent: -9999,
  height: 10,
  width: 10,
  border: 0,
  padding: 0,
  borderRadius: '50%',
  backgroundColor: selected ? theme.palette.darkerShadow : theme.palette.shadow,
  '& + *': {
    marginLeft: pixelsToRem(10),
  },
  [mediumQuery]: {
    backgroundColor: theme.palette.white,
    opacity: selected ? 1 : 0.5,
  },
});

const navigationContainer = ({ theme }: { theme: ThemeInterface }): {} => ({
  position: 'absolute',
  bottom: 0,
  height: 80,
  left: 0,
  right: 0,
  zIndex: 4,
  borderTop: `1px solid ${theme.palette.shadow}`,
  boxSizing: 'border-box',
  opacity: 0.95,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.white,
  [mediumQuery]: {
    marginTop: pixelsToRem(30),
    backgroundColor: 'transparent',
    border: 'none',
    top: 'calc(50% + 420px/2)',
    left: '50%',
    bottom: 'auto',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
  },
});

const navButtonContainer = {
  display: 'none',
  [mediumQuery]: {
    display: 'block',
  },
};

const navButtonPrev = (): {} => ({
  display: 'block',
  textIndent: -99999,
  position: 'absolute',
  height: 430,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: 'calc(50% - 840px/2)',
  width: 60,
  border: 'none',
  background: 'transparent',
});

const navButtonNext = {
  display: 'block',
  textIndent: -99999,
  position: 'absolute',
  height: 430,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: 'calc(50% + 840px/2)',
  width: 60,
  border: 'none',
  background: 'transparent',
};

const navButtonIcons = ({ theme }: { theme: ThemeInterface }): {} => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.white,
});

export const styles = {
  carouselCss,
  carouselFrameCss,
  navButtonContainer,
  navButtonPrev,
  navButtonNext,
  navButtonIcons,
  navigationContainer,
  navigationDots,
  scrollContainerCss,
  scrollItemOuterCss,
  scrollItemCss,
  viewportCss,
};
