import React, { Fragment, useRef, useEffect } from 'react';
import { withTheme } from 'theming';
import noop from 'lodash/noop';

import { A, Button, Div } from 'MoshtixShared/component-element';
import Icon from 'MoshtixShared/component-icon';
import { useFocusVisible } from 'MoshtixShared/component-with-focus-visible';
import { ThemeInterface } from 'Types/theme';

import { styles } from './styles';

export interface DropdownButtonOptionProps {
  id: string;
  type: 'regular' | 'link';
  subText: string;
  css: () => {} | {};
  children: JSX.Element[] | JSX.Element;
  iconLeft: string;
  disabled: boolean;
  onClick: () => void;
  onKeyDown: () => void;
  setFocusIndex: () => void;
  focusIndex: number;
  totalItems: number;
  theme: ThemeInterface;
  isFocussed: boolean;
  href?: string;
  target?: string;
}

const sameDomainCheck = ({ href }: { href: string }) => {
  const pageLocation = window.location;
  const URL_HOST_PATTERN = /(\w+:)?(?:\/\/)([\w.-]+)?(?::(\d+))?\/?/;
  // matches a url (part1)//(part2):(part3)
  // index 0 is the full string that matched
  const urlMatch = URL_HOST_PATTERN.exec(href) || [];
  const urlparts = {
    protocol: urlMatch[1] || '',
    host: urlMatch[2] || '',
    port: urlMatch[3] || '',
  };
  // eslint-disable-next-line no-extra-boolean-cast
  return Boolean(urlparts.host && urlparts.host === pageLocation.hostname) ? '_self' : '_blank';
};

const createButtonOrLink = (props: DropdownButtonOptionProps, content: JSXElement[]) => {
  let buttonOrLink = null;
  if (props.href) {
    // if there is no target set then assume we want it on a new window if not from same domain
    buttonOrLink = (
      <A {...{ ...props, target: props.target ? props.target : sameDomainCheck({ href: props.href }) }}>{content}</A>
    );
  } else {
    buttonOrLink = <Button {...props}>{content}</Button>;
  }
  return buttonOrLink;
};

const DropdownButtonOptionWithoutTheme: React.FunctionComponent = (props: DropdownButtonOptionProps) => {
  // Deconstruction with defaults. Most will never get defaulted but in there just in case.
  /* istanbul ignore next */
  const {
    id = undefined,
    type = 'regular',
    subText = '',
    css = {},
    children = undefined,
    iconLeft = undefined,
    disabled = false,
    onKeyDown = noop,
    onClick = noop,
    focusIndex = null,
    setFocusIndex = noop,
    totalItems = 1,
    isFocussed = false,
    triggerRef = null,
    href = null,
    target = null,
  } = props;

  // eslint-disable-next-line @typescript-eslint/typedef
  const optionRef = useRef<HTMLButtonElement>(null);

  const { focusVisible, onFocus, onBlur } = useFocusVisible();

  useEffect(
    () => {
      // only focus if the component is mounted
      if (isFocussed) {
        optionRef.current.focus();
      }
    },
    [isFocussed],
  );

  const contentInsideButtonOrLink = (
    <Fragment>
      {iconLeft && <Icon type={iconLeft} css={styles.optionIconCss({ props })} />}
      <Div css={styles.optionContentCss({ props })}>
        {children}
        <Div css={styles.optionSubTextCss({ props })}>{subText}</Div>
      </Div>
    </Fragment>
  );

  return createButtonOrLink(
    {
      ...props,
      innerRef: optionRef,
      onMouseOver: onFocus,
      onMouseOut: onBlur,
      onFocus,
      onBlur,
      css: styles.optionCss({ ...props, focusVisible }),
      onKeyDown: (event: Event) => onKeyDown({ event, focusIndex, setFocusIndex, totalItems, triggerRef }),
      onClick: (params: any) => {
        if (!disabled) {
          onClick(params);
        }
      },
    },
    contentInsideButtonOrLink,
  );
};

export const DropdownButtonOption: React.FunctionComponent = withTheme(DropdownButtonOptionWithoutTheme);
