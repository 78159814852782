import React, { ReactChild } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withTheme } from 'theming';
import { Global, css } from '@emotion/core';
import Icon from 'MoshtixShared/component-icon';
import { Div, H2, Img, Span } from 'MoshtixShared/component-element';
import noop from 'lodash/noop';
import { keyCodes } from 'MoshtixShared/helper-key-code';

import { Overlay } from 'MoshtixShared/component-overlay';
import { Button } from 'MoshtixShared/component-button';

import { ThemeInterface, DialogInterface } from 'Types/theme';

import { styles } from './styles';

export interface DialogComponentSharedProps extends DialogInterface {
  children: ReactChild;
  onCloseClick?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  theme?: ThemeInterface;
  // eslint-disable-next-line react/no-unused-prop-types
  css?: object | (() => object);
  contentHeight?: number;
  show?: boolean;
}

export interface DialogComponentProps extends DialogComponentSharedProps {
  buttonContent?: React.JSXElement;
  title?: string;
  hideButtonContent?: boolean;
  id?: string;
  dialogRootTarget?: string;
  bannerImgSrc?: string;
  closeWithEsc: boolean;
  hideCloseButton?: boolean;
}

export class DialogComponent extends React.Component<DialogComponentProps, {}> {
  /* eslint-disable */
  static defaultProps = {
    buttonContent: undefined,
    children: undefined,
    closeButtonCss: {},
    containerButtonsCss: {},
    containerCss: {},
    containerDialogCss: {},
    containerInnerCss: {},
    dialogRootTarget: '.dialog-root',
    headerCss: {},
    bannerCss: {},
    bannerContainerCss: {},
    bannerImgSrc: '',
    hideButtonContent: false,
    id: '',
    onCloseClick: (): void => noop,
    closeOnOutsideClick: true,
    overlayCss: {},
    show: false,
    title: '',
    contentHeight: undefined,
    closeWithEsc: false,
  };

  static propTypes = {
    children: PropTypes.node,
    // eslint-disable-next-line react/no-unused-prop-types
    buttonContent: PropTypes.node,
    title: PropTypes.string,
    show: PropTypes.bool,
    hideButtonContent: PropTypes.bool,
    onCloseClick: PropTypes.func,
    closeOnOutsideClick: PropTypes.bool,
    id: PropTypes.string,
    dialogRootTarget: PropTypes.string,
    bannerImgSrc: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    contentHeight: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    closeButtonCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    containerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    containerDialogCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    containerInnerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    containerButtonsCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    headerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    bannerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    bannerContainerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    // eslint-disable-next-line react/no-unused-prop-types
    overlayCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    closeWithEsc: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
  };
  /* eslint-enable */
  componentDidMount(): void {
    /* istanbul ignore next */
    const { show = false, dialogRootTarget = '.dialog-root' } = this.props;
    this.dialogRoot = document.querySelector(dialogRootTarget) as HTMLElement;
    if (this.dialogRoot) {
      this.dialogRoot.appendChild(this.element);
      this.toggleShow(show);
    }
    document.addEventListener('keydown', this.escFunction.bind(Event, this.props), false);
  }

  componentWillReceiveProps(newProps: DialogComponentProps): void {
    /* istanbul ignore next */
    const { show = false } = newProps;
    this.toggleShow(show);
  }

  componentWillUnmount(): void {
    /* istanbul ignore else */
    if (this.dialogRoot && this.element) {
      // if unmounting the dialog should remove showing-dialog from body
      this.toggleShow(false);
      this.dialogRoot.removeChild(this.element);
    }
    document.removeEventListener('keydown', this.escFunction, false);
  }

  element: HTMLDivElement = document.createElement('div') as HTMLDivElement;
  dialogRoot: HTMLElement = document.createElement('template') as HTMLElement;

  toggleShow(show: boolean): void {
    if (show) {
      document.body.classList.add('showing-dialog');
    } else {
      document.body.classList.remove('showing-dialog');
    }
  }
  /* istanbul ignore next */
  escFunction(propsValue: DialogComponentProps, event: any): void {
    if (propsValue.show && event.keyCode === keyCodes.escape) {
      propsValue.onCloseClick();
    }
  }

  render(): React.JSXElement {
    if (!this.props.show) {
      return null;
    }

    const { props } = this;
    const { buttonContent, iconLeft, iconRight, headerSubtext, hideCloseButton } = props;

    const bannerImg = this.props.bannerImgSrc ? (
      <Div css={styles.bannerContainerCss({ props })}>
        <Img css={styles.bannerCss({ props })} alt="Banner" src={this.props.bannerImgSrc} />
      </Div>
    ) : null;

    const returnComponent = (
      <Div id={this.props.id} className="moshtix-dialog-scroll-container" css={styles.containerCss({ props })}>
        <Overlay
          onClick={this.props.closeOnOutsideClick ? this.props.onCloseClick : null}
          visible
          css={styles.overlayCss({ props })}
        />
        <Global
          styles={css`
            body.showing-dialog {
              overflow: 'hidden';
            }
          `}
        />
        <Div css={styles.containerDialogCss({ props })}>
          <Div css={styles.headerCss({ props })}>
            {iconLeft && React.cloneElement(iconLeft, iconLeft.props, iconLeft.props.children)}
            <div>
              <H2 css={styles.titleCss({ props })}>{this.props.title}</H2>
              {headerSubtext && <div>{headerSubtext}</div>}
            </div>
            {iconRight && React.cloneElement(iconRight, iconRight.props, iconRight.props.children)}
            {!hideCloseButton && (
              <Button
                buttonType="iconOnly"
                iconLeft="close"
                onClick={this.props.onCloseClick}
                css={styles.closeButtonCss({ props })}
              />
            )}
          </Div>
          {bannerImg}
          <Div css={styles.containerInnerCss({ props })}>{this.props.children}</Div>
          {!this.props.hideButtonContent && (
            <Div css={styles.containerButtonsCss({ props })}>
              {buttonContent && React.cloneElement(buttonContent, buttonContent.props, buttonContent.props.children)}
              {!buttonContent && (
                <Button buttonType="secondary" onClick={this.props.onCloseClick}>
                  DONE
                </Button>
              )}
            </Div>
          )}
        </Div>
      </Div>
    );

    return ReactDOM.createPortal(returnComponent, this.element);
  }
}

export const DialogWithBody = withTheme(DialogComponent);

export default DialogWithBody;
