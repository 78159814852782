import React from 'react';
import { withTheme } from 'theming';

import { A } from 'MoshtixShared/component-element';
import { DropDownButtonOption, DropDownButtonWrapper } from 'MoshtixShared/component-drop-down-button';
import { calculateDuration, formatDateTimeForCalendar } from 'MoshtixShared/helper-timezone';
import { isMobile, ieCanHandleBlobs } from 'MoshtixShared/helper-device-info';
import { ThemeInterface } from 'Types/theme';

import styles from './styles';

interface AddToCalendarProps {
  event: EventInterface;
  theme: ThemeInterface;
}

interface EventInterface {
  startDate: string;
  endDate: string;
  description: string;
  name: string;
  venue: {
    name: string;
    address: {
      region: string;
    };
  };
}

const generateKey = (): string => {
  // quick function to generate a random key for outlook events
  const n = Math.floor(Math.random() * 999999999999).toString();
  return `${new Date().getTime().toString()}_${n}`;
};

const generateCalendarUrl = ({ type, event }: { type: string; event: EventInterface }): string => {
  let calendarUrl = '';

  const startDate = formatDateTimeForCalendar({
    utcDate: event.startDate,
    region: event.venue.address.region,
  });
  const endDate = formatDateTimeForCalendar({
    utcDate: event.endDate,
    region: event.venue.address.region,
  });
  const eventName = encodeURIComponent(event.name);
  const venueName = encodeURIComponent(event.venue.name);
  const description = encodeURIComponent(event.description);
  const duration = calculateDuration({ startDate: event.startDate, endDate: event.endDate });

  switch (type) {
    case 'google':
      calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDate}/${endDate}&location=${venueName}&text=${eventName}&details=${description}`;
      break;

    case 'yahoo':
      calendarUrl = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${eventName}&st=${startDate}&dur=${duration}&desc=${description}&in_loc=${venueName}`;
      break;

    case 'outlook':
      calendarUrl = `https://outlook.live.com/owa/?rru=addevent&startdt=${startDate}&enddt=${endDate}&subject=${eventName}&location=${venueName}&body=${description}&allday=false&uid=${generateKey()}&path=/calendar/view/Month`;
      break;

    default:
      /* eslint-disable */
      calendarUrl = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + startDate,
        'DTEND:' + endDate,
        'SUMMARY:' + event.name,
        'DESCRIPTION:' + event.description,
        'LOCATION:' + event.venue.name,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');
      /* eslint-enable */
      if (!ieCanHandleBlobs() && isMobile()) {
        calendarUrl = encodeURI(`data:text/calendar;charset=utf8,${calendarUrl}`);
      }
  }
  return calendarUrl;
};

const handleDropdownLinkClick = (e: Event, url: string): void => {
  e.preventDefault();

  if (!isMobile() && (url.startsWith('data') || url.startsWith('BEGIN'))) {
    const filename = 'download.ics';
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
    if (ieCanHandleBlobs()) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      /* ***************************************************************
      // many browsers do not properly support downloading data URIs
      // (even with "download" attribute in use) so this solution
      // ensures the event will download cross-browser
      *************************************************************** */
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    window.open(url, '_blank');
  }
};

// TODO: Make this component more configurable to allow for only certain calendars to be available etc

const AddToCalendarWithoutTheme: React.FunctionComponent<AddToCalendarProps> = ({ event }: { event: {} }) => (
  <DropDownButtonWrapper
    options={[
      <DropDownButtonOption
        key="google"
        ui-test-id="save-to-calendar-google"
        onClick={(e: Event): void => handleDropdownLinkClick(e, generateCalendarUrl({ type: 'google', event }))}
      >
        Google
      </DropDownButtonOption>,
      <DropDownButtonOption
        key="yahoo"
        ui-test-id="save-to-calendar-yahoo"
        onClick={(e: Event): void => handleDropdownLinkClick(e, generateCalendarUrl({ type: 'yahoo', event }))}
      >
        Yahoo
      </DropDownButtonOption>,
      <DropDownButtonOption
        key="outlook-desktop"
        ui-test-id="save-to-calendar-outlook-desktop"
        onClick={(e: Event): void =>
          handleDropdownLinkClick(e, generateCalendarUrl({ type: 'outlook-desktop', event }))
        }
      >
        Outlook
      </DropDownButtonOption>,
      <DropDownButtonOption
        key="outlook"
        ui-test-id="save-to-calendar-outlook"
        onClick={(e: Event): void => handleDropdownLinkClick(e, generateCalendarUrl({ type: 'outlook', event }))}
      >
        Outlook.com
      </DropDownButtonOption>,
      <DropDownButtonOption
        key="ical"
        ui-test-id="save-to-calendar-ical"
        onClick={(e: Event): void => handleDropdownLinkClick(e, generateCalendarUrl({ type: 'ical', event }))}
      >
        iCal
      </DropDownButtonOption>,
    ]}
  >
    <A css={styles.linkCss}>Add to Calendar</A>
  </DropDownButtonWrapper>
);

export const AddToCalendar = withTheme(AddToCalendarWithoutTheme);
