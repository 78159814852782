import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

const mediumQuery = configuration.getMediaQuery('medium');
const largeQuery = configuration.getMediaQuery('large');
const extraLargeQuery = configuration.getMediaQuery('extraLarge');
const extraExtraLargeQuery = configuration.getMediaQuery('extraExtraLarge');

const containerCss = ({ props }) =>
  merge(
    {
      width: '100%',
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const innerContainerCss = ({ props }) =>
  merge(
    {
      boxSizing: 'border-box',
      margin: '0 auto',
      maxWidth: pixelsToRem(props.maxWidth),
      paddingTop: pixelsToRem(props.paddingTop || 24),
      paddingBottom: pixelsToRem(24),
      paddingLeft: pixelsToRem(24),
      paddingRight: pixelsToRem(24),
      [mediumQuery]: {
        paddingLeft: pixelsToRem(32),
        paddingRight: pixelsToRem(32),
      },
      [largeQuery]: {
        paddingLeft: pixelsToRem(40),
        paddingRight: pixelsToRem(40),
      },
      [extraLargeQuery]: {
        paddingLeft: pixelsToRem(48),
        paddingRight: pixelsToRem(48),
      },
      [extraExtraLargeQuery]: {
        paddingLeft: pixelsToRem(64),
        paddingRight: pixelsToRem(64),
      },
    },
    resolveToObject({ input: props.innerContainerCss, params: { props } }),
  );

export default {
  containerCss,
  innerContainerCss,
};
