const getText = ({ item, text }) => {
  if (typeof text === 'function') {
    return text({ item });
  }
  return item[text] !== undefined ? item[text] : null;
};

const getGroupKey = ({ item, groupKey }) => {
  if (typeof groupKey === 'function') {
    return groupKey({ item });
  }
  return item[groupKey] !== undefined ? item[groupKey] : null;
};

export const mapToDropDown = ({ item, key, text, value, groupKey }) => {
  const map = () => ({
    key: item[key] !== undefined ? item[key] : item.key,
    value: item[value] !== undefined ? item[value] : item.value,
    text: getText({ item, text }),
    url: item.url,
    item,
    groupKey: getGroupKey({ item, groupKey }),
  });
  const result = item ? map() : null;

  return result;
};

export const mapGroupToDropDown = ({ item, key, text, value }) => {
  const map = () => ({
    key: item[key] !== undefined ? item[key] : item.key,
    value: item[value] !== undefined ? item[value] : item.value,
    text: item[text] !== undefined ? item[text] : null,
    url: item.url,
    item, // include original item
    isGroup: true,
  });
  const result = item ? map() : null;

  return result;
};

export default mapToDropDown;
