import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import merge from 'lodash/merge';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { ThemeInterface } from 'Types/theme';
import { DialogComponentSharedProps } from './dialog-with-body';

const mediumQuery = configuration.getMediaQuery('medium');

const closeButtonCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      marginLeft: 'auto',
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.closeButtonCss, params: { props } }),
    resolveToObject({ input: props.closeButtonCss, params: { props } }),
  );

const containerCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      bottom: 0,
      left: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 1002,

      // vertically center align hack - https://css-tricks.com/centering-in-the-unknown/
      textAlign: 'center',
      ':before': {
        content: '""',
        display: 'inline-block',
        height: '100%',
        verticalAlign: 'middle',
      },
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.containerCss, params: { props } }),
    resolveToObject({ input: props.css, params: { props } }),
  );

const containerDialogCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      backgroundColor: '#fff',
      border: `solid transparent ${pixelsToRem(1)}`,
      boxShadow: `0 0 ${pixelsToRem(23)} 0 rgba(0,0,0,0.75)`,
      boxSizing: 'border-box',
      margin: `${pixelsToRem(24)} auto`,
      maxWidth: `calc(100% - ${pixelsToRem(40)})`,
      borderRadius: pixelsToRem(4),
      minHeight: pixelsToRem(100),
      position: 'relative',
      width: pixelsToRem(600),
      zIndex: 1002,
      [mediumQuery]: {
        marginBottom: pixelsToRem(50),
        marginTop: pixelsToRem(50),
      },

      // vertically center align hack - https://css-tricks.com/centering-in-the-unknown/
      display: 'inline-block',
      verticalAlign: 'baseline',
      textAlign: 'left',
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.containerDialogCss, params: { props } }),
    resolveToObject({ input: props.containerDialogCss, params: { props } }),
  );

const headerHeight = 77;

const footerHeight = 77;

const containerInnerCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      margin: pixelsToRem(24),
    },
    props.contentHeight && {
      padding: pixelsToRem(0, 24, 24),
      margin: 0,
      boxSizing: 'border-box',
      maxHeight: pixelsToRem(props.contentHeight - headerHeight - footerHeight),
      overflowY: 'scroll',
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.containerInnerCss, params: { props } }),
    resolveToObject({ input: props.containerInnerCss, params: { props } }),
  );

const containerButtonsCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      borderTop: props.contentHeight ? `solid transparent ${pixelsToRem(1)}` : 'none',
      margin: `${pixelsToRem(24)}`,
      textAlign: 'right',
    },
    props.contentHeight
      ? {
          backgroundImage: 'linear-gradient(to top, #ffffff 50%, rgba(255, 255, 255, 0.9))',
          backgroundColor: 'transparent',
          margin: 0,
          padding: `${pixelsToRem(24)}`,
        }
      : {
          backgroundColor: props.theme.palette.white,
        },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.containerButtonsCss, params: { props } }),
    resolveToObject({ input: props.containerButtonsCss, params: { props } }),
  );

const headerCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      borderBottom: `1px solid ${props.theme.palette.shadow}`,
      display: 'flex',
      fontWeight: 600,
      padding: props.contentHeight ? pixelsToRem(24, 24, 20) : pixelsToRem(24, 24, 24),
    },
    props.contentHeight
      ? {
          backgroundImage: 'linear-gradient(to bottom, #ffffff 50%, rgba(255, 255, 255, 0.9))',
          backgroundColor: 'transparent',
        }
      : {
          backgroundColor: props.theme.palette.white,
        },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.headerCss, params: { props } }),
    resolveToObject({ input: props.headerCss, params: { props } }),
  );

const bannerContainerCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: pixelsToRem(200),
      overflow: 'hidden',
      backgroundColor: props.theme.palette.lightShadow,
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.bannerContainerCss, params: { props } }),
    resolveToObject({ input: props.bannerContainerCss, params: { props } }),
  );

const bannerCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      height: pixelsToRem(200),
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.bannerCss, params: { props } }),
    resolveToObject({ input: props.bannerCss, params: { props } }),
  );

const overlayCss = ({ props }: { props: DialogComponentSharedProps }): {} =>
  merge(
    {
      zIndex: 1001,
    },
    !!props.theme &&
      !!props.theme.dialog &&
      resolveToObject({ input: props.theme.dialog.overlayCss, params: { props } }),
    resolveToObject({ input: props.overlayCss, params: { props } }),
  );

const nakedDialogContainer = (): {} => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1001,
  [mediumQuery]: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
});

const nakedDialogBackButton = ({ mobileBreakpoint = 600 }: { mobileBreakpoint: number }) => ({
  display: 'none',
  [`@media (min-width: ${pixelsToRem(mobileBreakpoint)})`]: {
    position: 'absolute',
    top: 30,
    right: 30,
    display: 'block',
    textIndent: -99999,
    background: 'transparent',
    border: 0,
    zIndex: 1002,
  },
});

const closeIcon = ({ theme }: { theme: ThemeInterface }): {} => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: 24,
  width: 24,
  color: theme.palette.white,
});

const titleCss = ({ theme }: { theme: ThemeInterface }): {} => ({
  fontSize: pixelsToRem(22),
  fontWeight: 500,
  fontFamily: "'Oswald', 'Open Sans', sans-serif",
  marginBottom: 0,
});

export const styles = {
  closeButtonCss,
  closeIcon,
  containerCss,
  containerDialogCss,
  containerInnerCss,
  containerButtonsCss,
  headerCss,
  bannerCss,
  bannerContainerCss,
  overlayCss,
  nakedDialogContainer,
  nakedDialogBackButton,
  titleCss,
};
