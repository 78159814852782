import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'theming';
import { Div } from 'MoshtixShared/component-element';

import styles from './styles';

const SectionComponent = (props) => (
  <Div id={props.id} css={styles.containerCss({ props })}>
    <Div css={styles.innerContainerCss({ props })}>{props.children}</Div>
  </Div>
);

SectionComponent.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  id: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  innerContainerCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  // eslint-disable-next-line react/no-unused-prop-types
  maxWidth: PropTypes.number,
};

SectionComponent.defaultProps = {
  children: undefined,
  css: {},
  id: '',
  innerContainerCss: {},
  type: 'regular',
  maxWidth: 1000,
};

const ThemedSection = withTheme(SectionComponent);
export const Section = (props) => <ThemedSection {...props} />;
Section.propTypes = SectionComponent.propTypes;
Section.defaultProps = SectionComponent.defaultProps;

export default Section;
