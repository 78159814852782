import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

import { ThemeInterface } from 'Types/theme';

const mediumQuery = configuration.getMediaQuery('medium');

const backgroundCss = ({ theme }: { theme: ThemeInterface }) => ({
  backgroundColor: theme.palette.white,
  minHeight: pixelsToRem(450),
  position: 'relative',
  paddingTop: 0,

  [mediumQuery]: {
    paddingTop: 0,
    '&::before': {
      right: 0,
      top: 150,
    },
    '&::after': {
      left: 0,
      top: -180,
    },
  },
});

const headingCss = {
  marginBottom: 0,
};

const labelCss = ({ theme }: { theme: ThemeInterface }) => ({
  color: theme.palette.darkerShadow,
  fontSize: pixelsToRem(14),
});

const semiBoldLabelCss = {
  fontWeight: 600,
  margin: 0,
};

const eventThumbnailCss = {
  height: 90,
  width: 90,
  borderRadius: 4,
  [mediumQuery]: {
    height: 120,
    width: 120,
  },
};

const eventNameCss = {
  marginLeft: pixelsToRem(20),
  marginBottom: 0,
  flex: 1,
  [mediumQuery]: {
    marginLeft: pixelsToRem(26),
  },
};

const eventDetailsCss = {
  marginLeft: pixelsToRem(16),
  marginBottom: pixelsToRem(28),
  flex: 1,
};

const eventDetailsContainerCss = {
  display: 'flex',
  [mediumQuery]: {
    width: 260,
  },
};

const eventDetailsWrapperCss = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: pixelsToRem(40),
  [mediumQuery]: {
    flexDirection: 'row',
  },
};

const flexContainerCss = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: pixelsToRem(30),
  alignItems: 'center',
  [mediumQuery]: {
    flexDirection: 'row',
  },
};

const flexAlignTopCss = {
  alignItems: 'top',
};

const linksCss = {
  textDecoration: 'underline',
  textTransform: 'uppercase',
  fontSize: pixelsToRem(14),
  fontWeight: 600,
};

const circleCss = ({ theme }: { theme: ThemeInterface }) => ({
  height: 38,
  width: 38,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.black}`,
  position: 'relative',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

export default {
  backgroundCss,
  circleCss,
  headingCss,
  labelCss,
  semiBoldLabelCss,
  eventThumbnailCss,
  eventDetailsContainerCss,
  flexContainerCss,
  flexAlignTopCss,
  eventNameCss,
  eventDetailsCss,
  eventDetailsWrapperCss,
  linksCss,
};
