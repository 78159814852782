import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import merge from 'lodash/merge';

let innerPalette = {
  black: '',
  white: '',
  darkerShadow: '',
  darkShadow: '',
  shadow: '',
  lightShadow: '',
  lighterShadow: '',
  attention: '',
  lightAttention: '',
  hightlight: '',
  uber: '',
  error: '',
  lightError: '',
  warning: '',
  lightWarning: '',
  success: '',
  lightSuccess: '',
  info: '',
  lightInfo: '',
  gradient: {
    attention: '',
  },
};

const typography = {
  fontFamilyOne: "'Open Sans', sans-serif;",
};

const containerCss = () => ({});

const label = {
  labelCss: ({ props }) =>
    merge(
      {
        color: innerPalette.black,
      },
      props.errorState && { color: innerPalette.error },
    ),
  subLabelCss: ({ props }) =>
    merge(
      {
        color: innerPalette.darkerShadow,
      },
      props.errorState && { color: innerPalette.error },
    ),
  subTextCss: ({ props }) =>
    merge(
      {
        color: innerPalette.darkerShadow,
      },
      props.errorState && { color: innerPalette.error },
    ),
  footNoteCss: ({ props }) =>
    merge(
      {
        color: innerPalette.darkerShadow,
      },
      props.errorState && { color: innerPalette.error },
    ),
  footNoteLightCss: ({ props }) =>
    merge(
      {
        color: innerPalette.shadow,
      },
      props.errorState && { color: innerPalette.error },
    ),
  requiredAsteriskCss: () => ({
    color: innerPalette.error,
  }),
};

const textBox = {
  containerCss: {},
  containerInnerCss: ({ props }) =>
    merge(
      {
        backgroundColor: innerPalette.white,
      },
      props.minimal && { backgroundColor: 'transparent' },
      props.disabled && { backgroundColor: innerPalette.lightShadow },
    ),
  inputCss: ({ props, state }) =>
    merge(
      {
        borderColor: innerPalette.shadow,
      },
      props.errorState && {
        borderColor: innerPalette.error,
      },
      props.disabled && {
        color: innerPalette.shadow,
      },
      props.readOnly && {
        color: innerPalette.darkerShadow,
      },
      state.focussed && {
        borderColor: innerPalette.attention,
        outlineColor: innerPalette.attention,
      },
      state.focussed && props.errorState && { borderColor: innerPalette.error, outlineColor: innerPalette.error },
    ),
  // this is the 2 px wide border for when a minimal field is focussed
  minimalBorderCss: ({ props, state }) =>
    merge(
      {},
      state.focussed && { borderColor: innerPalette.attention },
      state.focussed && props.errorState && { borderColor: innerPalette.error },
    ),
  keyCharacterCss: () => ({
    color: innerPalette.darkerShadow,
  }),
  datePickerCss: () => ({
    ' .DayPicker-Day--today': {
      color: innerPalette.error,
    },
    ' .DayPicker-Day--selection': {
      backgroundColor: innerPalette.attention,
      color: innerPalette.white,
    },
  }),
  placeholderCss: () => ({
    color: innerPalette.black,
  }),
};

const timeBox = {
  timePickerCss: () => ({
    backgroundColor: innerPalette.white,
    borderColor: innerPalette.shadow,
  }),
  timePickerItemCss: ({ props }) =>
    merge(
      {
        ':hover': {
          backgroundColor: innerPalette.lightAttention,
        },
      },
      props.itemDisabled && {
        color: innerPalette.darkShadow,
        cursor: 'default',
        ':hover': {
          backgroundColor: 'none',
        },
      },
      props.itemIsCurrent && {
        backgroundColor: innerPalette.lightAttention,
      },
    ),
};

const htmlEditor = {
  containerCss: ({ props, state }) =>
    merge(
      {
        borderColor: innerPalette.shadow,
      },
      props.errorState && {
        borderColor: innerPalette.error,
      },
      props.disabled && {
        color: innerPalette.shadow,
      },
      state.focussed && {
        borderColor: innerPalette.attention,
        boxShadow: `0 0 0 ${pixelsToRem(1)} ${innerPalette.attention}`,
      },
      state.focussed && props.errorState && { borderColor: innerPalette.error, outlineColor: innerPalette.error },
    ),
};

const button = {
  buttonCss: ({ props }) =>
    merge(
      {
        color: innerPalette.darkerShadow,
        ':focus': {
          outlineColor: innerPalette.lightAttention,
        },
      },
      props.buttonType === 'primary' && {
        backgroundColor: innerPalette.attention,
        backgroundSize: '200% auto',
        backgroundImage: innerPalette.gradient.attention,
        borderColor: innerPalette.attention,
        color: innerPalette.white,
        ':focus': {
          backgroundPosition: 'right center',
        },
        ':hover': {
          backgroundPosition: 'right center',
        },
        ':active': {
          backgroundColor: innerPalette.darkAttention,
          backgroundImage: 'none',
        },
      },
      props.buttonType === 'error' && {
        backgroundColor: innerPalette.error,
        backgroundImage: 'linear-gradient(to right, #ff0062, #d10051)',
        borderColor: innerPalette.attention,
        color: innerPalette.white,
        ':focus': {
          backgroundPosition: 'right center',
        },
        ':hover': {
          backgroundPosition: 'right center',
        },
      },
      props.buttonType === 'secondary' && {
        border: `solid ${pixelsToRem(2)} ${innerPalette.attention}`,
        color: innerPalette.attention,
        outline: 'none',
        ':hover': {
          backgroundColor: innerPalette.lightAttention,
        },
        ':focus': {
          backgroundColor: innerPalette.lightAttention,
        },
      },
      props.buttonType === 'secondaryInverted' && {
        color: innerPalette.white,
        border: `solid 2px ${innerPalette.white}`,
        ':hover': {
          backgroundColor: 'rgba(255,255,255,0.15)',
        },
        ':focus': {
          outline: 'none',
          backgroundColor: 'rgba(255,255,255,0.15)',
        },
      },
      props.buttonType === 'tertiary' && {
        color: innerPalette.black,
        outline: 'none',
        ':hover': {
          backgroundColor: innerPalette.lightAttention,
        },
        ':focus': {
          backgroundColor: innerPalette.lightAttention,
        },
      },
      props.buttonType === 'tertiaryInverted' && {
        color: innerPalette.white,
        ':hover': {
          backgroundColor: 'rgba(255,255,255,0.15)',
        },
        ':focus': {
          outline: 'none',
          backgroundColor: 'rgba(255,255,255,0.15)',
        },
      },
      props.buttonType === 'uber' && {
        background: `linear-gradient(135deg, ${innerPalette.uber} 0%,${innerPalette.attention} 95%)`,
        borderColor: innerPalette.white,
        color: innerPalette.white,
        ':hover': {
          outlineColor: innerPalette.lightAttention,
        },
        ':focus': {
          outlineColor: innerPalette.lightAttention,
        },
      },
      props.buttonType === 'tertiaryAlternate' && {
        outline: 'none',
      },
    ),
  iconCss: ({ props }) =>
    merge(
      {
        color: innerPalette.darkerShadow,
      },
      props.buttonType === 'primary' && { color: innerPalette.white },
      props.buttonType === 'secondary' && { color: innerPalette.attention },
      props.buttonType === 'secondaryInverted' && { color: innerPalette.white },
      props.buttonType === 'tertiary' && { color: innerPalette.black },
      props.buttonType === 'tertiaryInverted' && { color: innerPalette.white },
      props.buttonType === 'uber' && { color: innerPalette.white },
      props.buttonType === 'iconOnly' && { color: innerPalette.darkerShadow },
    ),
};

const icon = {
  containerCss: () => ({
    color: innerPalette.black,
  }),
};

const pagination = {
  pageIndexCss: ({ props }) =>
    merge(
      { color: innerPalette.attention },
      !props.disabled && {
        ':hover': {
          background: innerPalette.lightShadow,
        },
      },
      { color: innerPalette.attention },
      props.selected && {
        color: innerPalette.black,
      },
    ),
};

const infoPanel = {
  alertCss: () => ({}),
  alertIconCss: () => ({}),
  containerCss: ({ props }) =>
    merge(
      {
        backgroundColor: innerPalette.lightInfo,
        borderColor: innerPalette.info,
      },
      props.type === 'warning' && {
        backgroundColor: innerPalette.lightWarning,
        borderColor: innerPalette.warning,
      },
      props.type === 'error' && {
        backgroundColor: innerPalette.lightError,
        borderColor: innerPalette.error,
      },
    ),
};

const appBar = {
  containerCss: () => ({
    backgroundColor: innerPalette.black,
  }),
  iconCss: () => ({
    color: innerPalette.white,
  }),
  labelCss: () => ({
    color: innerPalette.white,
  }),
  buttonCss: {},
  centerContentCss: {},
};

const menu = {
  closeButtonIconCss: () => ({
    color: innerPalette.white,
  }),
  containerCss: () => ({
    backgroundColor: innerPalette.black,
  }),
};

const accordionMenu = {
  containerCss: () => ({
    backgroundColor: innerPalette.black,
  }),
  dropDownIconCss: () => ({
    color: innerPalette.white,
  }),
  unorderedListCss: {},
  listItemCss: {},
  iconColumnCss: {},
  linkCss: {},
  currentSelectedIconCss: () => ({ color: innerPalette.white }),
  iconCss: () => ({ color: innerPalette.white }),
  labelCss: ({ props }) =>
    merge({ color: innerPalette.white }, props.finalHighlight && { fontWeight: 600, opacity: 1 }),
};

const checkBox = {
  containerCss: {},
  iconCss: ({ props }) =>
    merge(props.checked && { color: innerPalette.attention }, props.disabled && { color: innerPalette.darkerShadow }),
  inputCss: ({ props }) =>
    merge(
      {
        ':focus:not(:active)': {
          outlineColor: innerPalette.lightAttention,
        },
      },
      !props.checked && { borderColor: innerPalette.shadow },
      props.checked && { borderColor: innerPalette.attention },
      props.disabled && {
        backgroundColor: innerPalette.lightShadow,
        borderColor: innerPalette.shadow,
      },
    ),
  labelCss: () =>
    merge({
      color: innerPalette.black,
    }),
};

const radio = {
  containerItemCss: () => ({}),
  containerCss: () => ({}),
  inputCss: () => ({}),
  labelCss: ({ props, state }) => {
    const disabledChecked = {
      '&::after,&::before': {
        background: innerPalette.darkerShadow,
        borderColor: innerPalette.shadow,
      },
    };
    const disabledUnchecked = {
      '&::after,&::before': {
        background: innerPalette.lightShadow,
        borderColor: innerPalette.shadow,
      },
    };
    const enabledUnchecked = {
      '&::after,&::before': {
        background: innerPalette.white,
        borderColor: innerPalette.shadow,
      },
    };
    const enabledChecked = {
      '&::after,&::before': {
        background: innerPalette.attention,
        borderColor: innerPalette.attention,
      },
    };
    return merge(
      props.disabled && state.checked && disabledChecked,
      props.disabled && !state.checked && disabledUnchecked,
      !props.disabled && !state.checked && enabledUnchecked,
      !props.disabled && state.checked && enabledChecked,
    );
  },
};

const repeater = {
  containerCss: () => ({}),
  listCss: () => ({}),
  itemCss: () => ({}),
};

const alert = {
  alertCss: ({ props }) =>
    merge(
      {
        color: innerPalette.error,
      },
      props.type === 'warning' && { color: innerPalette.warning },
      props.type === 'success' && { color: innerPalette.success },
      props.type === 'attention' && { color: innerPalette.info },
    ),
  iconCss: ({ props }) =>
    merge(
      {
        color: innerPalette.error,
      },
      props.type === 'warning' && { color: innerPalette.warning },
      props.type === 'success' && { color: innerPalette.success },
      props.type === 'attention' && { color: innerPalette.info },
    ),
};

const chip = {
  containerCss: ({ props }) =>
    merge(
      !props.disabled && {
        background: innerPalette.lightShadow,
        '&::hover': {},
      },
      props.disabled && {
        background: innerPalette.lighterShadow,
      },
    ),
  spanCss: () => ({}),
  buttonCss: () =>
    merge({
      ':hover path': {
        fill: 'white',
      },
      background: 'inherit',
      '&::after': {
        color: innerPalette.darkerShadow,
      },
      '&:hover': {
        background: innerPalette.darkerShadow,
        '&::after': {
          color: innerPalette.white,
        },
      },
      '&:active': {
        background: innerPalette.lightShadow,
      },
    }),
};

const collapsableList = {
  itemContainerCss: ({ props }) =>
    merge({}, !props.expanded && props.oddIndex && { background: innerPalette.lighterShadow }),
};

const dialog = {
  closeButtonCss: () => ({}),
  containerCss: () => ({}),
  containerDialogCss: () => ({
    backgroundColor: innerPalette.white,
    borderColor: innerPalette.shadow,
  }),
  containerInnerCss: () => ({}),
  containerButtonsCss: () => ({
    borderColor: innerPalette.shadow,
  }),
  headerCss: () => ({
    borderColor: innerPalette.shadow,
  }),
  overlayCss: () => ({}),
};

const dropDown = {
  containerCss: () =>
    merge({
      borderColor: `${innerPalette.lightAttention} !important`,
      background: innerPalette.white,
    }),
  dropDownItemCss: ({ props }) => {
    const hover = {
      backgroundColor: `${innerPalette.lightAttention} !important`,
    };
    return merge(
      !props.isGroup && props.index === props.currentPosition && hover,
      props.isAddNew && { color: `${innerPalette.attention} !important` },
      props.isNoMatch && {
        backgroundColor: `${innerPalette.lightShadow} !important`,
        color: `${innerPalette.darkShadow} !important`,
      },
    );
  },
  inputContainerCss: ({ props, state }) => {
    const focusEnabled = {
      borderColor: innerPalette.attention,
      outlineColor: innerPalette.attention,
    };
    const disabledNotMin = {
      color: innerPalette.shadow,
      backgroundColor: innerPalette.lightShadow,
    };
    const errorFocus = {
      borderColor: innerPalette.error,
      outlineColor: innerPalette.error,
    };

    return merge(
      {
        borderColor: innerPalette.shadow,
      },
      !props.errorState && state.focus && !props.disabled && focusEnabled,
      !props.errorState && props.disabled && !props.minimal && disabledNotMin,
      props.errorState && {
        borderColor: innerPalette.error,
        outlineColor: 'transparent',
      },
      props.errorState && state.focus && !props.disabled && errorFocus,
    );
  },
  inputCss: ({ props, state }) => {
    const disabledNotMin = {
      color: innerPalette.shadow,
      backgroundColor: innerPalette.lightShadow,
      '&::placeholder': { color: innerPalette.shadow },
    };
    return merge(
      {
        fontFamily: typography.fongtFamilyOne,
        '&::placeholder': { color: innerPalette.shadow },
      },
      props.disabled && !props.minimal && disabledNotMin,
      state.focus && props.errorState && { borderColor: innerPalette.error, outlineColor: innerPalette.error },
    );
  },
  placeholderCss: () => ({
    color: innerPalette.black,
  }),
  // this is the 2 px wide border for when a minimal field is focussed
  minimalBorderCss: ({ state }) => merge(state.focus && { borderColor: innerPalette.attention }),
  subTextCss: () => ({
    color: innerPalette.darkerShadow,
  }),
  dropDownItemTextCss: ({ props }) => merge(props.isGroup && { color: innerPalette.darkerShadow }),
  iconHistoryCss: () => ({
    color: innerPalette.shadow,
  }),
  iconSelectedCss: () => ({
    color: innerPalette.black,
  }),
  iconCss: ({ props }) =>
    merge(
      !props.disabled && {
        color: innerPalette.black,
        ':hover > path': {
          fill: innerPalette.darkShadow,
        },
      },
      props.disabled && {
        color: innerPalette.shadow,
      },
    ),
};

const dropDownButton = {
  containerCss: () => merge({}),
  menuContainerCss: () =>
    merge({
      borderColor: innerPalette.shadow,
      background: innerPalette.white,
    }),
  optionCss: ({ props }) =>
    merge(
      {
        color: innerPalette.black,
        ':hover': {
          background: innerPalette.lightAttention,
        },
      },
      props.type === 'link' && { color: innerPalette.attention },
      props.disabled && {
        color: innerPalette.shadow,
        ':hover': {
          background: 'none',
        },
      },
    ),
  optionContentCss: () => merge({}),
  optionIconCss: ({ props }) =>
    merge(
      {},
      props.type === 'link' && { color: innerPalette.attention },
      props.disabled && { color: innerPalette.shadow },
    ),
  optionSubTextCss: () =>
    merge({
      color: innerPalette.darkShadow,
    }),
};

const horizontalScroll = {
  scrollContainerCss: {},
  buttonCss: {},
  buttonIconCss: {
    color: innerPalette.darkShadow,
  },
  backgroundColor: innerPalette.white,
};

const overlay = {
  containerCss: {},
};

const table = {
  tableCss: () => merge({}),
  trCss: () =>
    merge({
      ':nth-child(even)': {
        background: innerPalette.lighterShadow,
      },
    }),
  thCss: () =>
    merge({
      color: innerPalette.darkShadow,
    }),
  tdCss: () => merge({}),
};

const tag = {
  containerCss: ({ props }) => {
    const invertedSuccessBackground = {
      backgroundColor: 'transparent',
    };
    const invertedSuccess = {
      borderColor: innerPalette.success,
      color: innerPalette.success,
    };
    const invertedWarning = {
      borderColor: innerPalette.warning,
      color: innerPalette.warning,
    };
    const invertedError = {
      borderColor: innerPalette.error,
      color: innerPalette.error,
    };
    return merge(
      {
        backgroundColor: innerPalette.shadow,
        color: innerPalette.darkerShadow,
      },
      (props.type === 'sectionTag' ||
        props.type === 'success' ||
        props.type === 'warning' ||
        props.type === 'uber' ||
        props.type === 'error') && {
        color: innerPalette.white,
      },
      props.type === 'sectionTag' && { backgroundColor: innerPalette.darkerShadow },
      props.type === 'success' && { backgroundColor: innerPalette.success },
      props.type === 'warning' && { backgroundColor: innerPalette.warning },
      props.type === 'error' && { backgroundColor: innerPalette.error },
      props.type === 'uber' && { backgroundColor: innerPalette.uber },
      // inverted styles
      (props.type === 'success' || props.type === 'warning' || props.type === 'error') &&
        props.inverted &&
        invertedSuccessBackground,
      props.inverted && props.type === 'success' && invertedSuccess,
      props.inverted && props.type === 'warning' && invertedWarning,
      props.inverted && props.type === 'error' && invertedError,
    );
  },
};

const panel = {
  containerCss: () => ({
    backgroundColor: innerPalette.white,
    borderColor: innerPalette.shadow,
    boxShadow: `0 ${pixelsToRem(3)} 0 0 ${innerPalette.shadow}`, // set default bottom shadow color here
  }),
  footerCss: () => ({
    backgroundColor: innerPalette.lightShadow,
  }),
};

const panelCollapsable = {
  collapsedCss: () => ({
    backgroundColor: innerPalette.lightShadow,
  }),
};

const fileInputBox = {
  textCss: () => ({
    color: innerPalette.attention,
  }),
  css: ({ state }) =>
    merge(
      {
        borderColor: innerPalette.shadow,
        backgroundColor: innerPalette.white,
      },
      state.dragEnter && {
        backgroundColor: innerPalette.lightShadow,
      },
    ),
  removeCss: () => ({
    color: innerPalette.white,
    ':hover > path': {
      fill: innerPalette.black,
    },
  }),
  cropCss: () => ({
    color: innerPalette.white,
  }),
  imageContainerCss: () => ({
    backgroundColor: innerPalette.black,
  }),
  actionButtonCss: () => ({
    color: innerPalette.white,
  }),
};

const switchComponent = {
  labelCss: ({ props }) =>
    merge(
      props.disabled && {
        color: innerPalette.shadow,
        cursor: 'no-drop',
      },
      props.checked && {
        '&::before': {
          backgroundColor: innerPalette.lightAttention,
        },
      },
      props.checked && {
        '&::after': {
          backgroundColor: innerPalette.attention,
        },
      },
      !props.checked && {
        '&::before': {
          backgroundColor: innerPalette.shadow,
        },
      },
      !props.checked && {
        '&::after': {
          backgroundColor: innerPalette.lightShadow,
        },
      },
      props.disabled && {
        '&::before, &::after': {
          backgroundColor: innerPalette.lightShadow,
        },
      },
    ),
};

const tabs = {
  containerCss: () => merge({}),
  tabsContainerCss: () => ({}),
  tabElementCss: ({ props, item }) =>
    merge(
      {
        color: innerPalette.attention,
        ':hover': {
          backgroundColor: innerPalette.lighterShadow,
        },
      },
      item.highlight && {
        '::after': {
          borderBottomColor: innerPalette.attention,
        },
      },
      props.color && {
        color: props.color,
        '::after': {
          borderBottomColor: props.color,
        },
      },
      item.disabled && {
        color: innerPalette.darkerShadow,
        ':hover': {
          backgroundColor: 'auto',
        },
      },
    ),
  iconCss: () =>
    merge({
      color: innerPalette.attention,
    }),
};

const list = {
  listCss: () => merge({}),
  listItemCss: () =>
    merge({
      ':nth-child(odd)': {
        background: innerPalette.lightShadow,
      },
    }),
  listSubText: () =>
    merge({
      color: innerPalette.darkerShadow,
    }),
};

const root = ({ palette }) => {
  innerPalette = palette;
  return {
    accordionMenu,
    alert,
    appBar,
    button,
    checkBox,
    chip,
    collapsableList,
    containerCss,
    dialog,
    dropDown,
    dropDownButton,
    fileInputBox,
    horizontalScroll,
    htmlEditor,
    icon,
    infoPanel,
    label,
    list,
    menu,
    overlay,
    pagination,
    palette,
    panel,
    panelCollapsable,
    radio,
    repeater,
    switchComponent,
    table,
    tag,
    textBox,
    timeBox,
    typography,
    tabs,
  };
};

export default root;
