import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const clearButtonCss = {
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  boxShadow: 'none',
  position: 'absolute',
  top: 0,
  right: 0,
  paddingRight: `${pixelsToRem(13)}`,
  height: '40%',
};

const containerCss = ({ props, state }) =>
  merge(
    {
      margin: `0 0 ${pixelsToRem(8)}`,
      position: 'relative',
      maxWidth: props.width || '100%',
      width: '100%',
    },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.containerCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const containerInnerCss = ({ props, state }) =>
  merge(
    {
      position: 'relative',
    },
    props.multiline && {
      height: pixelsToRem(props.height),
    },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.containerInnerCss, params: { props, state } }),
    resolveToObject({ input: props.containerInnerCss, params: { props, state } }),
  );

const inputCss = ({ props, state }) =>
  merge(
    {
      appearance: 'none',
      background: 'rgba(0, 0, 0, 0)',
      border: `solid ${pixelsToRem(1)} transparent`,
      borderRadius: 0,
      boxSizing: 'border-box',
      fontFamily: 'inherit',
      fontSize: pixelsToRem(16),
      minHeight: pixelsToRem(48),
      outlineColor: 'transparent',
      outlineWidth: pixelsToRem(1),
      outlineStyle: 'solid',
      padding: `${pixelsToRem(12)}`,
      position: 'relative',
      transition: 'outline-color 0.3s, border-color 0.3s',
      width: '100%',
    },
    props.multiline && {
      height: pixelsToRem(props.height),
    },
    props.iconLeft && {
      paddingLeft: `${pixelsToRem(43)}`,
    },
    props.disabled && { cursor: 'default' },
    props.minimal && {
      padding: `${pixelsToRem(7.5)} 0`,
      outline: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      minHeight: pixelsToRem(34),
    },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.inputCss, params: { props, state } }),
    resolveToObject({ input: props.inputCss, params: { props, state } }),
  );

const minimalBorderCss = ({ props, state }) =>
  merge(
    {
      borderBottom: `solid ${pixelsToRem(2)} transparent`,
      bottom: 0,
      position: 'absolute',
      transition: 'all 0.3s',
      width: '100%',
    },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.minimalBorderCss, params: { props, state } }),
    resolveToObject({ input: props.minimalBorderCss, params: { props, state } }),
  );

const descriptionCss = ({ props }) => ({
  fontSize: pixelsToRem(14),
  color: props.theme.palette.darkShadow,
});

const placeholderCss = ({ props, state }) =>
  merge(
    {
      fontSize: pixelsToRem(16),
      left: pixelsToRem(16),
      lineHeight: pixelsToRem(48),
      opacity: 0.4,
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      top: 0,
      right: 0,
      transform: 'translate(0, 0)',
      transition: 'all 0.3s',
      whiteSpace: 'nowrap',
    },
    props.iconLeft && {
      paddingLeft: `${pixelsToRem(36)}`,
    },
    props.minimal && {
      left: 1,
      lineHeight: pixelsToRem(36),
    },
    state.value ? { opacity: 0, transform: 'translate(30px, 0)' } : {},
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.placeholderCss, params: { props, state } }),
    resolveToObject({ input: props.placeholderCss, params: { props, state } }),
  );

const iconCss = {
  position: 'absolute',
  top: '30%',
  paddingLeft: `${pixelsToRem(13)}`,
  height: '40%',
};

const relativeContainerCss = {
  position: 'relative',
};

export const styles = {
  clearButtonCss,
  containerCss,
  containerInnerCss,
  inputCss,
  minimalBorderCss,
  placeholderCss,
  iconCss,
  relativeContainerCss,
  descriptionCss,
};
