import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { ThemeInterface } from 'Types/theme';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

const mediumQuery = configuration.getMediaQuery('medium');

const rowContainerCss = {
  display: 'block',
  [mediumQuery]: {
    display: 'flex',
  },
};

const flexColumnCss = {
  // fill in all the available space minus margins
  flex: '1 1 0',
  // everything after the first element should have a margin to the left of it
  '&:nth-child(n+2)': {
    marginLeft: pixelsToRem(0),
  },
  [mediumQuery]: {
    '&:nth-child(n+2)': {
      marginLeft: pixelsToRem(24),
    },
  },
};

const showingHiddenRowCss: () => {} = ({ theme }: { theme: ThemeInterface }) => ({
  padding: pixelsToRem(16, 24, 24),
  borderLeft: `8px solid ${theme.palette.lightBanner}`,
});

const formCss = {
  '&> *': {
    '&:nth-child(n+2)': {
      marginTop: pixelsToRem(24),
    },
  },
};

export const styles = {
  rowContainerCss,
  flexColumnCss,
  formCss,
  showingHiddenRowCss,
};
