import React from 'react';
import { withTheme } from 'theming';

import { Div } from 'MoshtixShared/component-element';
import { ThemeInterface } from 'Types/theme';

import { styles } from './styles';

export interface DropdownMenuContentProps {
  id: string;
  children: JSXElement[] | JSXElement;
  // eslint-disable-next-line react/no-unused-prop-types
  css: () => {} | {};
  // eslint-disable-next-line react/no-unused-prop-types
  theme: ThemeInterface;
}

const DropdownMenuContentWithoutTheme: React.FunctionComponent = (props: DropdownMenuContentProps) => (
  <Div id={props.id} css={styles.containerCss({ props })}>
    {props.children}
  </Div>
);

export const DropdownMenuContent: React.FunctionComponent = withTheme(DropdownMenuContentWithoutTheme);
