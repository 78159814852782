import React from 'react';
import { withTheme } from 'theming';
import { A } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import { ThemeInterface } from 'Types/theme';

import { history } from '../../../app/history';
import { styles } from './styles';

const TicketDialogBarWithoutTheme = ({ theme, tickets }: { theme: ThemeInterface; tickets: Array<{}> }) => {
  const event = history.location.pathname.endsWith('/') ? history.location.pathname : `${history.location.pathname}/`;
  const ticketLink = `${event}ticket/${tickets[0].id}`;
  return (
    <A
      css={styles.containerCss({ theme })}
      onClick={(e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(ticketLink);
      }}
    >
      <Icon type="icons-qr" css={styles.iconCss({ theme })} />
      OPEN {tickets.length} TICKETS
    </A>
  );
};

export const TicketDialogBar = withTheme(TicketDialogBarWithoutTheme);
