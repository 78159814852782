import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const mediumQuery = configuration.getMediaQuery('medium');

const returnLinkCss = () => ({
  textDecoration: 'none',
});

const returnToAccountBarCss = ({ props }) => ({
  backgroundColor: props.theme.palette.black,
  color: props.theme.palette.white,
  textAlign: 'center',
  textDecoration: 'none',
  padding: pixelsToRem(20),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Open Sans',
  fontSize: pixelsToRem(16),
  fontWeight: '600',
});

const returnToAccountIconCss = ({ props }) => ({
  color: props.theme.palette.white,
  width: pixelsToRem(18),
  height: pixelsToRem(15),
  marginRight: pixelsToRem(10),
});

const moshtixLogoContainerCss = () => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: pixelsToRem(29),
  [mediumQuery]: {
    marginTop: pixelsToRem(37),
  },
});

const moshtixLogoCss = () => ({
  height: pixelsToRem(32),
});

export default {
  returnToAccountBarCss,
  returnToAccountIconCss,
  returnLinkCss,
  moshtixLogoContainerCss,
  moshtixLogoCss,
};
