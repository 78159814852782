import { isNil } from 'ramda';
import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { styles as textBoxStyles } from 'MoshtixShared/component-text-box/styles';

const numberPlaceholderCss = ({ props, state }) =>
  merge(
    textBoxStyles.placeholderCss({ props, state }),
    {
      right: pixelsToRem(12),
      textAlign: props.textAlign ? props.textAlign : 'right',
    },
    props.minimal && { right: pixelsToRem(2) },
    props.keyCharacterRight && { right: pixelsToRem(35) },
    !isNil(props.value) || !!state.value ? { opacity: 0, transform: 'translate(-30px, 0)' } : {},
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.placeholderCss, params: { props, state } }),
    resolveToObject({ input: props.placeholderCss, params: { props, state } }),
  );

const keyCharacterCss = ({ props, state }) =>
  merge(
    {
      lineHeight: pixelsToRem(46),
      position: 'absolute',
      top: 0,
    },
    !props.keyCharacterRight && { left: pixelsToRem(10) },
    props.keyCharacterRight && { right: pixelsToRem(10) },
    !props.keyCharacterRight && props.minimal && { left: pixelsToRem(0) },
    props.keyCharacterRight && props.minimal && { right: pixelsToRem(0) },
    props.minimal && { lineHeight: pixelsToRem(36) },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.keyCharacterCss, params: { props, state } }),
    resolveToObject({ input: props.keyCharacterCss, params: { props, state } }),
  );

const numberInputCss = ({ props, state }) =>
  merge(
    textBoxStyles.inputCss({ props, state }),
    {
      textAlign: props.textAlign ? props.textAlign : 'right',
    },
    props.keyCharacter && props.keyCharacterRight && { paddingRight: pixelsToRem(30) },
    props.keyCharacter && !props.keyCharacterRight && { paddingLeft: pixelsToRem(30) },
    props.minimal && props.keyCharacter && props.keyCharacterRight && { paddingRight: pixelsToRem(20) },
    props.minimal && props.keyCharacter && !props.keyCharacterRight && { paddingLeft: pixelsToRem(20) },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.inputCss, params: { props, state } }),
    resolveToObject({ input: props.inputCss, params: { props, state } }),
  );

export const styles = {
  ...textBoxStyles,
  numberPlaceholderCss,
  keyCharacterCss,
  numberInputCss,
};
