import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { keyframes } from '@emotion/core';
import merge from 'lodash/merge';

const containerCss = ({ props, state }) =>
  merge(
    {
      position: 'relative',
      width: '100%',
      marginBottom: pixelsToRem(8),
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.containerCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const spinKeyFrames = keyframes`
  '0%' {
    transform: 'rotate(0deg)'; 
  }
  '100%' {
    transform: 'rotate(360deg)';
  }
`;

const spin = {
  animation: `${spinKeyFrames} 1s linear infinite`,
};

const iconContainerCss = ({ props, state }) =>
  merge(
    {
      position: 'absolute',
      top: pixelsToRem(3),
      right: pixelsToRem(-1),
    },

    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.iconContainerCss, params: { props, state } }),
    resolveToObject({ input: props.iconContainerCss, params: { props, state } }),
  );

const dropDownSelectionSpanCss = ({ props, state }) =>
  merge(
    {
      width: '100%',
      position: 'absolute',
      zIndex: 1,
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({
        input: props.theme.dropDown.dropDownSelectionSpanCss,
        params: { props, state },
      }),
    resolveToObject({ input: props.dropDownSelectionSpanCss, params: { props, state } }),
  );

const minimalBorderCss = ({ props, state }) =>
  merge(
    {
      borderBottom: `solid ${pixelsToRem(2)} transparent`,
      bottom: 0,
      position: 'absolute',
      transition: 'all 0.3s',
      width: '100%',
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.minimalBorderCss, params: { props, state } }),
    resolveToObject({ input: props.minimalBorderCss, params: { props, state } }),
  );

const inputContainerCss = ({ props, state }) => {
  const minimalDisabled = {
    borderBottomStyle: 'dashed',
  };
  return merge(
    {
      appearance: 'none',
      border: `solid ${pixelsToRem(1)} transparent`,
      borderRadius: 0,
      boxSizing: 'border-box',
      minHeight: pixelsToRem(49),
      outlineColor: 'transparent',
      outlineStyle: 'solid',
      outlineWidth: pixelsToRem(1),
      transition: 'outline-color 0.3s, border-color 0.3s',
    },
    props.multiple && !props.disabled && { cursor: 'text', padding: `0 ${pixelsToRem(2)}` },
    !!state.mappedItems && state.mappedItems.length === 0 && { borderBottomCss: 'solid' },
    props.minimal && {
      paddingTop: `${pixelsToRem(7.5)} 0`,
      outline: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      minHeight: pixelsToRem(34),
    },
    props.minimal && props.disabled && minimalDisabled,
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.inputContainerCss, params: { props, state } }),
    resolveToObject({ input: props.inputContainerCss, params: { props, state } }),
  );
};

const inputCss = ({ props, state }) => {
  const mulitpleSelectedItems = {
    minWidth: `${pixelsToRem(120)} !important`,
    width: pixelsToRem(200),
    display: 'inline',
  };
  return merge(
    {
      margin: 0,
      width: '100%',
    },
    props.simple && { cursor: 'default' },
    props.multiple && state.selectedItems.length > 0 && mulitpleSelectedItems,
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.inputCss, params: { props, state } }),
    resolveToObject({ input: props.inputCss, params: { props, state } }),
  );
};

const innerInputCss = ({ props, state }) => {
  const multipleSelected = {
    minWidth: `${pixelsToRem(120)} !important`,
    width: pixelsToRem(200),
  };
  return merge(
    {
      '::-ms-clear': {
        display: 'none', // hide clear button on ie
      },
    },
    props.simple && { cursor: 'default' },
    props.multiple && state.selectedItems.length > 0 && multipleSelected,
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.innerInputCss, params: { props, state } }),
    resolveToObject({ input: props.innerInputCss, params: { props, state } }),
  );
};

const placeholderCss = ({ props, state }) =>
  merge(
    {},
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.placeholderCss, params: { props, state } }),
    resolveToObject({ input: props.placeholderCss, params: { props, state } }),
  );

const dropDownContainerCss = ({ props, state }) =>
  merge(
    {
      borderStyle: 'solid',
      borderWidth: pixelsToRem(1),
      boxSizing: 'border-box',
      marginBottom: 0,
      marginTop: pixelsToRem(1),
      maxHeight: props.dropDownContainerCss.minHeight ? pixelsToRem(254) : pixelsToRem(264),
      outline: '1px solid transparent',
      outlineStyle: 'solid',
      outlineWidth: '0.0625rem',
      overflowY: 'auto',
      paddingLeft: 0,
      transition: 'outline-color 0.3s, border-color 0.3s',
      width: 'inherit',
    },

    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({
        input: props.theme.dropDown.containerCss,
        params: { props, state },
      }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const dropDownContainerAddCss = ({ props, state }) =>
  merge(
    {
      ...dropDownContainerCss({ props, state }),
      marginTop: 0,
      borderTopStyle: 'none',
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({
        input: props.theme.dropDown.containerCss,
        params: { props, state },
      }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const dropDownItemCss = ({ props, state }) =>
  merge(
    {
      lineHeight: pixelsToRem(24),
      minHeight: pixelsToRem(36),
      listStyle: 'none',
      cursor: 'default',
      color: 'black',
      fontSize: pixelsToRem(16),
      fontWeight: 400,
      position: 'relative',
    },
    props.displayClientId && props.isNoMatch && { padding: pixelsToRem(0) },
    props.text && {
      paddingBottom: pixelsToRem(12),
    },
    props.isAddNew && {
      fontWeight: 600,
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({
        input: props.theme.dropDown.dropDownItemCss,
        params: { props, state },
      }),
    resolveToObject({ input: props.dropDownItemCss, params: { props, state } }),
  );

const dropDownItemTextCss = ({ props, state }) =>
  merge(
    {
      padding: pixelsToRem(12, 12),
    },
    props.displayClientId && props.isNoMatch && { fontWeight: '600', padding: `${pixelsToRem(12)} ${pixelsToRem(16)}` },
    props.isGroup && {
      fontWeight: 600,
      fontSize: pixelsToRem(14),
      // textAlign: 'center',
    },
    props.selected && { fontWeight: '600' },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({
        input: props.theme.dropDown.dropDownItemTextCss,
        params: { props, state },
      }),
    resolveToObject({ input: props.dropDownItemTextCss, params: { props, state } }),
  );

const linkStyleCss = ({ props, state }) =>
  merge(
    {
      position: 'absolute',
      right: pixelsToRem(20),
      top: `calc(50% + ${pixelsToRem(2)})`,
      transform: 'translate( 0, -50%)',
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.linkStyleCss, params: { props, state } }),
    resolveToObject({ input: props.linkStyleCss, params: { props, state } }),
  );

const subTextCss = ({ props, state }) =>
  merge(
    {
      fontSize: pixelsToRem(12),
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.subTextCss, params: { props, state } }),
    resolveToObject({ input: props.subTextCss, params: { props, state } }),
  );

const iconHistoryCss = ({ props, state }) =>
  merge(
    {
      position: 'relative',
      left: 0,
      float: 'left',
      top: pixelsToRem(-3),
      paddingRight: pixelsToRem(10),
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.iconHistoryCss, params: { props, state } }),
    resolveToObject({ input: props.iconHistoryCss, params: { props, state } }),
  );

const iconCss = ({ props, state }) =>
  merge(
    {
      position: 'absolute',
      top: pixelsToRem(11),
      right: pixelsToRem(6),
    },

    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.iconCss, params: { props, state } }),
    resolveToObject({ input: props.iconCss, params: { props, state } }),
  );

const iconSelectedCss = ({ props, state }) =>
  merge(
    {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      right: pixelsToRem(10),
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.iconSelectedCss, params: { props, state } }),
    resolveToObject({ input: props.iconSelectedCss, params: { props, state } }),
  );
const containerInnerCss = ({ props, state }) =>
  merge(
    props.multiple && {
      display: 'inline-block',
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.containerInnerCss, params: { props, state } }),
    resolveToObject({ input: props.containerInnerCss, params: { props, state } }),
  );

const dropDownItemDescriptiveTextCss = ({ props, state }) =>
  merge(
    props.displayClientId && {
      fontSize: pixelsToRem(12),
      lineHeight: pixelsToRem(21),
      color: props.theme.palette.darkShadow,
    },
    !!props.theme &&
      !!props.theme.dropDown &&
      resolveToObject({ input: props.theme.dropDown.dropDownItemDescriptiveTextCss, params: { props, state } }),
    resolveToObject({ input: props.dropDownItemDescriptiveTextCss, params: { props, state } }),
  );

const root = {
  containerCss,
  containerInnerCss,
  dropDownContainerAddCss,
  dropDownContainerCss,
  dropDownItemCss,
  dropDownItemTextCss,
  dropDownSelectionSpanCss,
  iconCss,
  iconContainerCss,
  iconHistoryCss,
  iconSelectedCss,
  innerInputCss,
  inputContainerCss,
  inputCss,
  linkStyleCss,
  placeholderCss,
  minimalBorderCss,
  spin,
  subTextCss,
  dropDownItemDescriptiveTextCss,
};

export default root;
