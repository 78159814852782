import React from 'react';
import { withTheme } from 'theming';
import { Dialog } from 'MoshtixShared/component-dialog';
import { Div, Label, P } from 'MoshtixShared/component-element';
import { TextBox } from 'MoshtixShared/component-text-box';
import { Button } from 'MoshtixShared/component-button';
import { Icon } from 'MoshtixShared/component-icon';
import configuration from 'Configuration/index';
import tokenStore from 'MoshtixShared/helper-token-store';

import styles from './styles';

const copyUrl = ({ accessUrl }: { accessUrl: string }) => {
  const dummy = document.createElement('textarea');
  // to avoid breaking origin page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = accessUrl;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

const GetMobileDialogWithoutTheme = ({ onClose, theme }) => {
  const accessToken = tokenStore.getAccessToken();
  const accessUrl = `${configuration.myUrl}/access-token/${accessToken}`;

  return (
    <Dialog title="Get on mobile" show onCloseClick={onClose}>
      <P>Get access to your tickets for this event by opening this link on your mobile device.</P>
      <Div>
        <Label htmlFor="ticket-link">All tickets</Label>
        <Div css={styles.textboxBarCss}>
          <TextBox idInput="ticket-link" css={styles.textBox} value={accessUrl} />
          <Button
            css={styles.buttonCss({ theme })}
            buttonType="iconOnly"
            iconColor={theme.palette.white}
            iconLeft="copy"
            onClick={copyUrl({ accessUrl })}
          />
        </Div>
        <Div css={styles.textboxBarCss}>
          <Icon type="exclamation-triangle" color={theme.palette.warning} />
          <P css={styles.warningText({ theme })}>
            To ensure the safety of your account, don&rsquo;t share this link with others.
          </P>
        </Div>
      </Div>
    </Dialog>
  );
};

export const GetMobileDialog = withTheme(GetMobileDialogWithoutTheme);
