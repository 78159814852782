import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import merge from 'lodash/merge';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const h1Css = ({ props }) =>
  merge(
    {
      fontSize: pixelsToRem(28),
      fontFamily: "'Oswald', 'Open Sans', sans-serif",
      fontWeight: 700,
      marginBottom: pixelsToRem(20),
      lineHeight: pixelsToRem(40),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const h2Css = ({ props }) =>
  merge(
    {
      fontSize: pixelsToRem(26),
      fontFamily: "'Oswald', 'Open Sans', sans-serif",
      fontWeight: 500,
      marginBottom: pixelsToRem(20),
      lineHeight: pixelsToRem(32),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const h3Css = ({ props }) =>
  merge(
    {
      fontSize: pixelsToRem(22),
      fontFamily: "'Oswald', 'Open Sans', sans-serif",
      fontWeight: 400,
      marginBottom: pixelsToRem(10),
      lineHeight: pixelsToRem(32),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const h4Css = ({ props }) =>
  merge(
    {
      fontFamily: "'Oswald', 'Open Sans', sans-serif",
      color: props.theme.palette.darkerShadow,
      fontSize: pixelsToRem(18),
      fontWeight: 400,
      marginBottom: pixelsToRem(8),
      lineHeight: pixelsToRem(24),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const pCss = ({ props }) =>
  merge(
    {
      marginBottom: pixelsToRem(12),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const smallCss = ({ props }) =>
  merge({ fontSize: pixelsToRem(14) }, resolveToObject({ input: props.css, params: { props } }));

const strongCss = ({ props }) =>
  merge(
    {
      fontWeight: 600,
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const aCss = ({ props }) =>
  merge(
    {
      color: props.theme.palette.attention,
      cursor: 'pointer',
      transition: 'opacity 250ms',
      ':hover': {
        opacity: 0.7,
      },
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const aExternalCss = { position: 'relative', paddingRight: pixelsToRem(24) };

const aExternalIconCss = {
  position: 'absolute',
  height: pixelsToRem(16),
  width: pixelsToRem(16),
  top: pixelsToRem(2),
  right: 0,
};

const hrCss = ({ props }) =>
  merge(
    {
      border: 0,
      borderBottom: `solid ${pixelsToRem(1)} ${props.theme.palette.shadow}`,
      margin: 0,
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const ulCss = ({ props }) =>
  merge(
    {
      paddingLeft: pixelsToRem(20),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const olCss = ({ props }) =>
  merge(
    {
      paddingLeft: pixelsToRem(20),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const liCss = ({ props }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(14),
      fontWeight: 400,
      margin: 0,
      color: props.theme.palette.darkerShadow,
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const subCss = ({ props }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(14),
      fontWeight: 400,
      margin: 0,
      color: props.theme.palette.darkerShadow,
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const labelCss = ({ props }) =>
  merge({ lineHeight: pixelsToRem(24) }, resolveToObject({ input: props.css, params: { props } }));
const buttonCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const spanCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const divCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const iframeCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const imgCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));

const tableCss = ({ props }) =>
  merge(
    {
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },
    resolveToObject({ input: props.css, params: { props } }),
  );
const trCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const thCss = ({ props }) =>
  merge(
    {
      fontWeight: 500,
      textAlign: 'left',
    },
    resolveToObject({ input: props.css, params: { props } }),
  );
const tdCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const inputCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const navCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const svgCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));
const headerCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));

const textareaCss = ({ props }) => merge({}, resolveToObject({ input: props.css, params: { props } }));

const subHeadingCss = ({ props }) =>
  merge(
    {
      color: props.theme.palette.darkerShadow,
      fontSize: pixelsToRem(14),
      marginBottom: pixelsToRem(8),
    },
    resolveToObject({ input: props.css, params: { props } }),
  );

const UnnamedCheckCss = ({ props }) =>
  merge(
    {},
    resolveToObject({ input: props.css, params: { props } }),
    // if no text then overwrite the current styles
    !props.text && {
      color: props.theme.palette.darkShadow,
      fontStyle: 'italic',
      fontWeight: 300,
    },
  );

export const styles = {
  h1Css,
  h2Css,
  h3Css,
  h4Css,
  pCss,
  smallCss,
  strongCss,
  aCss,
  aExternalCss,
  aExternalIconCss,
  hrCss,
  ulCss,
  olCss,
  liCss,
  subCss,
  labelCss,
  buttonCss,
  spanCss,
  divCss,
  iframeCss,
  imgCss,
  navCss,
  svgCss,
  headerCss,
  tableCss,
  trCss,
  thCss,
  tdCss,
  inputCss,
  textareaCss,
  subHeadingCss,
  UnnamedCheckCss,
};
