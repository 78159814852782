import React, { Fragment, useState, useEffect } from 'react';
import { graphql, compose } from 'react-apollo';
import tokenStore from 'MoshtixShared/helper-token-store';
import configuration from 'Configuration/index';
import LoadingOverlay from 'ControlRoomComponents/loading-overlay';

import { history } from '../../app/history';
import { loginWithAccessToken } from './data-definition';

const loginWithAccessTokenMutation = graphql(loginWithAccessToken, {
  props: ({ mutate }) => ({
    login: (variables) => mutate({ variables }),
  }),
});

interface AccessTokenProps {
  match: {
    params: {
      accessToken: string;
    };
  };
  login: () => void;
}

const loginAsync = async ({
  accessToken,
  login,
}: {
  accessToken: string;
  login: ({ accessToken }: { accessToken: string }) => void;
}) => {
  const returnData: string = await login({ accessToken });
  return returnData;
};

const HelpTokenLoginWithoutState: React.FunctionComponent<AccessTokenProps> = ({ match, login }: AccessTokenProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (!isLoggedIn) {
      const { accessToken } = match.params;
      loginAsync({ accessToken, login }).then(({ data }: { data: { login: string } }) => {
        const jwt = data.login || null;
        if (jwt) {
          tokenStore.setTicketDistroToken({ token: jwt, domain: configuration.cookieDomain });
          tokenStore.setAccessToken({ token: accessToken, domain: configuration.cookieDomain });
        }
        setIsLoggedIn(true);
      });
    } else {
      const { orderId } = match.params;
      history.push(`/help/${orderId}`);
    }
  }, [isLoggedIn]);

  return (
    <Fragment>
      <LoadingOverlay visible />
    </Fragment>
  );
};

export const HelpTokenLogin = compose(loginWithAccessTokenMutation)(HelpTokenLoginWithoutState);
