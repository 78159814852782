import React, { Fragment } from 'react';
import { graphql, compose } from 'react-apollo';
import { get } from 'object-path';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { withTheme } from 'theming';

import tokenStore from 'MoshtixShared/helper-token-store';
import { withState } from 'MoshtixShared/component-context-provider';
import { Icon } from 'MoshtixShared/component-icon';
import LoadingOverlay from 'ControlRoomComponents/loading-overlay';
import { Header } from 'Components/component-header';
import Footer from 'Components/component-footer';
import { ZendeskDialog } from 'Components/component-zendesk-form';
import { getOrdersQuery } from '../../components/component-access-token-login/data-definition';
import { TicketDialog } from '../../components/component-ticket-dialog';

import { TicketDistroContext } from '../../app/contexts';
import { EventDetails } from './component-event-header';
import { TicketList } from './component-ticket-list';
import { withHistory } from './withHistory';

import styles from './styles';

const getOrdersQueryData = graphql(getOrdersQuery, {
  name: 'orderData',
  options: (props) => ({
    variables: {
      token: tokenStore.getTicketDistroToken(),
    },
  }),
  props: ({ ownProps, orderData: { loading, viewer, refetch, networkStatus } }) => ({
    ...ownProps,
    orderData: {
      // if loading or networkStatus is refetching
      // pending fix with apollo network status though https://github.com/apollographql/apollo-client/issues/1257
      loading: loading || networkStatus === 4,
      viewer,
      refetchEvent: refetch,
    },
  }),
});

interface EventProps {
  ticketDistroContext: {
    state: {
      event: object;
    };
    actions: {
      setState: (param: any) => void;
    };
  };
  ticketDialogVisible: boolean;
  onDialogClose?: () => void;
}

interface EventState {
  isLoading: boolean;
}

export class EventPage extends React.Component<EventProps, EventState> {
  state = {
    isLoading: !get(this.props, 'ticketDistroContext.state.dataLoaded', true),
  };

  shouldComponentUpdate(nextProps: EventProps, nextState: { isLoading: boolean }) {
    // check if the get order query has returned something
    const loadedData = get(nextProps, 'orderData.viewer.getOrders.items', false);
    // need to strip out refetchEvent as the signature of the function will change giving false positive
    const nextOrderDataWithoutRefetch = { ...nextProps.orderData, ...{ refetchEvent: null } };
    const thisPropsOrderDataWithoutRefetch = { ...this.props.orderData, ...{ refetchEvent: null } };
    if (!isEqual(nextOrderDataWithoutRefetch, thisPropsOrderDataWithoutRefetch) && loadedData) {
      const { setState } = this.props.ticketDistroContext.actions;
      setState({ getOrders: nextProps.orderData.viewer.getOrders, dataLoaded: true });
      this.setState({ isLoading: false });
      return true;
    } else if (!isEmpty(nextProps) && isEmpty(this.props)) {
      return true;
    } else if (nextProps.ticketDialogVisible !== this.props.ticketDialogVisible) {
      // ticket list item was clicked and dialog is now visible
      return true;
    } else if (
      nextState.isLoading !== this.state.isLoading ||
      !isEqual(nextProps.ticketDistroContext, this.props.ticketDistroContext)
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { props } = this;
    const { isLoading } = this.state;
    const { theme } = props;

    const isHelpVisible = get(props, 'ticketDistroContext.state.isHelpVisible', false);
    const helperOrder = get(props, 'ticketDistroContext.state.helpOrder', 0);
    const infoColor = get(theme, 'palette.info', '#000');
    // use first order for event name currently no cross event ticket functionality exists
    const eventName = get(props, 'ticketDistroContext.state.getOrders.items.0.event.name', '');
    return (
      <Fragment>
        <Header />
        <div>
          <LoadingOverlay visible={isLoading} />
          <EventDetails {...props} />
          <TicketList {...props} />
          {eventName && (
            <ZendeskDialog
              headerCss={styles.headerCss}
              iconLeft={<Icon type="question" color={infoColor} height={36} width={36} css={styles.iconCss} />}
              headerSubtext={`Order: ${helperOrder}`}
              title={`Help with Order: ${eventName}`}
              show={isHelpVisible}
            />
          )}
          {props.ticketDialogVisible && <TicketDialog {...props} />}
          <div className="dialog-root" />
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export const Event = compose(
  getOrdersQueryData,
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  withHistory,
  withTheme,
)(EventPage);
