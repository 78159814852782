import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'theming';
import { Div } from 'MoshtixShared/component-element';

import styles from './styles';

const OverlayComponent = (props) => (
  <Div
    id={props.id}
    name={props.name}
    className={props.className}
    onClick={props.onClick}
    css={styles.containerStyle(props)}
  >
    {props.children}
  </Div>
);

OverlayComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  heading: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/no-unused-prop-types
  visible: PropTypes.bool,
  children: PropTypes.node,
};

OverlayComponent.defaultProps = {
  id: '',
  name: '',
  heading: '',
  className: '',
  css: {},
  visible: false,
  children: undefined,
  onClick: () => {},
};

const ThemedOverlay = withTheme(OverlayComponent);
export const Overlay = (props) => <ThemedOverlay {...props} />;
Overlay.propTypes = OverlayComponent.propTypes;
Overlay.defaultProps = OverlayComponent.defaultProps;

export default Overlay;
