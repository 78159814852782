import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const alertCss = ({ props }) =>
  merge(
    {
      fontSize: pixelsToRem(12),
      fontWeight: 300,
      left: 0,
      paddingLeft: pixelsToRem(20),
      marginBottom: pixelsToRem(10),
      opacity: 0,
      transition: 'all 0.3s',
      transform: `translate(${pixelsToRem(30)}, 0)`,
    },
    props.text ? { opacity: 1, transform: 'translate(0, 0)' } : {},
    !!props.theme &&
      !!props.theme.alert &&
      !!props.theme.alert &&
      resolveToObject({ input: props.theme.alert.alertCss, params: { props } }),
    resolveToObject({ input: props.css, params: { props } }),
  );

const iconCss = ({ props }) =>
  merge(
    {
      bottom: 0,
      left: 0,
      height: 15,
      position: 'absolute',
      top: `calc(50% - ${pixelsToRem(7.5)})`,
      width: 15,
    },
    !!props.theme &&
      !!props.theme.alert &&
      !!props.theme.alert &&
      resolveToObject({ input: props.theme.alert.iconCss, params: { props } }),
    resolveToObject({ input: props.iconCss, params: { props } }),
  );

export default {
  alertCss,
  iconCss,
};
