import React from 'react';
import PropTypes from 'prop-types';
import { Div, Img } from 'glamorous';
import Overlay from 'MoshtixShared/component-overlay';
import MoshtixLogo from './loader-pulsate.gif';
import styles from './styles';

const overlayIncludingContainer = (props) => (
  <Overlay visible={props.visible} css={styles.overlayCss}>
    <Div css={styles.loadingContainerCss}>
      <Img css={styles.imageCss} alt="Loading..." src={MoshtixLogo} />
    </Div>
  </Overlay>
);

const overlayExcludingContainer = () => (
  <Div css={styles.loadingContainerCenteredCss}>
    <Img css={styles.imageCss} alt="Loading..." src={MoshtixLogo} />
  </Div>
);

const LoadingOverlay = (props) => {
  if (props.includeContainer) {
    return overlayIncludingContainer(props);
  }
  return overlayExcludingContainer();
};

LoadingOverlay.propTypes = {
  visible: PropTypes.bool,
  includeContainer: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  visible: false,
  includeContainer: true,
};

export default LoadingOverlay;
