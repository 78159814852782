import gql from 'graphql-tag';

export const loginWithAccessToken = gql`
  mutation login($accessToken: String) {
    login(accessToken: $accessToken, isCustomer: true)
  }
`;

export const getOrdersQuery = gql`
  query getOrders($token: String) {
    viewer(token: $token) {
      getOrders(pageIndex: 0, pageSize: 10, sortBy: ID, sortByDirection: DESC) {
        items {
          id
          channel
          createdDate
          customer {
            id
            firstName
            lastName
            email
            telephone
            mobile
          }
          event {
            id
            name
            client {
              id
            }
            startDate
            endDate
            description
            reissueFee
            peerToPeerResaleEnabled
            digitalTicketsOnly
            images {
              items {
                id
                url
                type
                dimensions {
                  width
                  height
                  globalId
                }
              }
            }
            resalePeriods {
              items {
                id
                ticketTypes(pageSize: 100) {
                  items {
                    ticketType {
                      id
                      name
                    }
                  }
                }
                startDate
                endDate
              }
            }
            venue {
              id
              name
              address {
                locality
                region
              }
            }
            ageRestriction
            ticketFinePrint
            ticketsRequireAttendeeName
            ticketsRequireAttendeeDob
          }
          tickets {
            items {
              id
              firstName
              lastName
              dateOfBirth
              ticketTypeName
              collectorTicket
              event {
                id
                name
              }
              ticketType {
                id
                name
                description
                complimentary
                suppressDistribution
              }
              reissued
              refunded
              cancelled
              valid
              pdfDownloadUrl
              walletDownloadUrl
              ticketNumber
              onTicketMessage
              totalCost
              discount
              barcode
            }
          }
        }
      }
    }
  }
`;
