import React from 'react';

import GridComponent from './component-grid';
import CellComponent from './component-cell';
import HideComponent from './component-hide';

class ResponsiveGridClass {
  constructor({ configuration }) {
    this.configuration = configuration;
  }

  Grid = (props) => <GridComponent {...props} configuration={this.configuration} />;
  Cell = (props) => <CellComponent {...props} configuration={this.configuration} />;
  Hide = (props) => <HideComponent {...props} configuration={this.configuration} />;
}

export { ResponsiveGridClass };
export default ResponsiveGridClass;
