import React, { lazy, Suspense, Fragment } from 'react';
import { ApolloProvider } from 'react-apollo';

import { Route, Router, Switch } from 'react-router-dom';
import * as smoothscroll from 'smoothscroll-polyfill';

import ApolloClient from 'MoshtixShared/helper-apollo-client';
import { theme } from 'MoshtixShared/helper-theme-ticket-distro';

import Theme from 'MoshtixShared/component-theme';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Div } from 'MoshtixShared/component-element';

import configuration from 'Configuration/index';
import { history } from './history';
import './global-styles';
import { AccessTokenLogin } from '../components/component-access-token-login';
import { Redirecter } from '../components/component-redirecter';
import { ContextProvider } from '../components/component-context-provider';
import { HelpTokenLogin } from '../components/component-help-token-login';

import { TicketDistroContext } from './contexts';
import { DATA } from './mock-data';

export const client = ApolloClient({
  graphqlUrl: configuration.graphqlUrl,
  redirectUrl: `//${configuration.publicWebsiteOriginalUrl}/v2/account/login`,
});

const EventLazy = lazy(() => import('../pages/event').then((module) => ({ default: module.Event })));

const HelpLazy = lazy(() => import('../pages/help').then((module) => ({ default: module.HelpForm })));

const Event = (props: any) => (
  <Suspense fallback={<div>Loading..</div>}>
    <EventLazy {...props} />
  </Suspense>
);

const HelpForm = (props: any) => (
  <Suspense fallback={<div>Loading..</div>}>
    <HelpLazy {...props} />
  </Suspense>
);
smoothscroll.polyfill();

const App = () => (
  <Fragment>
    <Router history={history}>
      <Theme palette={theme.palette}>
        <ApolloProvider client={client}>
          <SkeletonTheme color={theme.palette.darkShadow} highlightColor={theme.palette.darkerShadow}>
            <Div>
              <ContextProvider stateContext={TicketDistroContext} contextName="ticketDistroContext" initialState={DATA}>
                <Switch>
                  <Route path="/" exact render={() => <Redirecter />} />
                  <Route path="/access-token/:accessToken" exact render={(props) => <AccessTokenLogin {...props} />} />
                  <Route path="/event/:eventId" exact component={Event} />
                  <Route path="/help/:accessToken/:orderId" exact render={(props) => <HelpTokenLogin {...props} />} />
                  <Route path="/help/:orderId" exact component={HelpForm} />
                  <Route
                    path="/event/:eventId/ticket/:ticketId"
                    exact
                    render={(props) => (
                      <Fragment>
                        <Event {...props} ticketDialogVisible />
                      </Fragment>
                    )}
                  />
                </Switch>
              </ContextProvider>
            </Div>
          </SkeletonTheme>
        </ApolloProvider>
      </Theme>
    </Router>
    <div className="dialog-root" />
  </Fragment>
);

export default App;
