import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

const mediumQuery = configuration.getMediaQuery('medium');

const backgroundCss = ({ props }) => ({
  background: props.theme.palette.lightShadow,
});

const innerBackgroundCss = () => ({
  maxWidth: pixelsToRem(630),
  padding: pixelsToRem(24),
});

const headingCss = ({ props }) => ({
  fontFamily: 'Oswald, sans-serif',
  fontSize: pixelsToRem(22),
  lineHeight: 0.95,
  color: props.theme.palette.black,
  marginBottom: pixelsToRem(21),
});

const buttonBarCss = () => ({
  marginLeft: pixelsToRem(20),
  display: 'flex',
  marginBottom: pixelsToRem(28),
});

const selectAllCheckCss = () => ({
  marginTop: pixelsToRem(10),
  marginBottom: pixelsToRem(10),
  marginRight: 'auto',
});

const buttonBarItemCss = ({ props }) => ({
  color: props.theme.palette.attention,
});

const getOnMobileCss = ({ props }) => ({
  ...buttonBarItemCss({ props }),
  display: 'none',
  [mediumQuery]: {
    display: 'initial',
  },
});

const tempSpaceCss = () => ({
  marginBottom: pixelsToRem(30),
});

const cardCss = ({ props }) => ({
  marginTop: pixelsToRem(18),
  marginBottom: pixelsToRem(30),
  height: pixelsToRem(108),
  borderRadius: pixelsToRem(4),
  backgroundColor: props.theme.palette.white,
  display: 'flex',
});

const flagTriangleCss = ({ props }) => ({
  width: pixelsToRem(0),
  height: pixelsToRem(0),
  backgroundColor: 'transparent',
  border: `11px solid ${props.theme.palette.attention}`,
  borderTopLeftRadius: pixelsToRem(4),
  borderRight: '11px solid transparent',
  borderBottom: '11px solid transparent',
});

const ageWarningCss = ({ props }) => ({
  '&::before': {
    // circle css
    content: '""',
    display: 'block',
    position: 'absolute',
    width: pixelsToRem(38),
    height: pixelsToRem(38),
    borderRadius: '50%',
    left: 0,
    top: 0,
    border: `${pixelsToRem(2)} solid ${props.theme.palette.error}`,
  },
  '&::after': {
    // 18+ text
    content: '"18+"',
    display: 'block',
    position: 'absolute',
    fontSize: pixelsToRem(18),
    top: pixelsToRem(9),
    left: pixelsToRem(7),
    fontWeight: 600,
    letterSpacing: pixelsToRem(-1),
  },
  position: 'relative',
  paddingLeft: pixelsToRem(50),
  color: props.theme.palette.error,
  minHeight: pixelsToRem(42),
});

const furtherInformation = {
  '& > *': {
    marginBottom: pixelsToRem(20),
  },
};

export default {
  ageWarningCss,
  backgroundCss,
  furtherInformation,
  innerBackgroundCss,
  headingCss,
  selectAllCheckCss,
  buttonBarCss,
  tempSpaceCss,
  cardCss,
  flagTriangleCss,
  buttonBarItemCss,
  getOnMobileCss,
};
