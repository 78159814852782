import React from 'react';
import { withTheme } from 'theming';
import PropTypes from 'prop-types';
import { DropDownRaw } from './index';

const DropDownSimpleComponent = (props) => (
  <DropDownRaw
    {...props}
    showAdd={false}
    canFetchMoreData={false}
    historyItems={[]}
    simple
    multiple={false}
    noMatchText=""
    showAddText=""
    showDropDownOnFocus
    showNoMatch={false}
  />
);

DropDownSimpleComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropDownSelectItemCss: PropTypes.shape(),
  dropDownSelectCss: PropTypes.shape(),
  iconClearCss: PropTypes.shape(),
  inputContainerCss: PropTypes.shape(),
  inputCss: PropTypes.shape(),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
  css: PropTypes.shape(),
};

DropDownSimpleComponent.defaultProps = {
  className: '',
  css: {},
  disabled: false,
  dropDownSelectCss: {},
  dropDownSelectItemCss: {},
  iconClearCss: {},
  inputContainerCss: {},
  inputCss: {},
  items: [],
  selectedItems: [],
};

const ThemedDropDownSimple = withTheme(DropDownSimpleComponent);
const DropDownSimple = (props) => <ThemedDropDownSimple {...props} />;
DropDownSimple.propTypes = DropDownSimpleComponent.propTypes;
DropDownSimple.defaultProps = DropDownSimpleComponent.defaultProps;

export const ComponentRaw = DropDownSimpleComponent;
export default DropDownSimple;
