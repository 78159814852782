import React from 'react';
import { withTheme } from 'theming';
import PropTypes from 'prop-types';
import { Div, Span, Button } from 'MoshtixShared/component-element';
import Icon from 'MoshtixShared/component-icon';

import styles from './styles';

const ChipComponent = (props) => (
  <Div onMouseUp={props.onMouseUp} className={props.className} css={styles.containerCss({ props })}>
    <Span css={styles.spanCss({ props })}>{props.label}</Span>
    {!props.disabled && (
      <Button
        type="button"
        css={styles.buttonCss({ props })}
        onClick={() => {
          props.onRemove(props.label);
        }}
      >
        <Icon type="cross" css={styles.iconCss({ props })} />
      </Button>
    )}
  </Div>
);

ChipComponent.propTypes = {
  buttonCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  label: PropTypes.string,
  onRemove: PropTypes.func,
  onMouseUp: PropTypes.func,
  spanCss: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
  css: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
};

ChipComponent.defaultProps = {
  buttonCss: {},
  className: '',
  disabled: false,
  iconCss: {},
  label: '',
  onRemove: () => {},
  onMouseUp: () => {},
  spanCss: {},
  css: {},
};

const ThemedChip = withTheme(ChipComponent);
const Chip = (props) => <ThemedChip {...props} />;
Chip.propTypes = ChipComponent.propTypes;
Chip.defaultProps = ChipComponent.defaultProps;

export default Chip;
