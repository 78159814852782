import React, { useState, useEffect } from 'react';
import { get, del } from 'object-path';
import noop from 'lodash/noop';

import { Div, P, A, H3, Span } from 'MoshtixShared/component-element';
import { DynamicForm } from 'MoshtixShared/component-dynamic-form';
import Button from 'MoshtixShared/component-button';
import { Icon } from 'MoshtixShared/component-icon';

import styles from './styles';

const helpFormConfig = ({ event, order, topics, selectedCategory, selectedSubTopic, selectedTopic }) => {
  let topicList = null;
  let subTopicList = null;
  const form = {
    form: [
      {
        type: 'content',
        content: (
          <P>
            Need quick help on a popular topic?{' '}
            <A href="https://tixsupport.moshtix.com.au/hc/en-us" target="_blank" css={styles.linkCss}>
              Visit our FAQ&apos;s
            </A>
          </P>
        ),
      },
      {
        type: 'dropdown',
        id: 'category',
        label: 'Category:',
        items: topics.map((topic) => ({ value: topic.description, key: topic.id })),
      },
    ],
  };
  if (selectedCategory) {
    const availableTopicList = topics.find((category) => category.id === selectedCategory);
    if (availableTopicList) {
      topicList = availableTopicList.subtopics.items || [];
    }
    if (topicList && topicList.length > 0) {
      const topicsFormInput = {
        type: 'dropdown',
        id: 'topic',
        label: 'Topic:',
        placeholderText: 'Choose a topic',
        items: topicList.map((topic) => ({ value: topic.description, key: topic.id })),
      };
      form.form.push(topicsFormInput);
    }
  }

  if (selectedTopic) {
    const availableSubTopicList = topics
      .find((category) => category.id === selectedCategory)
      .subtopics.items.find((topicItem) => topicItem.id === selectedTopic);
    if (availableSubTopicList) {
      subTopicList = availableSubTopicList.subtopics.items || [];
    }
    if (subTopicList && subTopicList.length > 0) {
      const subTopicsFormInput = {
        type: 'dropdown',
        id: 'subtopic',
        label: 'Subtopic:',
        placeholderText: 'Choose a subtopic',
        items: subTopicList.map((topic) => ({ value: topic.description, key: topic.id })),
      };
      form.form.push(subTopicsFormInput);
    }
  }

  const messageField = {
    type: 'text-area',
    id: 'message',
    label: 'Message:',
    inputCss: styles.textBoxCss,
  };

  form.form.push(messageField);

  return form;
};

export const ZendeskFormWithoutThemeAndState = (props) => {
  const { theme, ticketDistroContext, onChange = noop, orderId } = props;
  const orders = get(ticketDistroContext, 'state.getOrders.items', []);
  const order = orders.find((item) => item.id === orderId);
  const topics = get(props, 'topicsData.viewer.getTopics.items', []);
  const [isSuccess, setIsSuccess] = useState(props.ticketDistro);
  const [isError, setIsError] = useState(false);
  const [ticketId, setTicketId] = useState(0);

  useEffect(() => setIsSuccess(get(ticketDistroContext, 'state.helpIsSuccess', false)), [
    props.ticketDistroContext.state.helpIsSuccess,
  ]);
  useEffect(() => setIsError(get(ticketDistroContext, 'state.helpIsError', false)), [
    props.ticketDistroContext.state.helpIsError,
  ]);
  useEffect(() => setTicketId(get(ticketDistroContext, 'state.helpTicketId', 0)), [
    props.ticketDistroContext.state.helpTicketId,
  ]);

  const [selectedCategory, setCategory] = useState(1);
  const [selectedTopic, setTopic] = useState(0);
  const [selectedSubTopic, setSubTopic] = useState(0);

  const [formState, setFormState] = useState({});
  return (
    <Div css={styles.formBackground({ theme })}>
      {isSuccess && (
        <div>
          <Div css={styles.infoContainerCss}>
            <Icon type="mail-checkmark" width={49} height={35} color={theme.palette.attention} />
            <H3 css={styles.errorHeadingCss}>Your query has been submitted!</H3>
          </Div>
          <P css={styles.infoTextCss}>
            Help Reference Case Number: <Span css={styles.linkCss}>#{`${ticketId}`}</Span>
          </P>
          <P css={styles.infoTextCss}>
            Check your email inbox for confirmation. All replies will be sent to your email saved on your account:{' '}
            <Span css={styles.linkCss}>{`${order.customer.email}`}</Span>
          </P>
        </div>
      )}
      {isError && (
        <div>
          <Div css={styles.infoContainerCss}>
            <Icon type="exclamation" width={32} height={32} color={theme.palette.error} />
            <H3 css={styles.errorHeadingCss}>Oh no! There was an error sending your message</H3>
          </Div>
          <P css={styles.infoTextCss}>
            Unfortunately, your message was not able to be sent through. Please try again or{' '}
            <A href="https://tixsupport.moshtix.com.au/hc/en-us" target="_blank" css={styles.linkCss}>
              visit our FAQ&apos;s
            </A>{' '}
            to quickly find answers on popular topics.
          </P>
        </div>
      )}
      {!isSuccess && !isError && (
        <DynamicForm
          formConfig={helpFormConfig({
            event: orders[0].event,
            topics,
            selectedCategory,
            selectedTopic,
            selectedSubTopic,
            setCategory,
            setTopic,
            setSubTopic,
          })}
          initialChanges={{
            category: {
              value: "I'd like help with my order",
              key: 1,
            },
          }}
          onChange={(newFormState) => {
            const changedCategory = get(newFormState, 'changes.category', null);
            const changedTopic = get(newFormState, 'changes.topic', null);
            if (changedCategory && changedCategory.key !== selectedCategory) {
              setCategory(newFormState.changes.category.key);
              // unset if we select a new option
              setTopic(0);
              setSubTopic(0);
              del(newFormState, 'changes.topic');
              del(newFormState, 'changes.subtopic');
            }
            if (changedTopic && changedTopic.key !== selectedTopic) {
              setTopic(newFormState.changes.topic.key);
              // unset if we select a new option
              setSubTopic(0);
              del(newFormState, 'changes.subtopic');
            }
            onChange(newFormState);
            setFormState(newFormState);
          }}
        />
      )}
    </Div>
  );
};
