import { ThemeInterface } from 'Types/theme';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const largeQuery = configuration.getMediaQuery('large');

const cardBackground = ({
  theme,
  selected,
  next,
  prev,
}: {
  theme: ThemeInterface;
  selected: boolean;
  next: boolean;
  prev: boolean;
}) => ({
  backgroundColor: theme.palette.white,
  display: selected || next || prev ? 'block' : 'none',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  overflowY: 'scroll',
  overflowX: 'hidden',
  // eslint-disable-next-line no-nested-ternary
  zIndex: selected ? 3 : next || prev ? 2 : 1,
  [largeQuery]: {
    width: 720,
    height: 430,
    top: '50%',
    left: '50%',
    // eslint-disable-next-line no-nested-ternary
    transform: prev
      ? 'translate(calc(-50% - 102px), -50%) scale(0.8)'
      : next
      ? 'translate(calc(-50% + 102px), -50%) scale(0.8)'
      : 'translate(-50%, -50%)',
    position: 'absolute',
    minWidth: 0,
    bottom: 'auto',
    right: 'auto',
    overflow: 'hidden',
  },
});

const ticketDetailsButtonCss = {
  position: 'absolute',
  top: 30,
  left: 30,
  height: 16,
  width: 16,
  overflow: 'hidden',
  zIndex: 4,
  backgroundColor: 'red',
  [largeQuery]: {
    display: 'none',
  },
};

export default {
  cardBackground,
  ticketDetailsButtonCss,
};
