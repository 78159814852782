import React from 'react';
import { get } from 'object-path';
import moment from 'moment';
import configuration from 'Configuration/index';
import { withTheme } from 'theming';
import { Div, H4, P, A } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import { ThemeInterface } from 'Types/theme';
import { EventInterface, TicketInterface } from '../../../types/app';
import styles from './styles';

interface TicketActionsProps {
  ticket: TicketInterface;
  order: any;
  event: EventInterface;
  theme: ThemeInterface;
}

const isMoreThan3HrsBeforeStart = ({ eventStartDate }: { eventStartDate: string }) => {
  const startDate = moment(eventStartDate);
  const diff = startDate.diff(moment(), 'hours');
  return diff > 3;
};

const SUPPRESSED_TICKET_TEXT = 'If this ticket needs scanning, we’ll release the barcode closer to the event.';

const renderExplanation = ({
  ticket,
}: {
  scanned: boolean;
  valid: boolean;
  ticketType: { suppressDistribution: boolean };
}): string => {
  if (ticket.ticketType.suppressDistribution === true) {
    return SUPPRESSED_TICKET_TEXT;
  }
  return '';
};

const TicketActionsWithoutTheme: React.SFC<TicketActionsProps> = ({
  event,
  order,
  ticket,
  theme,
}: TicketActionsProps) => {
  const eventStartDate = get(event, 'startDate', null);
  const canReissue = eventStartDate ? isMoreThan3HrsBeforeStart({ eventStartDate }) : false;
  const resalePeriods = get(event, 'resalePeriods.items', []);

  let canResell = false;
  const isPeerToPeerResaleEnabled = event.peerToPeerResaleEnabled;

  let resalePeriodDates: { startDate: string; endDate: string } | null = null;
  if (resalePeriods && ticket) {
    // if there are resale periods find the appropriate resale period if available
    resalePeriods.forEach((resalePeriod: {}) => {
      const resalePeriodTicketTypes = get(resalePeriod, 'ticketTypes.items', []);
      const resalePeriodStartDate = get(resalePeriod, 'startDate', null);
      const resalePeriodEndDate = get(resalePeriod, 'endDate', null);
      const isResalePeriod = resalePeriodTicketTypes.find(
        (ticketTypeConnection: { ticketType: { id: number } }) =>
          ticketTypeConnection.ticketType.id === ticket.ticketType.id,
      );
      resalePeriodDates =
        isResalePeriod && !resalePeriodDates
          ? { startDate: resalePeriodStartDate, endDate: resalePeriodEndDate }
          : null;
    });

    if (resalePeriodDates) {
      // found a result so we can now see if today is within that resale period start end date
      canResell = moment().isBetween(moment(resalePeriodDates.startDate), moment(resalePeriodDates.endDate));
    }
  }

  const showDateOfBirth = event.ticketsRequireAttendeeDob && ticket.dateOfBirth;

  const dateOfBirthFormatted = moment(ticket.dateOfBirth).format('D MMM YYYY');
  const isValidTicket = !ticket.scanned && ticket.valid && !ticket.ticketType.suppressDistribution;

  return ticket ? (
    <Div>
      <H4 css={styles.detailsHeadingCss({ theme })}>Details</H4>
      {ticket.onTicketMessage && <P css={styles.onTicketMessage({ theme })}>{ticket.onTicketMessage}</P>}
      <div>
        {event.ticketsRequireAttendeeName ? (
          // if ticket requires attendee name
          <Div css={styles.actionContainer}>
            <Div css={styles.iconCircle({ active: true, theme })}>
              <Icon type="attendee" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>
                {ticket.firstName ? ticket.firstName : ''} {ticket.lastName ? ticket.lastName : ''}{' '}
                {showDateOfBirth ? `(${dateOfBirthFormatted})` : ''}
              </P>
              <P css={styles.ticketActionTextCss({ theme })}>
                Name{event.ticketsRequireAttendeeDob ? ' and DOB ' : ' '}must match valid ID of attendee.
              </P>
              {canReissue && (
                <A
                  href={`https://${configuration.publicWebsiteOriginalUrl}/v2/account/reissue-my-ticket/${order.id}`}
                  css={{ marginTop: '10px', display: 'inline-block' }}
                >
                  CHANGE NAME ON TICKET
                </A>
              )}
            </Div>
          </Div>
        ) : (
          // if ticket DOES NOT require attendee name
          <Div css={styles.actionContainer}>
            <Div css={styles.iconCircle({ active: false, theme })}>
              <Icon type="attendee" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>Anyone can use this ticket</P>
              <P css={styles.ticketActionTextCss({ theme })}>
                This ticket can be used by anyone subject to this event&#39;s age restrictions and Terms &amp;
                Conditions
              </P>
            </Div>
          </Div>
        )}
        {isPeerToPeerResaleEnabled ? (
          <A
            href={`https://${configuration.publicWebsiteOriginalUrl}/v2/account/re-sell-my-ticket/${order.id}`}
            css={styles.actionContainer}
          >
            <Div css={styles.iconCircle({ active: true, theme })}>
              <Icon type="peer-to-peer-resale" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>Resell Tickets</P>
              <P css={styles.ticketActionTextCss({ theme })}>
                Put your tickets up for resale via your unique link and sell directly to your friends using the official
                Moshtix Resale facility.
              </P>
            </Div>
          </A>
        ) : (
          <Div css={styles.actionContainer}>
            <Div css={styles.iconCircle({ active: false, theme })}>
              <Icon type="peer-to-peer-resale" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>Ticket Resale is not available</P>
              <P css={styles.ticketActionTextCss({ theme })}>
                The official Moshtix Ticket Resale facility is not available for this ticket at the moment. Check back
                closer to the event to see if there’s any change.
              </P>
            </Div>
          </Div>
        )}
        {canResell ? (
          <A
            href={`https://${configuration.publicWebsiteOriginalUrl}/v2/account/return-my-ticket/${order.id}`}
            css={styles.actionContainer}
          >
            <Div css={styles.iconCircle({ active: true, theme })}>
              <Icon type="ticket-return" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>Request Tickets Return</P>
              <P css={styles.ticketActionTextCss({ theme })}>
                Request to return your tickets back to Moshtix / the Event Organiser for a refund (less service fees)
                using the offical Moshtix Ticket Return facility.
              </P>
            </Div>
          </A>
        ) : (
          <Div css={styles.actionContainer}>
            <Div css={styles.iconCircle({ active: false, theme })}>
              <Icon type="ticket-return" />
            </Div>
            <Div>
              <P css={styles.ticketDetailsLabelCssSentenceCase({ theme })}>Ticket Return is not available</P>
              <P css={styles.ticketActionTextCss({ theme })}>
                The official Moshtix Ticket Return facility is not available for this ticket at the moment. Check back
                closer to the event to see if there’s any change.
              </P>
            </Div>
          </Div>
        )}
      </div>

      {ticket.ticketType.paperTicketOnly && (
        <Div css={styles.mustPrintWarning({ theme })}>
          <Div css={styles.mustPrintWarningIcon()}>
            <Icon type="print" color="#ffffff" />
          </Div>
          <P css={styles.mustPrintWarningText()}>This ticket must be printed and presented for entry.</P>
        </Div>
      )}
      {!isValidTicket && renderExplanation({ ticket }) !== '' && (
        <P css={styles.noBarcodeExplanation({ theme })}>{renderExplanation({ ticket })}</P>
      )}
    </Div>
  ) : null;
};

export const TicketActions = withTheme(TicketActionsWithoutTheme);
