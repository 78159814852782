import merge from 'lodash/merge';

const containerStyle = (props) =>
  merge(
    {
      width: '100%',
      height: '100%',
      zIndex: 1001,
      backgroundColor: 'rgba(0, 0 ,0 , 0.6)',
      position: 'fixed',
      top: 0,
      right: 0,
      display: 'flex',
      userSelect: 'none',
    },
    !props.visible && { display: 'none' },
    props.theme.overlay && props.theme.overlay.containerCss && props.theme.overlay.containerCss,
    props.css,
  );

const root = {
  containerStyle,
};

export default root;
