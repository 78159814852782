import gql from 'graphql-tag';

export const saveZendeskTicketMutation = gql`
  mutation($token: String, $supportTicket: SupportTicketInput!) {
    viewer(token: $token) {
      supportTicket: saveSupportTicket(supportTicket: $supportTicket)
    }
  }
`;

export const getHelpTopicsQuery = gql`
  query getTopics($token: String) {
    viewer(token: $token) {
      viewer {
        id
        hasEventAccess
      }
      getTopics {
        items {
          id
          description
          displayOrdinal
          subtopics {
            items {
              id
              displayOrdinal
              description
              subtopics {
                items {
                  id
                  displayOrdinal
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
