import React, { Fragment } from 'react';
import { get } from 'object-path';
import { compose } from 'react-apollo';
import { withTheme } from 'theming';
import { Div, H1, H2, P, Img, A } from 'MoshtixShared/component-element';
import { Section } from 'MoshtixShared/component-section';
import { withState } from 'MoshtixShared/component-context-provider';
import { AddToCalendar } from 'MoshtixShared/component-add-to-calendar';
import { getRegionDateFromUTCDateAndRegion, getStartInRelativeTime } from 'MoshtixShared/helper-timezone';
import { Icon } from 'MoshtixShared/component-icon';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { getCookie } from 'MoshtixShared/helper-cookie';
import Skeleton from 'react-loading-skeleton';

import { TicketDistroContext } from '../../../app/contexts';
import styles from './styles';

const EventDetailsWithoutThemeandState: React.SFC<{}> = ({ ticketDistroContext, theme }) => {
  const { event, customer } = ticketDistroContext.state.getOrders.items[0];
  const { dataLoaded } = ticketDistroContext.state;
  const { name, startDate, venue } = event;
  const { email } = customer;
  const thumbnail = get(
    event,
    'images.items.0.url',
    'https://moshtix.com.au/v2/assets/img/common/thumbnail_default_moshtix.png',
  );

  // not being used and its messing with the google maps query
  // eslint-disable-next-line no-underscore-dangle
  delete venue.address.__typename;
  const googleMapsQuery = `${venue.name} ${Object.values(venue.address).join(' ')}`;
  return dataLoaded ? (
    <Div css={styles.backgroundCss({ theme })}>
      <Section maxWidth={630}>
        <H1 css={styles.headingCss}>My Tickets</H1>
        <P css={styles.labelCss({ theme })}>{email}</P>
        <Div css={styles.flexContainerCss}>
          <Img src={thumbnail} css={styles.eventThumbnailCss} />
          <H2 css={styles.eventNameCss}>{name}</H2>
        </Div>
        <Div css={styles.eventDetailsWrapperCss}>
          <Div css={styles.eventDetailsContainerCss}>
            <Div css={styles.circleCss({ theme })}>
              <Icon type="date-time" />
            </Div>
            <Div css={styles.eventDetailsCss}>
              {startDate && (
                <Fragment>
                  <P css={styles.semiBoldLabelCss}>
                    {getStartInRelativeTime({
                      startTimeUTC: startDate,
                      prefix: 'Starts in',
                      finishedText: 'This event has already started',
                    })}
                  </P>
                  <P css={styles.labelCss({ theme })}>
                    {getRegionDateFromUTCDateAndRegion({
                      utcDate: startDate,
                      region: venue.address.region,
                      format: 'ddd DD MMM YYYY[,]h[:]mmA',
                    })}
                  </P>
                  <AddToCalendar event={event} />
                </Fragment>
              )}
            </Div>
          </Div>
          <Div css={styles.eventDetailsContainerCss}>
            <Div css={styles.circleCss({ theme })}>
              <Icon type="location" />
            </Div>
            <Div css={styles.eventDetailsCss}>
              <P css={styles.semiBoldLabelCss}>{venue.name}</P>
              <P css={styles.labelCss({ theme })}>{`${venue.address.locality}, ${venue.address.region}`}</P>
              <A
                css={styles.linksCss}
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`}
              >
                Open maps
              </A>
            </Div>
          </Div>
        </Div>
      </Section>
    </Div>
  ) : (
    <Div css={styles.backgroundCss({ theme })}>
      <Section maxWidth={630}>
        <Skeleton height={41} />
        <Skeleton />
        <Div css={styles.flexContainerCss}>
          <Skeleton height={90} width={90} />
          <div style={{ marginLeft: pixelsToRem(10) }} />
          <Skeleton height={41} width={200} />
        </Div>
        <Div css={styles.eventDetailsWrapperCss}>
          <Div css={styles.eventDetailsContainerCss}>
            <Skeleton circle height={38} width={38} />
            <Div css={styles.eventDetailsCss}>
              <Skeleton count={3} />
            </Div>
          </Div>
          <div style={{ marginLeft: pixelsToRem(20) }} />
          <Div css={styles.eventDetailsContainerCss}>
            <Skeleton circle height={38} width={38} />
            <Div css={styles.eventDetailsCss}>
              <Skeleton count={3} />
            </Div>
          </Div>
        </Div>
      </Section>
    </Div>
  );
};

export const EventDetails = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  withTheme,
)(EventDetailsWithoutThemeandState);
