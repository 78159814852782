import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

import merge from 'lodash/merge';

const containerCss = ({ props, state }) =>
  merge(
    {
      display: 'inline-block',
      padding: `${pixelsToRem(4)} ${pixelsToRem(6)} ${pixelsToRem(4)} ${pixelsToRem(12)}`,
      borderRadius: pixelsToRem(16),
      lineHeight: pixelsToRem(24),
      margin: `${pixelsToRem(8)} ${pixelsToRem(3)} ${pixelsToRem(4)}  ${pixelsToRem(3)} `,
      wordBreak: 'break-word',
      alignItems: 'center',
      width: 'fit-content',
      minHeight: pixelsToRem(32),
      boxSizing: 'border-box',
      verticalAlign: 'top',
      // fontSize: pixelsToRem(0),
    },

    !!props.theme &&
      !!props.theme.chip &&
      resolveToObject({ input: props.theme.chip.containerCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const iconCss = ({ props, state }) =>
  merge(
    {
      height: pixelsToRem(20),
      width: pixelsToRem(20),
    },

    !!props.theme &&
      !!props.theme.chip &&
      resolveToObject({ input: props.theme.chip.iconCss, params: { props, state } }),
    resolveToObject({ input: props.iconCss, params: { props, state } }),
  );

const spanCss = ({ props, state }) =>
  merge(
    !!props.theme &&
      !!props.theme.chip &&
      resolveToObject({ input: props.theme.chip.spanCss, params: { props, state } }),
    resolveToObject({ input: props.spanCss, params: { props, state } }),
  );

const buttonCss = ({ props, state }) =>
  merge(
    {
      display: 'inline-block',
      border: 0,
      height: pixelsToRem(20),
      width: pixelsToRem(20),
      borderRadius: '50%',
      padding: pixelsToRem(0),
      margin: `${pixelsToRem(-12)} ${pixelsToRem(9.2)} ${pixelsToRem(0)} ${pixelsToRem(0)}`,
      cursor: 'pointer',
      outline: 'none',
      lineHeight: pixelsToRem(20),
      top: pixelsToRem(5),
      verticalAlign: 'middle',
      position: 'relative',
      '&::after': {
        content: '',
      },
    },
    !!props.theme &&
      !!props.theme.chip &&
      resolveToObject({ input: props.theme.chip.buttonCss, params: { props, state } }),
    resolveToObject({ input: props.buttonCss, params: { props, state } }),
  );

const root = {
  buttonCss,
  containerCss,
  iconCss,
  spanCss,
};

export default root;
