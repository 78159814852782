export const getCookie = ({ name }) => {
  const cookie = {};
  document.cookie.split(';').forEach((el) => {
    const [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });

  return cookie[name];
};

export const setCookie = ({ name, value, expiry = null, domain = '.moshtix.com' }) => {
  document.cookie = `${name}=${value};Domain=${domain}${expiry ? `;expires = ${expiry}` : ''}; secure;`;
};

export const deleteCookie = ({ name, domain = '.moshtix.com' }) => {
  document.cookie = `${name}=null;Domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT; secure;`;
};
