import React, { useRef, useState, useEffect } from 'react';
import { Div, Input, Textarea, Span } from 'MoshtixShared/component-element';
import { withTheme } from 'theming';
import { useDebounceCallback } from '@react-hook/debounce';
import noop from 'lodash/noop';
import Icon from 'MoshtixShared/component-icon';
import Label from 'MoshtixShared/component-label';
import { Button } from 'MoshtixShared/component-button';
import { Theme } from 'Types/theme';

import { styles } from './styles';

export interface TextBoxProps {
  classNameInput?: string;
  className?: string;
  clearButton?: boolean;
  description?: string;
  disabled?: boolean;
  errorState?: boolean;
  height?: number;
  id?: string;
  idInput?: string;
  innerRef?: (ref: any) => void;
  label?: string;
  minimal?: boolean;
  multiline?: boolean;
  name?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string }) => void;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement> | null, args: {}) => void;
  onKeyPress?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  readOnly?: boolean;
  debounce?: boolean;
  debounceTime?: number;
  type?: string;
  value?: string;
  width?: string;
  autoComplete?: 'on' | 'off';
  css?: () => void;
  labelCss?: () => void;
  inputCss?: () => void;
  placeholderCss?: () => void;
  iconLeft?: string;
  minLength?: number;
  maxLength?: number;
  autoFocus: boolean;
  theme: Theme;
  required: boolean;
}

const TextBoxtWithoutTheme: React.FunctionComponent<TextBoxProps> = (props: TextBoxProps) => {
  const {
    classNameInput = '',
    className = '',
    clearButton = false,
    description = null,
    disabled = false,
    debounceTime = 1000,
    errorState = false,
    height = 100,
    id = '',
    idInput = '',
    innerRef = noop,
    label = null,
    minimal = false,
    multiline = false,
    name = '',
    onBlur = noop,
    onChange = noop,
    onClick = noop,
    onFocus = noop,
    onKeyPress = noop,
    onKeyDown = noop,
    onPaste = noop,
    placeholderText = '',
    readOnly = false,
    debounce = false,
    type = 'text',
    value: initialValue = '',
    width = '',
    autoComplete = 'on',
    autoFocus = false,
    css = noop,
    labelCss = noop,
    inputCss = noop,
    placeholderCss = noop,
    iconLeft = null,
    minLength = null,
    maxLength = null,
    theme,
    required = false,
  } = props;
  const containerRef = useRef(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue);

  useEffect(
    () => {
      setValue(initialValue);
    },
    [initialValue],
  );

  const onChangeDebounced = useDebounceCallback(onChange, debounceTime);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onBlur(event);
    setIsFocused(false);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFocus(event);
    setIsFocused(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* istanbul ignore else */
    if (event && event.target) {
      const newValue = event.target.value;
      if (!maxLength || newValue.length <= maxLength) {
        setValue(newValue);
        if (!debounce) {
          onChange(event, { newValue });
        } else {
          onChangeDebounced(event, { newValue });
        }
      }
    }
  };

  const buildStateAndPropsForCss = () => ({
    props: {
      ...props,
    },
    state: {
      focussed: isFocused,
      value,
    },
  });

  const renderPlaceholder = () => {
    if (!disabled && Boolean(placeholderText)) {
      return <Span css={styles.placeholderCss(buildStateAndPropsForCss())}>{placeholderText}</Span>;
    }
    return null;
  };

  const renderIcon = () => {
    if (iconLeft) {
      return <Icon type={iconLeft} css={styles.iconCss} />;
    }
    return null;
  };

  useEffect(
    () => {
      setValue(initialValue);
      if (autoFocus) {
        handleFocus();
      }
    },
    [initialValue],
  );
  const renderClear = () => {
    if (clearButton && value.length > 0) {
      return (
        <Button
          type="iconOnly"
          iconLeft="cross"
          css={styles.clearButtonCss}
          iconColor={theme.palette.black}
          onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event, { newValue: '' });
          }}
        />
      );
    }
    return null;
  };

  const renderInput = () => {
    const classNameInputAppended = `${classNameInput} data-hj-whitelist`;

    if (multiline) {
      return (
        <Textarea
          className={classNameInputAppended}
          css={styles.inputCss(buildStateAndPropsForCss())}
          disabled={disabled}
          id={idInput}
          innerRef={(input: React.RefObject<HTMLElement | SVGElement | React.Component>) => {
            innerRef(input);
          }}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          value={value}
          readOnly={readOnly}
          autoComplete={autoComplete}
        />
      );
    }
    return (
      <Input
        className={classNameInputAppended}
        css={styles.inputCss(buildStateAndPropsForCss())}
        disabled={disabled}
        id={idInput}
        innerRef={(input: React.RefObject<HTMLElement | SVGElement | React.Component>) => {
          innerRef(input);
        }}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={onClick}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        readOnly={readOnly}
        type={type}
        value={value}
        autoComplete={autoComplete}
      />
    );
  };

  return (
    <Div id={id} className={className} css={styles.containerCss(buildStateAndPropsForCss())} ref={containerRef}>
      <Div css={styles.containerInnerCss(buildStateAndPropsForCss())}>
        {label && (
          <Label css={labelCss} required={required}>
            {label}
          </Label>
        )}
        {description && <Span css={styles.descriptionCss(buildStateAndPropsForCss())}>{description}</Span>}
        {renderIcon()}
        <Div css={styles.relativeContainerCss}>
          {renderPlaceholder()}
          {renderInput()}
          {renderClear()}
        </Div>
        {minimal && !disabled && <Div css={styles.minimalBorderCss(buildStateAndPropsForCss())} />}
      </Div>
    </Div>
  );
};

export const TextBox = withTheme(TextBoxtWithoutTheme);

export const TextBoxRaw = TextBoxtWithoutTheme;
export const textBoxStyles = styles;

export default TextBox;
