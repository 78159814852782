import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { textBoxStyles } from 'MoshtixShared/component-text-box';

const datePlaceholderCss = ({ props, state }) =>
  merge(
    textBoxStyles.placeholderCss({ props, state }),
    !!state.typingValue && { opacity: 0, transform: `translate(${pixelsToRem(30)}, 0)` },
  );

const datePickerCss = ({ props, state }) =>
  merge(
    {
      backgroundColor: '#ffffff',
      boxShadow: `0 ${pixelsToRem(3)} ${pixelsToRem(4)} ${pixelsToRem(2)} #eeeeee`,
      cursor: 'default',
      left: 0,
      opacity: 0,
      outline: 0,
      position: 'absolute',
      transition: 'opacity 0.3s',
      zIndex: -1,
      ' .DayPicker-wrapper': {
        outline: 0,
      },
      ' .DayPicker-Day--today': {
        fontWeight: 300,
      },
      ' .DayPicker-Day--selection': {
        backgroundColor: '#007bbd',
        color: '#ffffff',
      },
      ' .DayPicker-Day': {
        cursor: 'default', // need to prevent cursor
      },
    },
    /* eslint-disable indent,import/no-named-as-default-member */
    props.position === 'top'
      ? {
          bottom: '100%',
        }
      : {
          top: '100%',
        },
    /* eslint-enable indent,import/no-named-as-default-member */
    state.showPicker && {
      cursor: 'pointer',
      opacity: 1,
      zIndex: 5,
    },
    !!props.theme &&
      !!props.theme.textBox &&
      resolveToObject({ input: props.theme.textBox.datePickerCss, params: { props, state } }),
    resolveToObject({ input: props.datePickerCss, params: { props, state } }),
  );

export const dateBoxStyles = {
  ...textBoxStyles,
  datePlaceholderCss,
  datePickerCss,
};
