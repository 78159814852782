import React, { useEffect } from 'react';
import { withTheme } from 'theming';
import { Div, Button } from 'MoshtixShared/component-element';
import { Overlay } from 'MoshtixShared/component-overlay';
import { Icon } from 'MoshtixShared/component-icon';

import { DialogComponentSharedProps } from './dialog-with-body';
import { styles } from './styles';

interface NakedDialogProps extends DialogComponentSharedProps {
  onCloseClick: () => void;
}

export const NakedDialogWithoutTheme: React.FunctionComponent<NakedDialogProps> = (props: NakedDialogProps) => {
  const { theme, mobileBreakpoint } = props;
  useEffect(() => {
    // componentDidMount()
    document.body.classList.add('showing-dialog');
    return (): void => {
      // componentWillUnmount()
      document.body.classList.remove('showing-dialog');
    };
  }, []);
  return (
    <Div className="moshtix-dialog-scroll-container" css={styles.containerCss({ props })}>
      <Overlay onClick={(): void => props.onCloseClick()} visible css={styles.overlayCss({ props })} />
      <Button css={styles.nakedDialogBackButton({ mobileBreakpoint })} onClick={props.onCloseClick}>
        Close
        <Icon type="close" css={styles.closeIcon({ theme })} />
      </Button>
      <Div css={styles.nakedDialogContainer({ props })}>{props.children}</Div>
    </Div>
  );
};

export const NakedDialog = withTheme(NakedDialogWithoutTheme);
