import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

import { DropdownButtonOptionProps } from './index';

interface OptionCssProps extends DropdownButtonOptionProps {
  focusVisible: boolean;
}

const optionCss: {} = (props: OptionCssProps) =>
  merge(
    {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      fontWeight: 400,
      backgroundColor: props.theme.palette.white,
      border: 'none',
      fontSize: '100%',
      padding: pixelsToRem(12, 16),
      width: pixelsToRem(210),
      maxWidth: '100%',
      textAlign: 'left',
      boxSizing: 'border-box',
    },
    props.disabled && {
      cursor: 'auto',
    },
    /* istanbul ignore next */
    props.focusVisible
      ? {
          outline: '2px solid black',
        }
      : {
          outline: 'none',
        },
    props.type === 'link' && {
      fontSize: pixelsToRem(12),
      textDecoration: 'underline',
    },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.optionCss, params: { props } }),
    resolveToObject({ input: props.css, params: { props } }), // css passed to option
  );

const optionContentCss: {} = ({ props }: { props: DropdownButtonOptionProps }) =>
  merge(
    {
      flex: 1,
    },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.optionContentCss, params: { props } }),
    resolveToObject({ input: props.optionContentCss, params: { props } }),
  );

const optionIconCss: {} = ({ props }: { props: DropdownButtonOptionProps }) =>
  merge(
    {
      marginRight: pixelsToRem(16),
    },
    props.type === 'link' && {
      marginRight: pixelsToRem(8),
      width: pixelsToRem(12),
    },
    props.iconColor && {
      color: props.iconColor,
    },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.optionIconCss, params: { props } }),
    resolveToObject({ input: props.optionIconCss, params: { props } }),
  );

const optionSubTextCss: {} = ({ props }: { props: DropdownButtonOptionProps }) =>
  merge(
    {
      fontSize: pixelsToRem(12),
    },
    !!props.theme &&
      !!props.theme.dropDownButton &&
      resolveToObject({ input: props.theme.dropDownButton.optionSubTextCss, params: { props } }),
    resolveToObject({ input: props.optionSubTextCss, params: { props } }),
  );

export const styles: {} = {
  optionContentCss,
  optionCss,
  optionIconCss,
  optionSubTextCss,
};
