import { DropdownButtonWrapper } from './drop-down-button-wrapper';
import { DropdownButtonOption } from './drop-down-button-option';
import { DropdownMenuContent } from './drop-down-menu-content';

export const DropDownButtonWrapper: React.FunctionComponent = DropdownButtonWrapper;
export const DropDownButtonOption: React.FunctionComponent = DropdownButtonOption;
export const DropDownMenuContent: React.FunctionComponent = DropdownMenuContent;

const root: {} = {
  DropDownButtonWrapper,
  DropDownButtonOption,
  DropDownMenuContent,
};

export default root;
