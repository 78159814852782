import React from 'react';
import appConfiguration from 'Configuration';
import { withTheme } from 'theming';

import Section from 'MoshtixShared/component-section';
import { Div, A, Li, Ul } from 'MoshtixShared/component-element';
import Icon from 'MoshtixShared/component-icon';
import styles from './styles';

const FooterLink = (props) => <A css={styles.footerNavLinkCss} {...props} />;
const links = {
  supportUrl: `//${appConfiguration.supportUrl}`,
  privacyUrl: `//${appConfiguration.publicWebsiteOriginalUrl}/v2/faq/faq-privacy-policy`,
  termsUrl: `//${appConfiguration.publicWebsiteOriginalUrl}/v2/faq/faq-terms-and-conditions`,
  moshtixLogoUrl: `//${appConfiguration.staticAssetsUrl}/images/moshtix-logo-white-nowhitespace.svg`,
};

const Footer = (props) => (
  <footer id="footer">
    <Section css={styles.backgroundCss({ props })}>
      <Div css={styles.footerContentCss}>
        <Div css={styles.footerLogoCss}>
          <img alt="Moshtix" src={links.moshtixLogoUrl} />
        </Div>
        <Ul css={styles.footerNavCss}>
          <Li css={styles.footerNavTitleCss({ props })}>Site Information</Li>
          <Li css={styles.footerNavItemCss}>
            <FooterLink href={links.privacyUrl}>Privacy Policy</FooterLink>
          </Li>
          <Li css={styles.footerNavItemCss}>
            <FooterLink href={links.termsUrl}>Terms & Conditions</FooterLink>
          </Li>
        </Ul>
        <A css={styles.supportBoxCss({ props })} aria-label="Support Centre" href={links.supportUrl} target="_parent">
          <Div css={styles.supportCircleCss({ props })}>
            <Icon type="support" alt="" css={styles.supportIconCss({ props })} />
          </Div>
          <Div css={styles.supportTextCss({ props })}>
            Visit our Support Centre to contact us or get answers to frequently asked questions.
          </Div>
          <Div css={styles.chevronRightCss}>
            <Icon type="right-arrow-alternate" css={styles.chevronRightIconCss} />
          </Div>
        </A>
      </Div>
      <div style={styles.copyrightCss({ props })}>Copyright © 2020 Moshtix Pty Ltd - ABN 72 076 980 955.</div>
    </Section>
  </footer>
);

export default withTheme(Footer);
