import { getCookie } from 'MoshtixShared/helper-cookie';

const setCookie = ({ name, value, domain = '.moshtix.com' }) => {
  document.cookie = `${name}=${value}; domain=${domain}; path=/; secure;`;
};

const root = {
  setCCEOId: (cceoid, domain) => {
    setCookie({ name: 'cceoid', value: cceoid, domain });
  },
  getCCEOId: () => getCookie({ name: 'cceoid' }),
  getToken: () => getCookie({ name: 'token' }),
  setToken: (token) => setCookie({ name: 'token', value: token }),
  getClientToken: () => getCookie({ name: 'client-token' }),
  getClientId: () => getCookie({ name: 'client-id' }),
  getAppExperience: () => !!getCookie({ name: 'app-experience' }),
  setAppExperience: () => setCookie({ name: 'app-experience', value: true }),
  getTicketDistroToken: () => getCookie({ name: 'ticket-distro-token' }),
  setTicketDistroToken: ({ token, domain }) => setCookie({ name: 'ticket-distro-token', value: token, domain }),
  getAccessToken: () => getCookie({ name: 'access-token' }),
  setAccessToken: ({ token, domain }) => setCookie({ name: 'access-token', value: token, domain }),
  setClient: (id, token, callback) => {
    setCookie({ name: 'client-id', value: id });

    setCookie({ name: 'client-token', value: token });

    if (callback) { callback(); }
  },
};

export default root;
