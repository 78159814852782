import moment from 'moment';

// invalidate if fails future / past only props
const validateTypedDate = ({ typingValue /* , futureOnly, pastOnly, disabledDays */ }) => {
  let newMomentDate;
  // if someone's typing 12/03/... or 03-04-... make sure it's english formatting
  if (typingValue.match(/^\d\d[^\d]\d\d?[^\d]/)) {
    newMomentDate = moment(typingValue, 'DD/M/YYYY');
  } else if (typingValue.match(/^\d[^\d]\d\d?[^\d]/)) {
    newMomentDate = moment(typingValue, 'D/M/YYYY');
  } else if (typingValue.match(/\d\d-[a-zA-Z]{3}-\d\d\d\d/)) {
    newMomentDate = moment(typingValue, 'DD-MMM-YYYY');
  } else {
    newMomentDate = moment(typingValue);
  }

  if (!newMomentDate.isValid()) {
    return null;
  }

  // JM - we want to allow the user to type any date, then a validation error should show
  // if (
  //   (futureOnly && moment().isAfter(newMomentDate.clone().add(1, 'days'))) ||
  //   (pastOnly && moment().isBefore(newMomentDate))
  // ) {
  //   return null;
  // }
  // if (disabledDays && disabledDays.length) {
  //   let isValid = true;
  //   disabledDays.forEach((item) => {
  //     if (item.before && newMomentDate.isBefore(moment(item.before))) {
  //       isValid = false;
  //     }
  //     if (item.after && newMomentDate.isAfter(moment(item.after))) {
  //       isValid = false;
  //     }
  //   });
  //   if (!isValid) {
  //     return null;
  //   }
  // }

  return newMomentDate;
};

const convertStringsToDate = (disabledDays) =>
  // assume is array
  disabledDays.map((disabledDayObject) => {
    if (disabledDayObject.after || disabledDayObject.before) {
      return {
        after: disabledDayObject.after && new Date(disabledDayObject.after),
        before: disabledDayObject.before && new Date(disabledDayObject.before),
      };
    }
    return disabledDayObject;
  });

export const helpers = {
  validateTypedDate,
  convertStringsToDate,
};
