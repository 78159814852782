import React from 'react';
import PropTypes from 'prop-types';
import curry from 'lodash/curry';
import merge from 'lodash/merge';
import { get, set } from 'object-path';

import ShouldUpdateWrapper from './should-update';

class ContextProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    contextName: PropTypes.string.isRequired,
    stateContext: PropTypes.shape().isRequired,
    initialState: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);

    // set up new state
    this.state = this.props.initialState;
  }

  setStatePassThrough = (param, callback) => this.setState(param, callback);

  actions = {
    setState: this.setStatePassThrough,
  };

  render() {
    const StateContext = this.props.stateContext;
    const { contextName } = this.props;
    const value = {
      [contextName]: {
        state: this.state,
        actions: this.actions,
      },
    };
    return <StateContext.Provider value={value}>{this.props.children}</StateContext.Provider>;
  }
}

// to replace lodash's pick function as that was not behaving consistently
export const customPick = (object, paths) => {
  const result = {};
  // using for instead of foreach for performance reasons
  for (let x = 0; x < paths.length; x += 1) {
    const newValue = get(object, paths[x]);
    set(result, paths[x], newValue);
  }
  return result;
};

// This function takes a component...
export const withState = curry(({ contextName, Context, subscribeStatePaths }, Component) =>
  // ...and returns another component...
  (props) => (
    // ... and renders the wrapped component with the context state
    // Notice that we pass through any additional props as well
    <Context.Consumer>
      {(contextValues) => {
        if (subscribeStatePaths && contextValues[contextName]) {
          const componentValues = merge({}, contextValues);
          const subscribedState = customPick(componentValues[contextName].state, subscribeStatePaths); // DON'T use lodash's pick here
          componentValues[contextName].state = subscribedState;
          return (
            <ShouldUpdateWrapper
              subscribeStatePaths={subscribeStatePaths}
              contextValues={componentValues}
              contextName={contextName}
              otherProps={props}
            >
              <Component {...props} {...componentValues} />
            </ShouldUpdateWrapper>
          );
        }

        return <Component {...props} {...contextValues} />;
      }}
    </Context.Consumer>
  ),
);

export default ContextProvider;
