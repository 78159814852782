import React from 'react';
import { withTheme } from 'theming';
import debounce from 'lodash/debounce';
import { ThemeInterface } from 'Types/theme';
import { Div, Button, Ul, Li } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

import { styles } from './styles';

interface CarouselProps {
  theme: ThemeInterface;
  children: React.ReactElement[];
  selectedKey: number | string;
  carouselWidth?: number | string;
  carouselHeight?: number | string;
  carouselPadding?: number | string;
  onChange: ({ index, selectedChild }: { index: number; selectedChild: React.ReactElement }) => void;
  noScrolling?: boolean;
}

interface CarouselState {
  selected: number;
}

class CarouselWithoutTheme extends React.Component<CarouselProps, CarouselState> {
  state: CarouselState = {
    // find the index where the selectedKey is and set state to that. If not found then default to 0
    selected:
      this.props.children.findIndex((child: JSX.Element) => child.key === this.props.selectedKey) > 0
        ? this.props.children.findIndex((child: JSX.Element) => child.key === this.props.selectedKey)
        : 0,
  };

  componentDidMount(): void {
    // add listener in case window resizes and carousel size changes
    window.addEventListener('resize', this.debouncedWindowResize, false);
    const { selected } = this.state;
    this.handleSlideSelected({ index: selected });
  }

  componentWillUnmount(): void {
    // clean up listeners
    window.removeEventListener('resize', this.debouncedWindowResize, false);
  }

  scrollerRef: React.Ref<HTMLUListElement> = React.createRef<HTMLUListElement>();

  handleWindowResize: () => void = () => {
    // when window resize the carousel could be a different size we need to rescroll to new element position
    const { selected } = this.state;
    this.handleSlideSelected({ index: selected });
  };

  // debounce the window resize so we aren't firing this all the time
  debouncedWindowResize: () => void = debounce(this.handleWindowResize, 250);

  handleCarouselScroll: () => void = () => {
    if (this.scrollerRef.current && !this.props.noScrolling) {
      const { children = [], onChange } = this.props;

      const scrollerLeft = this.scrollerRef.current.scrollLeft;
      const scrollerWidth = this.scrollerRef.current.scrollWidth;
      const totalItems = children.length;
      // try and find the closes item we scrolled to
      const activeIndex = Math.round((scrollerLeft / scrollerWidth) * totalItems);
      const { selected: currentSelected } = this.state;

      if (currentSelected !== activeIndex) {
        // pass the index so that the dots reflect this change
        this.setState({ selected: activeIndex });
        onChange({ index: activeIndex, selectedChild: children[activeIndex] });
      }
    }
  };

  handleSlideSelected: ({ index }) => void = ({ index }: { index: number }) => {
    if (this.scrollerRef.current) {
      const {
        children = [],
        // eslint-disable-next-line
        onChange = ({ index, selectedChild }: { index: number; selectedChild: React.ReactElement }) => null,
      } = this.props;

      this.setState({
        selected: index,
      });

      const totalItems = children.length;
      const scrollContainer = this.scrollerRef.current;
      // calculate how far left we need to go to get to the element
      const scrollLeft = Math.floor(scrollContainer.scrollWidth * (index / totalItems));
      // scrollTo TODO: polyfill scrollTo if not supported?
      scrollContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
      const selectedChild = children[index];
      // callback for when slide changes
      onChange({ index, selectedChild });
    }
  };

  renderNavigation: () => React.JSXElement = () => {
    const { selected } = this.state;
    const { children, theme } = this.props;
    return (
      <Div css={styles.navButtonContainer}>
        {selected !== 0 && (
          <Button
            css={styles.navButtonPrev({ theme })}
            onClick={(): void => this.handleSlideSelected({ index: selected - 1 })}
          >
            Previous
            <Icon type="chevron-left" css={styles.navButtonIcons({ theme })} />
          </Button>
        )}
        {selected !== children.length - 1 && (
          <Button css={styles.navButtonNext} onClick={(): void => this.handleSlideSelected({ index: selected + 1 })}>
            Next
            <Icon type="chevron-right" css={styles.navButtonIcons({ theme })} />
          </Button>
        )}
      </Div>
    );
  };

  renderDots: () => React.JSXElement = () => {
    const { selected } = this.state;
    const { children, theme } = this.props;

    return children.map((_child: React.ReactElement, index: number) => (
      <Button
        aria-label={`Scroll to item ${index + 1}`}
        onClick={(): void => this.handleSlideSelected({ index })}
        css={styles.navigationDots({ selected: selected === index, theme })}
      >
        {index}
      </Button>
    ));
  };

  render(): React.JSXElement | React.JSXElement[] {
    const { selected } = this.state;
    const {
      children,
      theme,
      carouselHeight = 'calc(0.7 * 40vw)',
      carouselWidth = '40vw',
      carouselPadding = pixelsToRem(5),
    } = this.props;
    return children.length > 1 ? (
      <Div css={styles.viewportCss}>
        <Div>{this.renderNavigation()}</Div>
        <Div css={styles.carouselFrameCss({ theme, carouselHeight, carouselWidth, carouselPadding })}>
          <Div css={styles.carouselCss({ carouselHeight, carouselWidth })}>
            <Ul
              innerRef={this.scrollerRef}
              css={styles.scrollContainerCss}
              onScroll={debounce(this.handleCarouselScroll, 250)}
            >
              {React.Children.map(this.props.children, (child: React.ReactElement, index: number) => (
                <Li
                  css={styles.scrollItemOuterCss({
                    selected: selected === index,
                    next: selected < index,
                    prev: selected > index,
                    theme,
                  })}
                >
                  {React.cloneElement(child, { ...child.props })}
                </Li>
              ))}
            </Ul>
          </Div>
        </Div>
        <Div css={styles.navigationContainer({ theme })}>{this.renderDots()}</Div>
      </Div>
    ) : (
      children
    );
  }
}

export const Carousel = withTheme(CarouselWithoutTheme);
