import React from 'react';
import { withTheme } from 'theming';
import { Dialog } from 'MoshtixShared/component-dialog';
import { A, Div, P } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import configuration from 'Configuration/index';

import styles from './styles';

const ResellDialogWithoutTheme = ({ onClose, orders, theme }) => (
  <Dialog title="Select order to resell from" show onCloseClick={onClose} containerInnerCss={styles.containerInnerCss}>
    <Div css={styles.infoCss({ theme })}>You&#39;ll be able to select individual tickets in the next step</Div>
    {orders.map((order) => (
      <A
        css={styles.stripeCss({ theme })}
        href={`https://${configuration.publicWebsiteOriginalUrl}/v2/account/re-sell-my-ticket/${order.id}`}
      >
        <Div css={styles.numTicketsCss({ theme })}>{order.tickets.items.length}</Div>
        <Div css={styles.detailsCss}>
          <P css={styles.orderIdCss}>Order {order.id}</P>
          <P css={styles.dateCss}>Purchased {order.createdDate}</P>
          <P css={styles.ticketNamesCss({ theme })}>
            {order.tickets.items.map((ticket, index) => (
              <span>{index !== 0 ? `,${ticket.ticketType.name}` : ticket.ticketType.name}</span>
            ))}
          </P>
        </Div>
        <Div css={styles.alignRightCss}>
          <Icon type="chevron-right" />
        </Div>
      </A>
    ))}
  </Dialog>
);

export const ResellDialog = withTheme(ResellDialogWithoutTheme);
