import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withTheme } from 'theming';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import noop from 'lodash/noop';

import { Div, Input, Span } from 'MoshtixShared/component-element';
import { checkSmartDevice } from 'MoshtixShared/helper-device-info';
import { TextBoxProps } from 'MoshtixShared/component-text-box';
import { Icon } from 'MoshtixShared/component-icon';
import { keyCodes } from 'MoshtixShared/helper-key-code';
import Label from 'MoshtixShared/component-label';

import { dateBoxStyles } from './styles';
import { helpers } from './helpers';

const DISPLAY_FORMAT = 'DD-MMM-YYYY';
const STATE_FORMAT = 'YYYY-MM-DDT00:00:00[.000]';

interface DateBoxProps extends TextBoxProps {
  placeholderText: string;
  value: string;
  futureOnly: boolean;
  pastOnly: boolean;
  disabledDays: Date[];
}

const validValueChosen = ({
  value,
  setCurrentDate,
  setTypingValue,
  setDaypickerVisible,
  onChange,
}: {
  value: string;
  setCurrentDate: (currentDate: string) => void;
  setTypingValue: (typingValue: string) => void;
  setDaypickerVisible: (daypickerVisible: boolean) => void;
  onChange: (event: null, { newValue }: { newValue: string }) => void;
}) => {
  setCurrentDate(moment(value).format(STATE_FORMAT));
  setTypingValue(moment(value).format(DISPLAY_FORMAT));
  setDaypickerVisible(false);
  onChange(null, { newValue: value });
};

const handleMonthChange = () => null;

const validateValue = ({
  typingValue,
  setTypingValue,
  currentDate,
  setCurrentDate,
  setDaypickerVisible,
  onChange,
}: {
  typingValue: string;
  setTypingValue: (typingValue: string) => void;
  currentDate: string;
  setCurrentDate: (currentDate: string) => void;
  setDaypickerVisible: (daypickerVisible: boolean) => void;
  onChange: (event: Event | null, { newValue }: { newValue: string | null }) => void;
}) => {
  // cleared out the value
  if (typingValue === '') {
    setTypingValue('');
    setCurrentDate('');
    onChange(null, { newValue: null });
    return;
  }

  const validMomentDate = helpers.validateTypedDate({
    typingValue,
  });

  if (validMomentDate) {
    const value = validMomentDate.format(STATE_FORMAT);
    validValueChosen({
      value,
      setCurrentDate,
      setTypingValue,
      setDaypickerVisible,
      onChange,
    });
  } else {
    setTypingValue(currentDate ? moment(currentDate).format(DISPLAY_FORMAT) : '');
  }
};

const handleChangeDesktopInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  { setTypingValue }: { setTypingValue: (typingValue: string) => void },
) => {
  const { value } = event.target;
  setTypingValue(value);
};

const handleFocusDesktopInput = ({
  setFocussed,
  setDaypickerVisible,
  onFocus,
  currentDate,
}: {
  setFocussed: (focussed: boolean) => void;
  setDaypickerVisible: (daypickerVisible: boolean) => void;
  onFocus: (
    event: React.ChangeEvent<HTMLInputElement> | null,
    { newValue }: { newValue: typeof moment | string },
  ) => void;
  currentDate: typeof moment | string;
}) => {
  setFocussed(true);
  setDaypickerVisible(true);
  onFocus(null, {
    newValue: currentDate,
  });
};

const handleBlurDesktopInput = (
  event: React.ChangeEvent<HTMLInputElement>,
  {
    clickedOnPicker,
    setFocussed,
    setDaypickerVisible,
    onBlur,
    typingValue,
    setTypingValue,
    currentDate,
    setCurrentDate,
    onChange,
  }: {
    clickedOnPicker: boolean;
    setFocussed: (focussed: boolean) => void;
    setDaypickerVisible: (daypickerVisible: boolean) => void;
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
    typingValue: string;
    setTypingValue: (typingValue: string) => void;
    currentDate: string;
    setCurrentDate: (currentDate: string) => void;
    onChange: (event: Event | null, { newValue }: { newValue: string | null }) => void;
  },
) => {
  // if clicking on picker then prevent blurring
  // something weird's going on and not picking up next line in coverage
  /* istanbul ignore next */
  if (clickedOnPicker) {
    return null;
  }
  validateValue({
    typingValue,
    setTypingValue,
    currentDate,
    setCurrentDate,
    setDaypickerVisible,
    onChange,
  });
  setFocussed(false);
  setDaypickerVisible(false);
  onBlur(event);
  return null;
};

const handleKeyUp = async (
  event: React.KeyboardEvent<HTMLInputElement>,
  {
    typingValue,
    setTypingValue,
    currentDate,
    setCurrentDate,
    focussed,
    daypickerVisible,
    setClickedOnPicker,
    daypickerContainerRef,
    setDaypickerVisible,
    onChange,
  }: {
    typingValue: string;
    setTypingValue: (typingValue: string) => void;
    setCurrentDate: (currentDate: string) => void;
    currentDate: string;
    focussed: boolean;
    daypickerVisible: boolean;
    setClickedOnPicker: (clickedOnPicker: boolean) => void;
    daypickerContainerRef: React.RefObject<HTMLDivElement>;
    setDaypickerVisible: (daypickerVisible: boolean) => void;
    onChange: (event: Event | null, { newValue }: { newValue: string | null }) => void;
  },
) => {
  if (event.keyCode === keyCodes.escape) {
    setDaypickerVisible(false);
  }
  if (event.keyCode === keyCodes.enter) {
    validateValue({
      typingValue,
      setTypingValue,
      currentDate,
      setCurrentDate,
      setDaypickerVisible,
      onChange,
    });
  }
  if (focussed && daypickerVisible && event.keyCode === keyCodes['down arrow']) {
    const firstDayElement = daypickerContainerRef.current.querySelector(
      '.DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled)',
    );
    /* istanbul ignore else */
    if (firstDayElement) {
      // useState is async so we should wait for it to set it first before moving so the blur doesnt trigger incorrectly
      await setClickedOnPicker(true);
      firstDayElement.focus();
    }
    setClickedOnPicker(false);
  }
};

const handleMouseDatePicker = ({ setClickedOnPicker }: { setClickedOnPicker: (clickedOnPicker: boolean) => void }) => {
  setClickedOnPicker(true);
  setTimeout(() => {
    setClickedOnPicker(false);
  });
};

const handleDayClick = (
  date: string | Date,
  {
    isDateDisabled,
    setCurrentDate,
    setTypingValue,
    setDaypickerVisible,
    onChange,
  }: {
    isDateDisabled?: boolean;
    setCurrentDate: (currentDate: string) => void;
    setTypingValue: (typingValue: string) => void;
    setDaypickerVisible: (daypickerVisible: boolean) => void;
    onChange: (event: null, { newValue }: { newValue: string }) => void;
  },
) => {
  /* istanbul ignore next */
  if (isDateDisabled) {
    return;
  }

  const value = moment(date).format(STATE_FORMAT);
  validValueChosen({
    value,
    setCurrentDate,
    setTypingValue,
    setDaypickerVisible,
    onChange,
  });
};

const removeAllTabIndexesFromDatePicker = ({
  daypickerContainerRef,
}: {
  daypickerContainerRef: React.RefObject<HTMLDivElement>;
}) => {
  /* istanbul ignore else */
  if (daypickerContainerRef.current) {
    // rarely will be a case where there is not ref and there is not a querySelectorAll function
    /* istanbul ignore next */
    if (!daypickerContainerRef.current.querySelectorAll) {
      return;
    }
    const tabIndexItems = daypickerContainerRef.current.querySelectorAll('div[tabindex="0"],span[tabindex="0"]');
    for (let index = 0; index < tabIndexItems.length; index += 1) {
      tabIndexItems[index].removeAttribute('tabindex');
    }
  }
};

const DateBoxWithoutTheme: React.FunctionComponent<DateBoxProps> = (props: DateBoxProps) => {
  const {
    className = '',
    disabled = false,
    // errorState = false,
    height = 100,
    id = '',
    idInput = '',
    innerRef = noop,
    label = null,
    minimal = false,
    multiline = false,
    name = '',
    onBlur = noop,
    onChange = noop,
    onClick = noop,
    onFocus = noop,
    onKeyPress = noop,
    onKeyDown = noop,
    onPaste = noop,
    readOnly = false,
    debounce = false,
    type = 'text',
    value: initialValue = '',
    width = '',
    autoComplete = 'on',
    css = noop,
    inputCss = noop,
    placeholderCss = noop,
    iconLeft = null,
    placeholderText = 'dd-mm-yyyy',
    futureOnly = false,
    pastOnly = false,
    disabledDays = [],
    classNameInput = '',
  } = props;

  const formattedValue = initialValue ? moment(initialValue).format(STATE_FORMAT) : '';
  const formattedTypingValue = initialValue ? moment(initialValue).format(DISPLAY_FORMAT) : '';
  const [currentDate, setCurrentDate] = useState(formattedValue);
  const [typingValue, setTypingValue] = useState(formattedTypingValue);
  const [focussed, setFocussed] = useState(false);
  const [daypickerVisible, setDaypickerVisible] = useState(false);
  const [clickedOnPicker, setClickedOnPicker] = useState(false);

  const inputRef = useRef(null);
  const daypickerRef = useRef(null);
  const daypickerContainerRef = useRef(null);
  const containerRef = useRef(null);

  const disabledDaysAsDates = helpers.convertStringsToDate([
    ...disabledDays,
    {
      before: futureOnly ? new Date() : undefined,
      after: pastOnly ? new Date() : undefined,
    },
  ]);

  useEffect(() => {
    const isSmartDevice = checkSmartDevice();
    if (isSmartDevice) {
      removeAllTabIndexesFromDatePicker({ daypickerContainerRef });
    }
  });

  useEffect(() => {
    const formattedValueFromInitialValue = initialValue ? moment(initialValue).format(STATE_FORMAT) : '';
    const formattedTypingValueFromInitialValue = initialValue ? moment(initialValue).format(DISPLAY_FORMAT) : '';
    setCurrentDate(formattedValueFromInitialValue);
    setTypingValue(formattedTypingValueFromInitialValue);
  }, [initialValue]);

  const buildStateAndPropsForCss = () => ({
    props: {
      ...props,
    },
    state: {
      focussed,
      showPicker: daypickerVisible,
      value: initialValue,
    },
  });

  const input = (
    <Fragment>
      <Input
        id={idInput}
        className={`${classNameInput} data-hj-whitelist`}
        name={name}
        key={`${id}-date-box`}
        type="text"
        value={typingValue}
        css={dateBoxStyles.inputCss(buildStateAndPropsForCss())}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDesktopInput(event, { setTypingValue })}
        onFocus={() => handleFocusDesktopInput({ setFocussed, setDaypickerVisible, onFocus, currentDate })}
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleBlurDesktopInput(event, {
            clickedOnPicker,
            setFocussed,
            setDaypickerVisible,
            onBlur,
            typingValue,
            setTypingValue,
            currentDate,
            setCurrentDate,
            onChange,
          })
        }
        onClick={() => handleFocusDesktopInput({ setFocussed, setDaypickerVisible, onFocus, currentDate })}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) =>
          handleKeyUp(event, {
            focussed,
            daypickerVisible,
            setClickedOnPicker,
            daypickerContainerRef,
            setDaypickerVisible,
            typingValue,
            setTypingValue,
            setCurrentDate,
            currentDate,
            onChange,
          })
        }
        disabled={props.disabled}
        innerRef={inputRef}
        autoComplete="off"
      />
      <Div
        css={dateBoxStyles.datePickerCss(buildStateAndPropsForCss())}
        innerRef={daypickerContainerRef}
        key={`${id}-date-box-picker`}
        onMouseDown={() => handleMouseDatePicker({ setClickedOnPicker })}
        onMouseUp={() => handleMouseDatePicker({ setClickedOnPicker })}
      >
        <DayPicker
          enableOutsideDaysClick
          onDayClick={(date: Date, { disabled: isDateDisabled }: { disabled: boolean }) =>
            handleDayClick(date, { isDateDisabled, setCurrentDate, setTypingValue, setDaypickerVisible, onChange })
          }
          month={formattedValue !== '' ? new Date(formattedValue) : new Date()}
          onMonthChange={handleMonthChange}
          modifiers={{
            selection: new Date(currentDate),
          }}
          disabledDays={disabledDaysAsDates}
          ref={daypickerRef}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleBlurDesktopInput(event, {
              clickedOnPicker,
              setFocussed,
              setDaypickerVisible,
              onBlur,
              typingValue,
              setTypingValue,
              currentDate,
              setCurrentDate,
              onChange,
            })
          }
        />
      </Div>
    </Fragment>
  );

  const placeholder =
    !disabled && Boolean(placeholderText) ? (
      <Span css={dateBoxStyles.datePlaceholderCss(buildStateAndPropsForCss())}>{placeholderText}</Span>
    ) : null;

  const icon = iconLeft ? <Icon type={iconLeft} css={dateBoxStyles.iconCss} /> : null;

  // something weird's going on with minimal && !disabled section not picking up the ternary true case
  /* istanbul ignore next */
  return (
    <Div id={id} className={className} css={dateBoxStyles.containerCss(buildStateAndPropsForCss())} ref={containerRef}>
      <Div css={dateBoxStyles.containerInnerCss(buildStateAndPropsForCss())}>
        {label && <Label>{label}</Label>}
        <Div css={dateBoxStyles.relativeContainerCss}>
          {icon}
          {placeholder}
          {input}
        </Div>
        {minimal && !disabled ? <Div css={dateBoxStyles.minimalBorderCss(buildStateAndPropsForCss())} /> : null}
      </Div>
    </Div>
  );
};

export const DateBox = withTheme(DateBoxWithoutTheme);
