import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import isEqual from 'lodash/isEqual';

// this component is to address the issue of the context api rerendering all the consumers, when the context state changes

const WrapperComponent = class extends React.Component {
  static propTypes = {
    subscribeStatePaths: PropTypes.arrayOf(PropTypes.string).isRequired,
    contextValues: PropTypes.shape().isRequired,
    contextName: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    otherProps: PropTypes.shape().isRequired,
  };

  // check if any of the subscribed state paths data has changed, if it has then we want to rerender, if not, then don't rerender
  shouldComponentUpdate(newProps) {
    const paths = this.props.subscribeStatePaths;
    const { contextName } = this.props;
    // paths must be provided, but in case it isn't just rerender
    if (!paths) {
      return true;
    }
    let subscribedFieldChanged = false;
    for (let i = 0; i < paths.length; i += 1) {
      const statePath = paths[i];
      // need to stringify, in case someone has specified a string ..or... array or object
      const oldValue = JSON.stringify(objectPath.get(this.props.contextValues[contextName].state, statePath));
      const newValue = JSON.stringify(objectPath.get(newProps.contextValues[contextName].state, statePath));
      const changed = !isEqual(oldValue, newValue);
      if (changed) {
        subscribedFieldChanged = true;
        break;
      }
    }
    // if this is true then just return, no need to carry on
    // console.log('subscribedFieldChanged', subscribedFieldChanged);
    if (subscribedFieldChanged) {
      return true;
    }

    // check if any other props have changed
    const restOfOldProps = this.props.otherProps;
    const restOfNewProps = newProps.otherProps;
    const otherPropsHaveChanged = !isEqual(restOfOldProps, restOfNewProps);

    return otherPropsHaveChanged;
  }

  contextName = Object.keys(this.props.contextValues)[0];

  render() {
    return this.props.children;
  }
};

export default WrapperComponent;
