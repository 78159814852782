import React, { Fragment } from 'react';
import { withTheme } from 'theming';
import { Div, P, Span } from 'MoshtixShared/component-element';
import { Button } from 'MoshtixShared/component-button';
import { Icon } from 'MoshtixShared/component-icon';
import { ThemeInterface } from 'Types/theme';

import { EventInterface, TicketInterface } from '../../../../types/app';
import { TicketActions } from '../ticket-actions';
import styles from './styles';

interface TicketDetailsProps {
  ticket: TicketInterface;
  event: EventInterface;
  theme: ThemeInterface;
  toggleTicketDetails: () => void;
  onCloseClick: () => void;
}

const TicketDetailsWithoutTheme: React.SFC<TicketDetailsProps> = ({
  event,
  ticket,
  order,
  theme,
  toggleTicketDetails,
  onCloseClick,
}: TicketDetailsProps) => (
  <Div css={styles.ticketDetailsContainer({ theme })}>
    <Div css={styles.ticketDetailsMobileControlContainer}>
      <Button css={styles.ticketDetailsBackToTicket} onClick={toggleTicketDetails}>
        Back to Ticket
        <Icon type="back" color={theme.palette.black} />
      </Button>
      <Button css={styles.ticketDetailsBackToEvent} onClick={onCloseClick}>
        Back to Event
        <Icon type="close" color={theme.palette.black} />
      </Button>
    </Div>
    <Div css={styles.ticketActionsDetails}>
      <TicketActions event={event} ticket={ticket} order={order} />
    </Div>
    <Div css={styles.ticketDetailsBackground({ theme })}>
      <P css={styles.ticketDetailsLabelCss}>Event</P>
      <P css={styles.ticketDetailsCss}>{event.name}</P>
      <P css={styles.ticketDetailsLabelCss}>Ticket type</P>
      <P css={styles.ticketDetailsCss}>{ticket.collectorTicket ? ticket.ticketTypeName : ticket.ticketType.name}</P>
      {!ticket.ticketType.suppressDistribution && (
        <>
          <P css={styles.ticketDetailsLabelCss}>Ticket no.</P>
          <P css={styles.ticketDetailsCss}>{ticket.barcode}</P>
        </>
      )}
      <P css={styles.ticketDetailsLabelCss}>Customer no.</P>
      <P css={styles.ticketDetailsCss}>{order.customer.id}</P>
      <P css={styles.ticketDetailsLabelCss}>Purchase Date</P>
      <P css={styles.ticketDetailsCss}>{order.createdDate}</P>
      <P css={styles.ticketDetailsLabelCss}>Price</P>
      <P css={styles.ticketDetailsCss}>
        {ticket.discount ? (
          <Fragment>
            <Span css={styles.strikethrough}>{`$${ticket.totalCost}`}</Span>
            <Span>{`$${(ticket.totalCost - ticket.discount).toFixed(2)}`}</Span>
          </Fragment>
        ) : (
          `$${ticket.totalCost}`
        )}
      </P>
    </Div>
  </Div>
);

export const TicketDetails = withTheme(TicketDetailsWithoutTheme);
