import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';
import { hexToRgbA } from 'MoshtixShared/helper-html-colour-encoder';

const mediumQuery = configuration.getMediaQuery('medium');

const footerContentCss = {
  display: 'flex',
  flexWrap: 'wrap',
  [mediumQuery]: {
    flexWrap: 'nowrap',
  },
};

const footerLogoCss = {
  display: 'flex',
  height: pixelsToRem(29),
  justifyContent: 'center',
  width: '100%',
  marginBottom: pixelsToRem(27),
  [mediumQuery]: {
    width: pixelsToRem(72),
    marginRight: pixelsToRem(70),
  },
};

const footerNavCss = {
  display: 'inline',
  flexGrow: '1',
  minWidth: pixelsToRem(200),
  width: '100%',
  margin: 0,
  padding: 0,
  marginBottom: pixelsToRem(27),
  [mediumQuery]: {
    width: pixelsToRem(100),
  },
};

const footerNavItemCss = {
  listStyleType: 'none',
  padding: 0,
  lineHeight: pixelsToRem(32),
};

const footerNavTitleCss = ({ props }) => ({
  ...footerNavItemCss,
  fontWeight: 600,
  color: props.theme.palette.white,
});

const footerNavLinkCss = {
  listStyleType: 'none',
  padding: 0,
  textDecoration: 'none',
  lineHeight: pixelsToRem(32),
  color: 'inherit',
};

const supportBoxCss = ({ props }) => ({
  display: 'flex',
  height: 'max-content',
  minHeight: pixelsToRem(90),
  backgroundColor: hexToRgbA({ hex: props.theme.palette.white, alpha: 0.15 }),
  borderRadius: pixelsToRem(4),
  width: '100%',
  color: props.theme.palette.white,
  fontSize: pixelsToRem(16),
  textDecoration: 'none',
  transition: 'background-color 250ms',
  [mediumQuery]: {
    maxWidth: pixelsToRem(468),
  },
  ':hover': {
    backgroundColor: hexToRgbA({ hex: props.theme.palette.white, alpha: 0.25 }),
    opacity: 1,
  },
  ':active': {
    backgroundColor: hexToRgbA({ hex: props.theme.palette.white, alpha: 0.25 }),
    opacity: 1,
  },
});

const supportIconCss = ({ props }) => ({
  color: props.theme.palette.white,
  width: pixelsToRem(20),
  [mediumQuery]: {
    width: pixelsToRem(28),
  },
});

const supportCircleCss = ({ props }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: pixelsToRem(38),
  minHeight: pixelsToRem(38),
  maxWidth: pixelsToRem(38),
  maxHeight: pixelsToRem(38),
  backgroundColor: props.theme.palette.highlight,
  borderRadius: '50%',
  textAlign: 'center',
  // margin: pixelsToRem(18),
  margin: pixelsToRem(21),
  marginLeft: pixelsToRem(22),
  [mediumQuery]: {
    minWidth: pixelsToRem(49),
    minHeight: pixelsToRem(49),
    maxWidth: pixelsToRem(49),
    maxHeight: pixelsToRem(49),
  },
});

const supportTextCss = ({ props }) => ({
  color: props.theme.palette.white,
  margin: '20px 0 20px 0',
  fontSize: pixelsToRem(16),
});

const chevronRightCss = {
  display: 'flex',
  maxHeight: pixelsToRem(90),
  alignItems: 'center',
  marginLeft: pixelsToRem(12),
  marginRight: pixelsToRem(20),
};

const chevronRightIconCss = ({ props }) => ({
  color: props.theme.palette.darkShadow,
});

const copyrightCss = () => ({
  textAlign: 'center',
  margin: pixelsToRem(27),
  fontSize: pixelsToRem(14),
});

const backgroundCss = ({ props }) => ({
  background: props.theme.palette.black,
  color: props.theme.palette.shadow,
});

export default {
  supportCircleCss,
  supportIconCss,
  footerLogoCss,
  footerNavCss,
  footerNavItemCss,
  footerNavTitleCss,
  footerNavLinkCss,
  footerContentCss,
  supportBoxCss,
  supportTextCss,
  chevronRightCss,
  chevronRightIconCss,
  copyrightCss,
  backgroundCss,
};
