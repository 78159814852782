const environmentVariables = {
  supportUrl: 'tixsupport.moshtix.com.au/hc/en-us',
  staticAssetsUrl: 'static-assets.moshtix.com.au',
  cookieDomain: '.moshtix.com.au',
};

export const getEnvironmentVariables = ({ environment, domain }) => ({
  ...environmentVariables,
  graphqlUrl: `https://api-${environment}.moshtix.com/v1/graphql`,
  adminOriginalUrl: `${environment}.moshtix.com`,
  controlRoomUrl: `control-room-${environment}.moshtix.com`,
  publicWebsiteOriginalUrl: `${environment}.${domain}`,
  environment,
  myUrl: `my-${environment}.${domain}`,
});

export default environmentVariables;
