import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const containerCss = ({ props }) => {
  let returnStyles = merge(
    {
      width: props.width || pixelsToRem(24),
      height: props.height || pixelsToRem(24),
    },
    !!props.theme && !!props.theme.icon && resolveToObject({ input: props.theme.icon.containerCss, params: { props } }),
    props.color && { '> path,> polygon,> rect': { fill: props.color } },
    resolveToObject({ input: props.css, params: { props } }),
  );

  if (!props.color) {
    returnStyles = merge(returnStyles, { '> path,> polygon,> rect': { fill: returnStyles.color } });
  }
  return returnStyles;
};

const root = {
  containerCss,
};

export default root;
