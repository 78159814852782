/* eslint-disable react/no-find-dom-node */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const ClickOutsideWrapper = class extends React.Component {
  constructor(props) {
    super(props);

    this.checkClickOutsideWrapper = this.checkClickOutsideWrapper.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.checkClickOutsideWrapper);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.checkClickOutsideWrapper);
  }

  checkClickOutsideWrapper(event) {
    if (this.wrapperReference && !this.wrapperReference.contains(event.target)) {
      this.props.onClickOutside(event);
    }
  }

  render() {
    return (
      <div
        ref={(wrapper) => {
          if (wrapper) {
            this.wrapperReference = ReactDOM.findDOMNode(wrapper);
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
};

ClickOutsideWrapper.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.node,
};

ClickOutsideWrapper.defaultProps = {
  onClickOutside: () => {},
  children: undefined,
};

export default ClickOutsideWrapper;
