/** @jsx jsx */
import { jsx } from '@emotion/core';
/* eslint-disable react/prefer-stateless-function */
// disabled prefer-stateless-function rule as it was breaking this component
import React from 'react';
import { ThemeProvider } from 'theming';
import PropTypes from 'prop-types';

import styles from './styles';

export default class ThemeConfiguration extends React.Component {
  render() {
    const themeStyles = styles({
      palette: this.props.palette,
    });

    return (
      <div css={themeStyles.containerCss()}>
        <ThemeProvider theme={themeStyles} {...this.props} />
      </div>
    );
  }
}
ThemeConfiguration.propTypes = {
  palette: PropTypes.shape().isRequired,
};
