import React from 'react';
import QRCodeComponent from 'qrcode.react';
// eslint-disable-next-line
import generateBarcode from 'pdf417/build';

import { Img } from 'MoshtixShared/component-element';

// this pdf417 library is bad and doesnt have an index
interface QRCodeProps {
  value: string | number;
  renderAs?: 'svg' | 'canvas';
  size?: number;
  level?: 'L' | 'M' | 'Q' | 'H';
  includeMargin?: boolean;
  bgColor?: string;
  fgColor?: string;
  id?: string;
}

interface BarcodeProps {
  value: string | number;
  width?: number;
  height?: number;
}

export const QRCode: React.SFC<QRCodeProps> = ({
  value,
  renderAs = 'canvas',
  size = 128,
  bgColor = '#fff',
  fgColor = '#000',
  level = 'L',
  includeMargin = false,
  id,
}: QRCodeProps) => (
  <QRCodeComponent
    value={value}
    renderAs={renderAs}
    size={size}
    bgColor={bgColor}
    fgColor={fgColor}
    level={level}
    includeMargin={includeMargin}
    id={id}
  />
);

export const BarcodeComponent: React.SFC<BarcodeProps> = ({ value, width = 3, height = 2 }: BarcodeProps) => (
  <Img src={generateBarcode(value, width, height)} alt={value} css={{ width: 'inherit', height: 'inherit' }} />
);
