import React from 'react';
import PropTypes from 'prop-types';
import { Label as EmotionLabel, Span } from 'MoshtixShared/component-element';
import { withTheme } from 'theming';

import { styles } from './styles';

const LabelComponent = (props) => {
  let style = styles.labelCss({ props });
  if (props.type === 'subLabel') {
    style = styles.subLabelCss({ props });
  }
  if (props.type === 'subText') {
    style = styles.subTextCss({ props });
  }
  if (props.type === 'footNote') {
    style = styles.footNoteCss({ props });
  }
  if (props.type === 'footNoteLight') {
    style = styles.footNoteLightCss({ props });
  }

  return (
    <EmotionLabel css={style} htmlFor={props.htmlFor}>
      {props.children} {props.required && [' ', <Span css={styles.requiredAsteriskCss}>*</Span>]}
    </EmotionLabel>
  );
};

LabelComponent.propTypes = {
  htmlFor: PropTypes.string,
  type: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  errorState: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  required: PropTypes.bool,
  children: PropTypes.node,
};

LabelComponent.defaultProps = {
  htmlFor: '',
  type: 'label',
  css: {},
  errorState: false,
  required: false,
  children: undefined,
};

const ThemedLabel = withTheme(LabelComponent);
export const Label = (props) => <ThemedLabel {...props} />;
Label.propTypes = LabelComponent.propTypes;
Label.defaultProps = LabelComponent.defaultProps;

export default Label;
