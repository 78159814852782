import React, { Fragment } from 'react';
import { get } from 'object-path';
import { graphql, compose } from 'react-apollo';
import tokenStore from 'MoshtixShared/helper-token-store';
import { withState } from 'MoshtixShared/component-context-provider';
import LoadingOverlay from 'ControlRoomComponents/loading-overlay';
import { Header } from 'Components/component-header';
import Footer from 'Components/component-footer';

import { getOrdersQuery } from '../component-access-token-login/data-definition';

import { history } from '../../app/history';
import { TicketDistroContext } from '../../app/contexts';
import { EventPage } from '../../pages/event';

const getOrdersQueryData = graphql(getOrdersQuery, {
  name: 'orderData',
  options: (props) => ({
    variables: {
      token: tokenStore.getTicketDistroToken(),
    },
  }),
  props: ({ ownProps, orderData: { loading, viewer, refetch, networkStatus } }) => ({
    ...ownProps,
    orderData: {
      // if loading or networkStatus is refetching
      // pending fix with apollo network status though https://github.com/apollographql/apollo-client/issues/1257
      loading: loading || networkStatus === 4,
      viewer,
      refetchEvent: refetch,
    },
  }),
});

interface RedirecterProps {
  ticketDistroContext: {
    state: {
      event: object;
    };
    actions: {
      setState: (param: any) => void;
    };
  };
  orderData: any;
  ticketDialogVisible: boolean;
  onDialogClose?: () => void;
}

class RedirecterWithoutState extends React.Component<RedirecterProps, { isLoading: boolean }> {
  shouldComponentUpdate(nextProps: RedirecterProps) {
    // check if the get order query has returned something
    if (
      nextProps.orderData !== this.props.orderData &&
      nextProps.orderData &&
      nextProps.orderData.viewer &&
      nextProps.orderData.viewer.getOrders &&
      nextProps.orderData.viewer.getOrders.items
    ) {
      const { setState } = this.props.ticketDistroContext.actions;
      const walkUpMode = get(nextProps, 'ticketDistroContext.state.walkUpMode', false);
      setState({ getOrders: nextProps.orderData.viewer.getOrders, dataLoaded: true, walkUpMode: false });
      const firstEvent = get(nextProps, 'orderData.viewer.getOrders.items.0.event.id', null);
      const firstTicketId = get(nextProps, 'orderData.viewer.getOrders.items.0.tickets.items.0.id', null);
      if (firstEvent) {
        if (walkUpMode) {
          history.push(`/event/${firstEvent}/ticket/${firstTicketId}`);
        } else {
          history.push(`/event/${firstEvent}`);
        }
      }
      return true;
    }
    return false;
  }

  render() {
    return (
      <Fragment>
        <Header />
        <LoadingOverlay visible />
        <EventPage ticketDistroContext={{ state: { dataLoaded: false } }} />
        <Footer />
      </Fragment>
    );
  }
}

export const Redirecter = compose(
  getOrdersQueryData,
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
)(RedirecterWithoutState);
