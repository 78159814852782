import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const infoCss = ({ theme }: { theme: ThemeInterface }) => ({
  background: theme.palette.lightShadow,
  padding: pixelsToRem(20, 30),
});

const detailsCss = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: pixelsToRem(20),
};

const stripeCss = ({ theme }: { theme: ThemeInterface }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.black,
  padding: pixelsToRem(10),
  ' + *': {
    borderTop: `1px solid ${theme.palette.shadow}`,
  },
});

const numTicketsCss = ({ theme }: { theme: ThemeInterface }) => ({
  borderRadius: '50%',
  height: pixelsToRem(38),
  width: pixelsToRem(38),
  textAlign: 'center',
  lineHeight: pixelsToRem(38),
  backgroundColor: theme.palette.lightShadow,
  fontWeight: 600,
  fontSize: pixelsToRem(16),
});

const containerInnerCss = (props) => ({
  padding: 0,
});

const orderIdCss = {
  padding: 0,
  margin: 0,
  fontWeight: 600,
  fontSize: pixelsToRem(16),
};

const dateCss = {
  padding: 0,
  margin: 0,
  fontSize: pixelsToRem(16),
};

const ticketNamesCss = ({ theme }: { theme: ThemeInterface }) => ({
  padding: pixelsToRem(8, 0),
  margin: 0,
  fontSize: pixelsToRem(14),
  color: theme.palette.darkerShadow,
  maxWidth: pixelsToRem(420),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const alignRightCss = {
  alignSelf: 'right',
  paddingRight: pixelsToRem(24),
};

export default {
  alignRightCss,
  detailsCss,
  infoCss,
  stripeCss,
  numTicketsCss,
  containerInnerCss,
  orderIdCss,
  dateCss,
  ticketNamesCss,
};
