import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from 'theming';
import { noop } from 'lodash';
import { Div } from 'MoshtixShared/component-element';
import ClickOutsideWrapper from 'MoshtixShared/component-click-outside';
import { keyCodes } from 'MoshtixShared/helper-key-code';
import { FocusVisibleManager } from 'MoshtixShared/component-with-focus-visible';
import { styles } from './styles';

export interface DropdownButtonWrapperProps {
  type: 'regular' | 'success' | 'warning' | 'alert';
  inverted: boolean;
  css: () => {} | {};
  keepOpenOnSelect: boolean;
  children: JSX.Element[] | JSX.Element;
  options: JSX.Element[] | JSX.Element;
}

const handleKeyDown: void = ({
  event,
  focusIndex,
  setFocusIndex,
  totalItems,
  triggerRef,
}: {
  event: Event;
  focusIndex: number;
  setFocusIndex: () => {};
  triggerRef: React.RefObject<HTMLButtonElement>;
}) => {
  let newFocusIndex: number = focusIndex;

  switch (true) {
    case event.keyCode === keyCodes['down arrow']:
      newFocusIndex += 1;
      if (newFocusIndex > totalItems) {
        // don't wrap around back to the start
        newFocusIndex = totalItems;
      }
      break;

    case event.keyCode === keyCodes['up arrow']:
      newFocusIndex -= 1;
      if (newFocusIndex < 0) {
        // don't wrap around back to the start
        newFocusIndex = 0;
      }
      break;
    case event.keyCode === keyCodes.escape || event.keyCode === keyCodes.tab:
      newFocusIndex = -1; // break out of the drop down button wrapper component from focus
      break;

    default:
      break;
  }
  setFocusIndex(newFocusIndex);
  if (event.keyCode === keyCodes.escape || event.keyCode === keyCodes.tab) {
    event.preventDefault();
    event.stopPropagation();
    triggerRef.current.focus();
  }
};

const DropdownButtonWrapperWithoutTheme: React.FunctionComponent = (props: DropdownButtonWrapperProps) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);

  /* istanbul ignore next */
  const {
    type = 'regular',
    inverted = false,
    css = {},
    children = null,
    options = null,
    keepOpenOnSelect = false,
    onBlur = noop,
  } = props;

  // eslint-disable-next-line @typescript-eslint/typedef
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(
    () => {
      if (focusIndex === -1) {
        // reset when we have a focusIndex -1
        setIsMenuVisible(false);
        setFocusIndex(0);
      }
    },
    [focusIndex],
  );

  const optionsList = options.map(
    (option: JSXElement, optionIndex: number) =>
      // remove falsy options
      option &&
      React.cloneElement(option, {
        onKeyDown: handleKeyDown,
        focusIndex,
        setFocusIndex,
        totalItems: options.length - 1, // using 0 based index
        isFocussed: isMenuVisible && focusIndex === optionIndex,
        triggerRef,
      }),
  );

  return (
    <FocusVisibleManager>
      <Div css={styles.containerCss({ props })}>
        <ClickOutsideWrapper
          onClickOutside={() => {
            setIsMenuVisible(false);
            onBlur();
          }}
        >
          <Div
            onClick={(event: Event): void => {
              event.preventDefault();
              setIsMenuVisible(!isMenuVisible);
              setIsFocused(true);
            }}
          >
            {React.cloneElement(children, {
              innerRef: triggerRef,
            })}
          </Div>
          {isMenuVisible && (
            <Div
              css={styles.menuContainerCss({ props, isMenuVisible })}
              onClick={() => !keepOpenOnSelect && setIsMenuVisible(false)}
            >
              {optionsList}
            </Div>
          )}
        </ClickOutsideWrapper>
      </Div>
    </FocusVisibleManager>
  );
};

export const DropdownButtonWrapper: React.FunctionComponent = withTheme(DropdownButtonWrapperWithoutTheme);
