import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const mediaQuery = `@media (min-width: ${pixelsToRem(1100)})`;

const sharedLoadingContainerCss = {
  width: 160,
  textAlign: 'center',
  margin: 'auto',
  userSelect: 'none',
  position: 'relative',
};

const loadingContainerCss = {
  ...sharedLoadingContainerCss,
  [mediaQuery]: {
    left: '125px',
  },
};

const loadingContainerCenteredCss = {
  ...sharedLoadingContainerCss,
};

// solid white for now until we make it transparent
const overlayCss = { backgroundColor: 'rgba(250, 250, 250, 0.5)' };

const imageCss = { width: pixelsToRem(160) };

const root = {
  loadingContainerCss,
  loadingContainerCenteredCss,
  overlayCss,
  imageCss,
};

export default root;
