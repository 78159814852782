import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const buttonContainerCss = {
  display: 'flex',
  justifyContent: 'right',
  marginTop: pixelsToRem(16),
};

const errorHeadingCss = {
  fontSize: pixelsToRem(18),
  margin: pixelsToRem(0, 0, 0, 12),
};

const formBackground = ({ theme }) => ({
  backgroundColor: theme.palette.white,
});

const infoContainerCss = {
  display: 'flex',
  alignItems: 'center',
  margin: pixelsToRem(40, 0),
  justifyContent: 'center',
};

const infoTextCss = {
  marginBottom: pixelsToRem(20),
};

const linkCss = {
  fontWeight: 700,
  textDecoration: 'none',
};

const textBoxCss = {
  maxWidth: '100%',
  minWidth: '100%',
  minHeight: pixelsToRem(96),
};

export default {
  buttonContainerCss,
  errorHeadingCss,
  formBackground,
  infoContainerCss,
  infoTextCss,
  linkCss,
  textBoxCss,
};
