import { keyframes } from '@emotion/core';
import merge from 'lodash/merge';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const buttonCss = ({ props }) => {
  let { invertHoverOpacity } = props;
  if (props.buttonType === 'iconOnly') {
    invertHoverOpacity = !invertHoverOpacity;
  }
  const idleOpacity = invertHoverOpacity ? 0.85 : 1;
  const hoveringOpacity = invertHoverOpacity ? 1 : 0.7;

  const notLoadingNotDisabledStyles = {
    cursor: 'pointer',
    ':active': { transform: `translateY(${pixelsToRem(2)})`, opacity: 1 },
  };
  const showIconOnlyOnHover = {
    paddingLeft: 0,
    paddingRight: 0,
    ' .moshtix-button-icon': { opacity: 0, transition: 'all 0.2s' },
    ':hover': merge(
      { ' .moshtix-button-icon': { opacity: 1 } },
      props.iconLeft && { paddingLeft: pixelsToRem(32) },
      props.iconRight && { paddingRight: pixelsToRem(32) },
    ),
  };

  const returnCss = merge(
    {
      display: 'inline-block',
      backgroundColor: 'transparent',
      border: 'none',
      boxSizing: 'border-box',
      borderRadius: pixelsToRem(4),
      fontFamily: 'inherit',
      fontSize: '1rem',
      fontWeight: 600,
      minHeight: pixelsToRem(48),
      opacity: 1,
      outline: `${pixelsToRem(3)} solid transparent`,
      overflow: 'hidden',
      padding: `${pixelsToRem(10)} ${pixelsToRem(20)}`,
      position: 'relative',
      textAlign: 'center',
      touchAction: 'none',
      transition: 'all 0.2s cubic-bezier(0.17, 0.67, 0.52, 0.97)',
      userSelect: 'none',
      width: 'auto',
    },
    props.capitalise && {
      textTransform: 'uppercase',
    },
    (props.buttonType === 'primary' || props.buttonType === 'error') && {
      boxShadow: '0px 2px 1px 0px rgba(0,0,0,0.20)',
      padding: `${pixelsToRem(12)} ${pixelsToRem(22)}`,
      transition: 'box-shadow 100ms linear, transform 100ms linear, background-position 150ms ease-out',
      ':focus': { outline: 'none' },
    },
    props.loading && { paddingLeft: pixelsToRem(40) },
    props.iconLeft && { paddingLeft: pixelsToRem(40) },
    props.iconRight && { paddingRight: pixelsToRem(40) },
    props.buttonType === 'tertiaryAlternate' &&
      merge(
        {
          border: 'none',
          minHeight: 'auto',
          padding: 0,
          outline: 'none',
          opacity: idleOpacity,
          ':hover': { opacity: hoveringOpacity },
          ':focus': { opacity: hoveringOpacity },
        },
        props.loading && { paddingLeft: pixelsToRem(32) },
        props.iconLeft && { paddingLeft: pixelsToRem(32) },
        props.iconRight && { paddingRight: pixelsToRem(32) },
      ),
    props.buttonType === 'iconOnly' &&
      merge({
        border: 'none',
        display: 'inline',
        minHeight: pixelsToRem(24),
        paddingTop: 0,
        paddingRight: pixelsToRem(2),
        paddingBottom: 0,
        paddingLeft: pixelsToRem(2),
        verticalAlign: 'top',
        outline: 'none',
        opacity: idleOpacity,
        ':hover': { opacity: hoveringOpacity },
        ':focus': { opacity: hoveringOpacity },
      }),
    props.disabled && { opacity: 0.5 },
    !props.disabled && !props.loading && notLoadingNotDisabledStyles,

    props.buttonType === 'tertiaryAlternate' && props.showIconOnlyOnHover && showIconOnlyOnHover,
    props.disabled && { opacity: 0.5 },

    !!props.theme &&
      !!props.theme.button &&
      resolveToObject({
        input: props.theme.button.buttonCss,
        params: { props },
      }),
    !!props.buttonType &&
      props.buttonType === 'secondary' &&
      !!props.css &&
      !!props.css.color && { borderColor: props.css.color },
    resolveToObject(
      // for convenience, if color set, then set same color to border and icons
      { input: props.css, params: { props } },
    ),
  );

  // if disabled or loading then remove any hover styles
  if (props.disabled || props.loading) {
    delete returnCss[':hover'];
  }

  return returnCss;
};

const spinKeyFrames = keyframes`
  '0%' {
    transform: 'rotate(0deg)'; 
  }
  '100%' {
    transform: 'rotate(360deg)';
  }
`;

const spin = {
  animation: `${spinKeyFrames} 1s linear infinite`,
};

const spanIconContainerLeftCss = ({ props }) =>
  merge(
    {},
    props.buttonType === 'iconOnly' && {
      display: 'block',
      height: pixelsToRem(24),
    },
    props.buttonType !== 'iconOnly' && {
      left: pixelsToRem(8),
      position: 'absolute',
      top: `calc(50% - ${pixelsToRem(12)})`,
    },
    props.buttonType === 'tertiaryAlternate' && {
      left: 0,
      outline: 'none',
    },
    !!props.theme &&
      !!props.theme.button &&
      resolveToObject({ input: props.theme.button.iconContainerLeftCss, params: { props } }),
    resolveToObject({ input: props.iconContainerLeftCss, params: { props } }),
  );

const spanIconContainerRightCss = ({ props }) =>
  merge(
    {
      right: pixelsToRem(8),
      position: 'absolute',
      top: `calc(50% - ${pixelsToRem(12)})`,
    },
    props.buttonType === 'tertiaryAlternate' && {
      right: 0,
    },
    !!props.theme &&
      !!props.theme.button &&
      resolveToObject({ input: props.theme.button.iconContainerRightCss, params: { props } }),
    resolveToObject({ input: props.iconContainerRightCss, params: { props } }),
  );

const iconCss = ({ props }) =>
  merge(
    {},
    !!props.theme && !!props.theme.button && resolveToObject({ input: props.theme.button.iconCss, params: { props } }),
    !!props.css && !!props.css.color && { color: props.css.color },
    resolveToObject({ input: props.iconCss, params: { props } }),
    (props.iconLeft || props.iconRight) && !!props.iconColor && { color: props.iconColor },
  );

const root = {
  buttonCss,
  spanIconContainerLeftCss,
  spanIconContainerRightCss,
  iconCss,
  spin,
};

export default root;
