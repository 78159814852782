import environmentVariables from 'EnvironmentVariables';

const configuration = {
  graphqlUrl: environmentVariables.graphqlUrl,
  adminOriginalUrl: environmentVariables.adminOriginalUrl,
  controlRoomUrl: environmentVariables.controlRoomUrl,
  publicWebsiteOriginalUrl: environmentVariables.publicWebsiteOriginalUrl,
  supportUrl: environmentVariables.supportUrl,
  staticAssetsUrl: environmentVariables.staticAssetsUrl,
  myUrl: environmentVariables.myUrl,
  cookieDomain: environmentVariables.cookieDomain,
};

export default configuration;
