import merge from 'lodash/merge';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

const labelCss = ({ props, state }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(16),
      fontWeight: 600,
      margin: `0 0 ${pixelsToRem(8)}`,
      transition: 'color 0.3s',
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.labelCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const subLabelCss = ({ props, state }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(14),
      fontWeight: 600,
      lineHeight: pixelsToRem(20),
      margin: `0 0 ${pixelsToRem(8)}`,
      transition: 'color 0.3s',
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.subLabelCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const subTextCss = ({ props, state }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(14),
      fontWeight: 400,
      lineHeight: pixelsToRem(20),
      margin: `0 0 ${pixelsToRem(8)}`,
      transition: 'color 0.3s',
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.subTextCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const footNoteCss = ({ props, state }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(12),
      fontWeight: 400,
      lineHeight: pixelsToRem(16),
      margin: `0 0 ${pixelsToRem(8)}`,
      transition: 'color 0.3s',
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.footNoteCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const footNoteLightCss = ({ props, state }) =>
  merge(
    {
      display: 'block',
      fontSize: pixelsToRem(12),
      fontWeight: 400,
      lineHeight: pixelsToRem(14),
      margin: `0 0 ${pixelsToRem(8)}`,
      transition: 'color 0.3s',
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.footNoteLightCss, params: { props, state } }),
    resolveToObject({ input: props.css, params: { props, state } }),
  );

const requiredAsteriskCss = ({ props, state }) =>
  merge(
    {
      fontWeight: 400,
    },
    !!props.theme &&
      !!props.theme.label &&
      resolveToObject({ input: props.theme.label.requiredAsteriskCss, params: { props, state } }),
    resolveToObject({ input: props.requiredAsteriskCss, params: { props, state } }),
  );

export const styles = {
  labelCss,
  subLabelCss,
  subTextCss,
  footNoteCss,
  footNoteLightCss,
  requiredAsteriskCss,
};
