/**
 * @callback SetCCEOId
 * @param {string} cceoid
 * @param {string} [domain]
 *
 * @callback GetCCEOId
 * @returns {string}
 *
 * @callback GetToken
 * @returns {string}
 *
 * @callback SetToken
 * @returns {string}
 *
 *
 * @callback GetClientToken
 * @returns {string}
 *
 *
 * @callback GetClientId
 * @returns {string}
 *
 *
 * @callback GetTicketDistroToken
 * @returns {string}
 *
 * @callback SetTicketDistroToken
 * @param {Object} params
 * @param {string} params.token
 * @param {string} params.domain
 *
 * @callback GetAccessToken
 * @returns {string}
 *
 * @callback SetAccessToken
 * @param {Object} params
 * @param {string} params.token
 * @param {string} params.domain
 *
 * @callback SetClient
 * @param {string} id
 * @param {string} token
 * @param {Function} callback
 *
 * @typedef {Object} Root
 * @property {SetCCEOId} setCCEOId
 * @property {GetCCEOId} getCCEOId
 * @property {GetToken} getToken
 * @property {SetToken} setToken
 * @property {GetCookie} getClientToken
 * @property {GetClientId} getClientId
 * @property {GetTicketDistroToken} getTicketDistroToken
 * @property {SetTicketDistroToken} setTicketDistroToken
 * @property {GetAccessToken} getAccessToken
 * @property {SetAccessToken} setAccessToken
 * @property {() => boolean} getAppExperience
 * @property {() => void} setAppExperience
 * @property {Function} setClient
 */

/**
 * @type {Root}
 */
module.exports = require('./src/index');
