import React, { useState, useEffect } from 'react';
import { get } from 'object-path';
import { graphql, compose } from 'react-apollo';
import noop from 'lodash/noop';
import { withTheme } from 'theming';

import tokenStore from 'MoshtixShared/helper-token-store';
import { Dialog } from 'MoshtixShared/component-dialog';
import { withState } from 'MoshtixShared/component-context-provider';
import Button from 'MoshtixShared/component-button';
import { Div } from 'MoshtixShared/component-element';
import LoadingOverlay from 'ControlRoomComponents/loading-overlay';

import configuration from 'Configuration';
import { getOrdersQuery } from '../../components/component-access-token-login/data-definition';
import { ZendeskFormWithoutThemeAndState } from './component';
import { saveZendeskTicketMutation, getHelpTopicsQuery } from './data-definition';
import { TicketDistroContext } from '../../app/contexts';

import styles from './styles';

const saveZendeskMutate = graphql(saveZendeskTicketMutation, {
  props: ({ mutate }) => ({
    save: (variables) =>
      mutate({
        variables: {
          ...variables,
          token: tokenStore.getTicketDistroToken(),
        },
      }),
  }),
});

const getHelpTopicsQueryData = graphql(getHelpTopicsQuery, {
  name: 'topicsData',
  options: (props) => ({
    variables: {
      token: tokenStore.getTicketDistroToken(),
    },
  }),
  props: ({ ownProps, topicsData: { loading, viewer, refetch, networkStatus } }) => ({
    ...ownProps,
    topicsData: {
      // if loading or networkStatus is refetching
      // pending fix with apollo network status though https://github.com/apollographql/apollo-client/issues/1257
      loading: loading || networkStatus === 4,
      viewer,
      refetchEvent: refetch,
    },
  }),
});

const getOrdersQueryData = graphql(getOrdersQuery, {
  name: 'orderData',
  options: (props) => ({
    variables: {
      token: tokenStore.getTicketDistroToken(),
    },
  }),
  props: ({ ownProps, orderData: { loading, viewer, refetch, networkStatus } }) => ({
    ...ownProps,
    orderData: {
      // if loading or networkStatus is refetching
      // pending fix with apollo network status though https://github.com/apollographql/apollo-client/issues/1257
      loading: loading || networkStatus === 4,
      viewer,
      refetchEvent: refetch,
    },
  }),
});

const handleClose = async () => {
  window.top.postMessage('close', '*');
};

const renderButtons = ({ isSuccess, isError, orderId, setState, formState, save, setIsLoading }) => {
  if (isSuccess) {
    return (
      <div>
        <Button
          buttonType="primary"
          onClick={() => {
            setState({ isHelpVisible: false, helpIsSuccess: false });
            handleClose();
          }}
        >
          Done
        </Button>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Button
          buttonType="tertiary"
          onClick={() => {
            setState({ isHelpVisible: false, helpIsSuccess: false, helpIsError: false });
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setIsLoading(true);
            try {
              const category = get(formState, 'changes.category.value', null);
              const topic = get(formState, 'changes.topic.value', null);
              const subtopic = get(formState, 'changes.subtopic.value', null);
              const description = get(formState, 'changes.message', null);

              const result = await save({
                supportTicket: {
                  category,
                  subtopic,
                  description,
                  orderId,
                  topic,
                },
              });
              const resultTicketId = get(result, 'data.viewer.supportTicket', null);
              setState({ helpIsSuccess: true, helpIsError: false, helpTicketId: resultTicketId });
            } catch (e) {
              setState({ helpIsError: true, helpIsSuccess: false, helpTicketId: 0 });
            }
            setIsLoading(false);
          }}
          buttonType="primary"
        >
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        buttonType="tertiary"
        onClick={() => {
          setState({ isHelpVisible: false });
          handleClose();
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={async () => {
          try {
            setIsLoading(true);
            const category = get(formState, 'changes.category.value', null);
            const topic = get(formState, 'changes.topic.value', null);
            const subtopic = get(formState, 'changes.subtopic.value', null);
            const description = get(formState, 'changes.message', null);
            const result = await save({
              supportTicket: {
                category,
                subtopic,
                description,
                orderId,
                topic,
              },
            });
            const resultTicketId = get(result, 'data.viewer.supportTicket', null);
            setState({ helpIsSuccess: true, helpIsError: false, helpTicketId: resultTicketId });
          } catch (e) {
            setState({ helpIsError: true, helpIsSuccess: false, helpTicketId: 0 });
          }
          setIsLoading(false);
        }}
        buttonType="primary"
      >
        Submit
      </Button>
    </div>
  );
};

const ZendeskDialogWithoutThemeAndState = (props) => {
  // get orderId from state in case of digital tickets as we may not know which order when token is event wide
  // else get orderId from props this is from iframe and there is already context for which order
  const orderId = get(props, 'ticketDistroContext.state.helpOrder', props.orderId);
  const topics = get(props, 'topicsData.viewer.getTopics.items', []);
  const topicsLoading = get(props, 'topicsData.loading', true);
  const hasEventAccess = get(props, 'topicsData.viewer.viewer.hasEventAccess', false);
  const { save } = props;
  if (!hasEventAccess && orderId && !topicsLoading) {
    // customer does not have access to event as a whole meaning they came in from email confirmation
    // redirect to log in to auth correctly. Prevents shared emails from being actions and unauthorised actions taken on shared tickets
    window.location.href = `//${configuration.publicWebsiteOriginalUrl}/v2/account/login`;
    return null;
  }
  const setState = get(props, 'ticketDistroContext.actions.setState', noop);
  const [formState, setFormState] = useState({});

  const helpIsSuccess = get(props, 'ticketDistroContext.state.helpIsSuccess', false);
  const [isSuccess, setIsSuccess] = useState(props.ticketDistroContext.state.helpIsSuccess);
  const [isError, setIsError] = useState(props.ticketDistroContext.state.helpIsError);
  const [isLoading, setIsLoading] = useState(topicsLoading);
  useEffect(() => setIsSuccess(get(props, 'ticketDistroContext.state.helpIsSuccess', false)), [
    props.ticketDistroContext.state.helpIsSuccess,
  ]);
  useEffect(() => setIsError(get(props, 'ticketDistroContext.state.helpIsError', false)), [
    props.ticketDistroContext.state.helpIsError,
  ]);
  useEffect(() => setIsLoading(get(props, 'topicsData.loading', true)), [props.topicsData.loading]);
  return (
    <Dialog
      {...props}
      onCloseClick={() => {
        setState({ isHelpVisible: false });
        handleClose();
      }}
      buttonContent={
        <Div css={styles.buttonContainerCss}>
          {renderButtons({ isSuccess, isError, orderId, setState, formState, save, setIsLoading })}
        </Div>
      }
    >
      {isLoading && <LoadingOverlay visible />}
      <ZendeskFormWithoutThemeAndState {...props} onChange={setFormState} hideControls orderId={orderId} />
    </Dialog>
  );
};

export const ZendeskDialog = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  getHelpTopicsQueryData,
  getOrdersQueryData,
  saveZendeskMutate,
  withTheme,
)(ZendeskDialogWithoutThemeAndState);
