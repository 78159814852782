import { OrderInterface } from '../types/app';

export const DATA: OrderInterface = {
  getOrders: {
    items: [
      {
        id: 0,
        event: {
          id: 10000,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id metus dolor. Proin iaculis molestie iaculis. Donec purus lorem, mattis eu sapien consectetur, ornare dignissim nunc. In ut massa at dolor euismod rutrum. Vestibulum lacinia hendrerit fringilla. In fringilla ut lorem quis dapibus. Fusce ut enim in sapien aliquam suscipit sit amet in justo. Vivamus fringilla arcu elit, quis condimentum sapien fermentum sed. Nunc non ipsum sed lorem pellentesque lacinia. Vestibulum bibendum tempus eros quis consectetur.

        Sed lacinia iaculis quam in vulputate. Donec quis ultricies magna, vitae sollicitudin sapien. In imperdiet lacus orci, eget lacinia ipsum blandit nec. In sed ante nec dolor rutrum lobortis eu sit amet mi. Integer eleifend, turpis eu volutpat porttitor, sem ex maximus ante, aliquet molestie lacus lectus in libero. Cras nisl arcu, iaculis vel justo id, venenatis rutrum quam. Ut rutrum risus sed arcu porttitor, a ornare tellus dignissim. Fusce interdum nec dui in viverra. Maecenas bibendum eget nibh eu viverra. Pellentesque eleifend, est eu fermentum ullamcorper, nibh diam viverra nunc, sit amet fringilla nisi ante at mi. Donec laoreet, nisl at pharetra porttitor, arcu magna pharetra diam, ac porttitor augue nibh ut mi. Ut quis orci dignissim, blandit turpis vitae, commodo nisi. Aliquam blandit purus id magna convallis, nec iaculis turpis congue. In finibus egestas tempor. Ut diam eros, ullamcorper ut metus facilisis, consectetur ornare leo.`,
          thumbnailUrl: 'https://www.w3schools.com/howto/pineapple.jpg',
          name: '',
          startDate: '',
          endDate: '',
          venue: {
            id: 4094,
            name: '',
            address: {
              locality: '',
              region: '',
            },
          },
        },
        createdDate: '2019-08-03T00:25:00.000Z',
        tickets: {
          items: [
            {
              ticketType: 'General Admission',
              barcode: 1234567890,
              ticketNumber: 2345678901, // don't need this I think
              orderNumber: 12345,
              customerNumber: 123456,
              price: 1000,
              description: 'General admission ticket',
              firstName: '',
              lastName: '',
              birthDate: '1991-04-01T00:00:00.000Z',
              reissueAvailable: true,
              resaleAvailable: true,
              suppressed: false,
              scanned: false,
            },
          ],
        },
        customer: {
          firstName: 'John',
          id: 1,
          lastName: 'Smith',
          email: '',
          mobile: '0466666666',
          telephone: '',
        },
      },
    ],
  },
  dataLoaded: false,
  isHelpVisible: false,
};
