import React from 'react';
import PropTypes from 'prop-types';
import { Div } from 'MoshtixShared/component-element';
import Icon from 'MoshtixShared/component-icon';
import { withTheme } from 'theming';

import styles from './styles';

const AlertComponent = (props) => {
  if (!props.text) {
    return null;
  }
  let { iconType } = props;
  if (props.type === 'warning') {
    iconType = iconType || 'exclamation-triangle';
  }
  if (props.type === 'success') {
    iconType = iconType || 'tick';
  }

  return (
    <Div css={styles.alertCss({ props })}>
      <Icon type={iconType} css={styles.iconCss({ props })} /> {props.text || ''}
      &nbsp;
    </Div>
  );
};

AlertComponent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  iconType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  css: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/no-unused-prop-types
  iconCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  // eslint-disable-next-line react/no-unused-prop-types
  iconInnerCss: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

AlertComponent.defaultProps = {
  text: '',
  type: '',
  iconType: 'exclamation',
  css: {},
  iconCss: {},
  iconInnerCss: {},
};

const ThemedAlert = withTheme(AlertComponent);
export const Alert = (props) => <ThemedAlert {...props} />;
Alert.propTypes = AlertComponent.propTypes;
Alert.defaultProps = AlertComponent.defaultProps;

export default Alert;
