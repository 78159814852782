import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const textboxBarCss = {
  display: 'flex',
  marginTop: pixelsToRem(5),
  marginBottom: pixelsToRem(11),
  alignItems: 'center',
};

const buttonCss = ({ theme }: { theme: ThemeInterface }) => ({
  height: pixelsToRem(44),
  width: pixelsToRem(44),
  background: theme.palette.success,
  borderRadius: 0,
});

const warningText = ({ theme }: { theme: ThemeInterface }) => ({
  color: theme.palette.warning,
  fontSize: pixelsToRem(12),
  marginBottom: 0,
});

const textBox = () => ({
  height: pixelsToRem(44),
  margin: 0,
});

export default {
  textboxBarCss,
  buttonCss,
  warningText,
  textBox,
};
