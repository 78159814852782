import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { withTheme } from 'theming';

import Icon from 'MoshtixShared/component-icon';

import { styles } from './styles';

// TODO: Refactor this to have an actual type.
// pretty far way from being able to do this.

export const H1: React.ComponentType<any> = withTheme(styled('h1')({}, (props: any) => styles.h1Css({ props })));
export const H2: React.ComponentType<any> = withTheme(styled('h2')({}, (props: any) => styles.h2Css({ props })));
export const H3: React.ComponentType<any> = withTheme(styled('h3')({}, (props: any) => styles.h3Css({ props })));
export const H4: React.ComponentType<any> = withTheme(styled('h4')({}, (props: any) => styles.h4Css({ props })));
export const P: React.ComponentType<any> = withTheme(styled('p')({}, (props: any) => styles.pCss({ props })));
export const Small: React.ComponentType<any> = withTheme(
  styled('small')({}, (props: any) => styles.smallCss({ props })),
);
export const Strong: React.ComponentType<any> = withTheme(
  styled('strong')({}, (props: any) => styles.strongCss({ props })),
);
export const AElement: React.ComponentType<any> = withTheme(styled('a')({}, (props: any) => styles.aCss({ props })));
export const A: React.ComponentType<any> = (props: any) => {
  if (props.external) {
    return (
      <AElement {...props} css={{ ...props.css, ...styles.aExternalCss }}>
        {props.children}
        <Icon type="external-link" css={styles.aExternalIconCss} />
      </AElement>
    );
  }
  return <AElement {...props} />;
};
export const Hr: React.ComponentType<any> = withTheme(styled('hr')({}, (props: any) => styles.hrCss({ props })));
export const Ul: React.ComponentType<any> = withTheme(
  styled('ul')({}, (props: any) => styles.ulCss({ props }), { forwardInnerRef: true }),
);
export const Ol: React.ComponentType<any> = withTheme(styled('ol')({}, (props: any) => styles.olCss({ props })));
export const Li: React.ComponentType<any> = withTheme(styled('li')({}, (props: any) => styles.liCss({ props })));
export const Sub: React.ComponentType<any> = withTheme(styled('sub')({}, (props: any) => styles.subCss({ props })));
export const Label: React.ComponentType<any> = withTheme(
  styled('label')({}, (props: any) => styles.labelCss({ props })),
);

export const Textarea: React.ComponentType<any> = withTheme(
  styled('textarea')({}, (props: any) => styles.textareaCss({ props })),
);

export const Button: React.ComponentType<any> = withTheme(
  styled('button')({}, (props: any) => styles.buttonCss({ props })),
);
export const Span: React.ComponentType<any> = withTheme(styled('span')({}, (props: any) => styles.spanCss({ props })));

export const Div: React.ComponentType<any> = withTheme(styled('div')({}, (props: any) => styles.divCss({ props })), {
  forwardInnerRef: true,
});
export const Iframe: React.ComponentType<any> = withTheme(
  styled('iframe')({}, (props: any) => styles.iframeCss({ props })),
);
export const Img: React.ComponentType<any> = withTheme(styled('img')({}, (props: any) => styles.imgCss({ props })));
export const Table: React.ComponentType<any> = withTheme(
  styled('table')({}, (props: any) => styles.tableCss({ props })),
);
export const Tr: React.ComponentType<any> = withTheme(styled('tr')({}, (props: any) => styles.trCss({ props })));
export const Th: React.ComponentType<any> = withTheme(styled('th')({}, (props: any) => styles.thCss({ props })));
export const Td: React.ComponentType<any> = withTheme(styled('td')({}, (props: any) => styles.tdCss({ props })));

export const Input: React.ComponentType<any> = withTheme(
  styled('input')({}, (props: any) => styles.inputCss({ props })),
  {
    forwardInnerRef: true,
  },
);

export const Nav: React.ComponentType<any> = withTheme(styled('nav')({}, (props: any) => styles.navCss({ props })));
export const Svg: React.ComponentType<any> = withTheme(styled('svg')({}, (props: any) => styles.svgCss({ props })));
export const Header: React.ComponentType<any> = withTheme(
  styled('header')({}, (props: any) => styles.headerCss({ props })),
);

export const SubHeading: React.ComponentType<any> = withTheme(
  styled('div')({}, (props: any) => styles.subHeadingCss({ props })),
);
const UnnamedCheckDiv: React.ComponentType<any> = withTheme(
  styled('div')({}, (props: any) => styles.UnnamedCheckCss({ props })),
);
export const UnnamedCheck: React.ComponentType<any> = (props: any) => (
  <UnnamedCheckDiv {...props}>{props.text || 'Unnamed'}</UnnamedCheckDiv>
); //eslint-disable-line

A.propTypes = {
  css: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  external: PropTypes.bool,
  children: PropTypes.node,
};

A.defaultProps = {
  css: {},
  external: false,
  children: undefined,
};
