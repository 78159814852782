// import LogRocket from 'logrocket';
import environmentVariables from 'EnvironmentVariables';

import { render } from 'react-dom';

import React from 'react';
import App from './app';

// only need to track production sessions
if (environmentVariables.environment === 'production') {
  // to enable LogRocket on this project uncomment the line below
  // LogRocket.init('asswmj/my-moshtix');
}

render(<App />, document.getElementById('app-container'));
