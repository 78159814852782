import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';

const linkCss = {
  textDecoration: 'underline',
  textTransform: 'uppercase',
  fontSize: pixelsToRem(14),
  fontWeight: 600,
};

export default {
  linkCss,
};
