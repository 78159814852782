import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from 'theming';
import noop from 'lodash/noop';
import isNil from 'lodash/isNil';
import { useDebounceCallback } from '@react-hook/debounce';
import { Div, Input, Span } from 'MoshtixShared/component-element';
import { checkSmartDevice } from 'MoshtixShared/helper-device-info';
import { TextBoxProps } from 'MoshtixShared/component-text-box';
import Label from 'MoshtixShared/component-label';

import { styles } from './styles';

interface NumberBoxProps extends TextBoxProps {
  value: string;
  disabled: boolean;
  placeholderText: string;
  keyCharacter: string;
  forceDecimals: number | null;
  textAlign: 'left' | 'right' | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string | number | null }) => void;
}

const convertToString = ({
  value,
  forceDecimals,
}: {
  value?: string | number | null;
  forceDecimals: number | null;
}) => {
  // instantiated as empty string so hard to get it to null undefined
  /* istanbul ignore next */
  if (isNil(value)) {
    /* istanbul ignore next */
    return '';
  }
  /* istanbul ignore next */
  if (value !== '' && typeof value !== 'number' && !Number.isNaN(Number(value)) && Boolean(forceDecimals)) {
    /* istanbul ignore next */
    return Number(value).toFixed(forceDecimals);
  }
  // rarely a case where this is a number its coming from an input text box
  /* istanbul ignore next */
  if (typeof value === 'number' && Boolean(forceDecimals)) {
    /* istanbul ignore next */
    return value.toFixed(forceDecimals);
  }
  return value.toString();
};

const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  {
    setValue,
    forceDecimals,
    debounce,
    onChange,
    onChangeDebounced,
    includeLeadingZero,
    maxLength,
  }: {
    setValue: (value: string) => void;
    forceDecimals: number | null;
    debounce: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, { newValue }: { newValue: string | number | null }) => void;
    onChangeDebounced: (
      event: React.ChangeEvent<HTMLInputElement>,
      { newValue }: { newValue: string | number | null },
    ) => void;
    includeLeadingZero: boolean;
    maxLength?: number;
  },
) => {
  const stringValue = event.target.value;
  if (!maxLength || stringValue.length <= maxLength) {
    // default allow all numbers
    let validTypingNumber = /^$|^-?[1-9]?\d*\.?(\d+)?$/;
    // if forceDecimals is specified then check input coming in against other regexes
    if (forceDecimals === 0) {
      validTypingNumber = /^$|^-?[1-9]?\d*$/;
    } else if (forceDecimals && forceDecimals > 0) {
      validTypingNumber = new RegExp(`^$|^-?[1-9]?\\d*\\.?(\\d{0,${forceDecimals}})?$`);
    }
    const valueValid = stringValue.match(validTypingNumber);
    if (!valueValid) {
      event.preventDefault();
      return;
    }
    setValue(stringValue);
    let newValue = null;
    if (!includeLeadingZero) {
      /* istanbul ignore next */
      newValue = stringValue === '' ? null : parseFloat(stringValue);
    } else {
      // parseFloat strips out leading zeros. Sometimes we want them like in the case of bsb and account number
      newValue = stringValue;
    }
    if (!debounce) {
      onChange(event, { newValue });
    } else {
      onChangeDebounced(event, { newValue });
    }
  } else {
    // if it exceeds the max length
    let newValue = stringValue.substr(0, maxLength);
    if (!includeLeadingZero) {
      /* istanbul ignore next */
      newValue = stringValue === '' ? null : parseFloat(newValue);
    }
    if (!debounce) {
      onChange(event, { newValue });
    } else {
      onChangeDebounced(event, { newValue });
    }
    setValue(String(newValue));
  }
};

const handleBlur = async (
  event: React.ChangeEvent<HTMLInputElement>,
  {
    onBlur,
    setFocussed,
    setValue,
    value,
    forceDecimals,
    debounce,
    onChangeDebounced,
  }: {
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setFocussed: (focussed: boolean) => void;
    setValue: (value: string) => void;
    value: string | number | null;
    forceDecimals: number | null;
    debounce: boolean;
    onChangeDebounced: (
      event: React.ChangeEvent<HTMLInputElement>,
      { newValue }: { newValue: string | number | null },
    ) => void;
  },
) => {
  onBlur(event);
  // need to give a chance for the props.value to come through after the manuallyResolveDebounce() has possibly updated the state
  setTimeout(async () => {
    setFocussed(false);
    await setValue(convertToString({ value, forceDecimals }));
  });
};

const handleFocus = (
  event: React.ChangeEvent<HTMLInputElement>,
  {
    setFocussed,
    onFocus,
  }: { setFocussed: (focussed: boolean) => void; onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void },
) => {
  onFocus(event);
  setFocussed(true);
};
const handleKeyPress = () => null;

const NumberBoxWithoutTheme: React.FunctionComponent<NumberBoxProps> = (props: NumberBoxProps) => {
  const {
    className = '',
    disabled = false,
    errorState = false,
    height = 100,
    id = '',
    idInput = '',
    label = null,
    minimal = false,
    multiline = false,
    name = '',
    onBlur = noop,
    onChange = noop,
    onClick = noop,
    onFocus = noop,
    onKeyPress = noop,
    onKeyDown = noop,
    onPaste = noop,
    readOnly = false,
    debounce = false,
    debounceTime = 1000,
    type = 'text',
    value: initialValue = '',
    width = '',
    autoComplete = 'on',
    css = noop,
    inputCss = noop,
    placeholderCss = noop,
    iconLeft = null,
    placeholderText = '',
    classNameInput = '',
    keyCharacter = '',
    forceDecimals = null,
    textAlign = 'right',
    includeLeadingZero = false,
    minLength = null,
    maxLength = null,
  } = props;

  const [focussed, setFocussed] = useState(false);
  const [value, setValue] = useState(convertToString({ value: initialValue, forceDecimals }));
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const isSmartDevice = checkSmartDevice();

  const buildStateAndPropsForCss = () => ({
    props: {
      ...props,
    },
    state: {
      focussed,
      value,
    },
  });

  /* istanbul ignore next */
  const onChangeDebounced = useDebounceCallback(onChange, debounceTime);

  useEffect(
    () => {
      setValue(convertToString({ value: initialValue, forceDecimals }));
    },
    [initialValue],
  );

  const placeholder =
    !disabled && Boolean(placeholderText) ? (
      <Span css={styles.numberPlaceholderCss(buildStateAndPropsForCss())}>{placeholderText}</Span>
    ) : null;

  const beforeInput = keyCharacter ? (
    <Span css={styles.keyCharacterCss(buildStateAndPropsForCss())}>{keyCharacter}</Span>
  ) : null;

  return (
    <Div id={id} className={className} css={styles.containerCss(buildStateAndPropsForCss())} ref={containerRef}>
      <Div css={styles.containerInnerCss(buildStateAndPropsForCss())}>
        {label && <Label>{label}</Label>}
        {placeholder}
        {beforeInput}
        <Input
          id={idInput}
          name={name}
          type={isSmartDevice ? 'number' : 'text'}
          value={value}
          css={styles.numberInputCss(buildStateAndPropsForCss())}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event, {
              setValue,
              forceDecimals,
              debounce,
              onChange,
              onChangeDebounced,
              includeLeadingZero,
              maxLength,
            })
          }
          onFocus={(event: React.ChangeEvent<HTMLInputElement>) => handleFocus(event, { setFocussed, onFocus })}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleBlur(event, {
              onBlur,
              setFocussed,
              setValue,
              value,
              forceDecimals,
              debounce,
              onChangeDebounced,
            })
          }
          onKeyPress={handleKeyPress}
          disabled={props.disabled}
          className={classNameInput}
          readOnly={readOnly}
          innerRef={inputRef}
        />
      </Div>
    </Div>
  );
};

export const NumberBox = withTheme(NumberBoxWithoutTheme);
