import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'theming';
import { Div, Input, Label } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import styles from './styles';

const CheckBoxComponent = (props) => {
  const onChange = (event) => {
    if (!props.disabled) {
      props.onChange(event, { newValue: event.target.checked });
    }
  };

  return (
    <Div className={props.className} css={styles.containerCss({ props: { ...props, css: props.css }, state: null })}>
      <Label css={styles.labelCss({ props, state: null })}>
        {props.labelPosition === 'left' && props.label}
        {props.checked &&
          !props.indeterminate && (
            <Icon type="tick" disabled={props.disabled} css={styles.iconCss({ props, state: null })} />
          )}
        {props.checked &&
          props.indeterminate && (
            <Icon type="indeterminate" disabled={props.disabled} css={styles.iconCss({ props, state: null })} />
          )}
        <Input
          id={props.id}
          css={styles.inputCss({ props, state: null })}
          type="checkbox"
          name={props.name}
          className={props.className}
          checked={props.checked}
          disabled={props.disabled}
          onChange={onChange}
        />
        {props.labelPosition === 'right' && props.label}
        {props.children}
      </Label>
    </Div>
  );
};

CheckBoxComponent.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  css: PropTypes.shape(),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  iconCss: PropTypes.shape(),
  indeterminate: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  inputCss: PropTypes.shape(),
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  labelCss: PropTypes.shape(),
  labelPosition: PropTypes.oneOf(['right', 'left']),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CheckBoxComponent.defaultProps = {
  checked: false,
  children: null,
  className: '',
  css: {},
  disabled: false,
  iconCss: {},
  id: '',
  indeterminate: false,
  inputCss: {},
  label: '',
  labelCss: {},
  labelPosition: 'right',
  name: '',
};

const ThemedCheckBox = withTheme(CheckBoxComponent);
export const CheckBox = (props) => <ThemedCheckBox {...props} />;
CheckBox.propTypes = CheckBoxComponent.propTypes;
CheckBox.defaultProps = CheckBoxComponent.defaultProps;

export default CheckBox;
