import React, { useState } from 'react';
import { compose } from 'react-apollo';
import { withTheme } from 'theming';
import { get } from 'object-path';
import noop from 'lodash/noop';

import configuration from 'Configuration/index';
import { withState } from 'MoshtixShared/component-context-provider';
import { DropDownButtonOption, DropDownButtonWrapper } from 'MoshtixShared/component-drop-down-button';
import { Div, Span, A } from 'MoshtixShared/component-element';
import { Icon } from 'MoshtixShared/component-icon';
import { CheckBox } from 'MoshtixShared/component-check-box';
import { Button } from 'MoshtixShared/component-button';
import { ThemeInterface } from 'Types/theme';

import { history } from '../../../app/history';
import { TicketDistroContext } from '../../../app/contexts';
import { TicketInterface } from '../../../types/app';
import styles from './styles';

interface TicketListItemProps {
  ticket: TicketInterface;
  theme: ThemeInterface;
  order: any;
  onChange: ({ id }: { id: number }) => void;
  checked: boolean;
  canResell: boolean;
  disableTicketDialog: boolean;
}

const TicketListItemWithoutThemeAndState: React.SFC<TicketListItemProps> = ({
  ticket,
  theme,
  onChange,
  checked,
  order,
  event,
  disableTicketDialog = false,
  canResell = true,
  ticketDistroContext,
}) => {
  const { firstName = '', lastName = '', ticketType, id, walletDownloadUrl, pdfDownloadUrl } = ticket;
  const eventUrl = history.location.pathname.endsWith('/')
    ? history.location.pathname
    : `${history.location.pathname}/`;
  const ticketLink = `${eventUrl}ticket/${id}`;
  const [isFocused, setIsFocused]: [boolean, (isFocused: boolean) => void] = useState(false);
  const { digitalTicketsOnly } = event;
  const isValidTicket = !ticket.scanned && ticket.valid && !ticket.ticketType.suppressDistribution;
  const setState = get(ticketDistroContext, 'actions.setState', noop);

  return (
    <Div css={styles.cardContainerCss({ isFocused })}>
      <CheckBox
        css={styles.checkBoxCss({ theme })}
        onChange={(e) => {
          e.stopPropagation();
          onChange({ ticketId: ticket.id });
        }}
        checked={checked}
      />
      <A
        onClick={(e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          if (disableTicketDialog) {
            window.open(pdfDownloadUrl, '_blank');
          } else {
            history.push(ticketLink);
          }
        }}
        href={disableTicketDialog ? pdfDownloadUrl : ticketLink}
        css={styles.cardCss({ theme, checked })}
      >
        <Div css={styles.flagCss({ theme })} />
        <Div css={styles.nameContainerCss({ theme })}>
          <Div css={styles.ticketTypeNameCss({ theme })}>{ticket.ticketTypeName}</Div>
          <Div css={styles.nameAndIconCss({ theme })}>
            {((firstName && firstName.length) || (lastName && lastName.length)) && (
              <Icon css={styles.ticketIconCss({ theme, checked })} type="attendee" />
            )}
            <Span css={styles.ticketNameCss({ theme, checked })}>{firstName}</Span>
            <Span css={styles.ticketNameCss({ theme, checked })}>{lastName}</Span>
          </Div>
        </Div>
      </A>
      <DropDownButtonWrapper
        css={styles.extraOptionsCss}
        onBlur={() => setIsFocused(false)}
        options={[
          <DropDownButtonOption
            onClick={() => {
              setIsFocused(false);
              window.open(
                `https://${configuration.publicWebsiteOriginalUrl}/v2/account/reissue-my-ticket/${order.id}`,
                '_blank',
              );
            }}
            iconLeft="attendee"
            iconColor={theme.palette.success}
          >
            Change name
          </DropDownButtonOption>,
          canResell && (
            <DropDownButtonOption
              onClick={() => {
                setIsFocused(false);
                window.open(
                  `https://${configuration.publicWebsiteOriginalUrl}/v2/account/re-sell-my-ticket/${order.id}`,
                  '_blank',
                );
              }}
              iconLeft="resell"
              iconColor={theme.palette.success}
            >
              Resell
            </DropDownButtonOption>
          ),
          isValidTicket && (
            <DropDownButtonOption
              onClick={() => {
                setIsFocused(false);
                window.open(walletDownloadUrl, '_blank');
              }}
              iconLeft="mobile"
              iconColor={theme.palette.success}
            >
              Add to wallet
            </DropDownButtonOption>
          ),
          isValidTicket && !digitalTicketsOnly && (
            <DropDownButtonOption
              onClick={() => {
                setIsFocused(false);
                window.open(pdfDownloadUrl, '_blank');
              }}
              iconLeft="pdf"
              iconColor={theme.palette.success}
            >
              View / Print PDF
            </DropDownButtonOption>
          ),
          isValidTicket && (
            <DropDownButtonOption
              onClick={() => {
                setState({ isHelpVisible: true, helpOrder: order.id });
              }}
              iconLeft="question"
              iconColor={theme.palette.success}
            >
              Help Form
            </DropDownButtonOption>
          ),
        ]}
      >
        <Button
          css={styles.menuButtonCss({ theme })}
          iconLeft="vertical-dots"
          buttonType="iconOnly"
          iconColor={checked ? theme.palette.white : null}
          onClick={() => {
            setIsFocused(true);
          }}
        />
      </DropDownButtonWrapper>
    </Div>
  );
};

export const TicketListItem = compose(
  withState({
    contextName: 'ticketDistroContext',
    Context: TicketDistroContext,
  }),
  withTheme,
)(TicketListItemWithoutThemeAndState);
