import React from 'react';
import PropTypes from 'prop-types';
import BreakPoint from 'react-responsive';

const buildQuery = (props) => {
  const queries = [];
  if (
    !!props.below &&
    !!props.configuration.breakpoints[props.below] &&
    props.configuration.breakpoints[props.below].minimumWidth
  ) {
    queries.push(`(min-width: ${props.configuration.breakpoints[props.below].minimumWidth}px)`);
  }
  if (
    !!props.above &&
    !!props.configuration.breakpoints[props.above] &&
    props.configuration.breakpoints[props.above].minimumWidth
  ) {
    queries.push(`(max-width: ${props.configuration.breakpoints[props.above].minimumWidth - 1}px)`);
  }
  if (!queries.length) {
    return '';
  }
  return queries.join(' and ');
};

const Hide = (props) => {
  const query = buildQuery(props);
  if (!query) {
    return null;
  }
  return <BreakPoint query={query}>{props.children}</BreakPoint>;
};

Hide.propTypes = {
  above: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  below: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  children: PropTypes.node,
};

Hide.defaultProps = {
  above: '',
  below: '',
  children: undefined,
};

export default Hide;
