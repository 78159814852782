import { ThemeInterface } from 'Types/theme';
import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { configuration } from 'MoshtixShared/helper-responsive-configuration';

const largeQuery = configuration.getMediaQuery('large');

const ticketDetailsContainer = ({ theme }: { theme: ThemeInterface }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.white,
  transform: 'rotateY(180deg)',
  textAlign: 'left',
  boxSizing: 'border-box',
  padding: pixelsToRem(0, 30, 36),
  overflowY: 'scroll',
  backfaceVisibility: 'hidden',
  paddingTop: pixelsToRem(93),
  '::before': {
    content: '""',
    display: 'block',
    height: 12,
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundImage: 'linear-gradient(130deg, #00C900 2%, #9D47FF 100%)',
  },
  [largeQuery]: {
    paddingTop: pixelsToRem(0),
    position: 'relative',
    height: 'auto',
    backfaceVisibility: 'visible',
    transform: 'none',
    width: '50%',
    backgroundColor: theme.palette.lighterShadow,
    '::before': {
      display: 'none',
    },
  },
});
const ticketDetailsLabelCss = {
  fontWeight: 600,
  marginBottom: 0,
};
const ticketDetailsCss = {
  marginBottom: 0,
  ':nth-child(2n)': {
    marginBottom: pixelsToRem(18),
  },
  ':last-child': { marginBottom: 0 },
  wordBreak: 'break-word',
};
const ticketDetailsBackground = ({ theme }: { theme: ThemeInterface }) => ({
  [largeQuery]: {
    backgroundColor: theme.palette.lightShadow,
    padding: pixelsToRem(20),
    borderRadius: 4,
  },
});
const ticketActionsDetails = {
  display: 'none',
  [largeQuery]: {
    display: 'block',
    paddingTop: pixelsToRem(24),
    marginBottom: pixelsToRem(36),
  },
};
const ticketDetailsBackToEvent = {
  textIndent: -99999,
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
  minHeight: 0,
  backgroundImage: 'none',
  boxShadow: 'none',
  padding: 0,
  borderColor: 'transparent',
  width: pixelsToRem(24),
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
const ticketDetailsBackToTicket = {
  textIndent: -99999,
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'transparent',
  minHeight: 0,
  backgroundImage: 'none',
  boxShadow: 'none',
  padding: 0,
  borderColor: 'transparent',
  width: pixelsToRem(24),
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ticketDetailsMobileControlContainer = {
  position: 'absolute',
  top: 25,
  left: 15,
  right: 15,
  display: 'flex',
  justifyContent: 'space-between',
  [largeQuery]: {
    display: 'none',
  },
};

const strikethrough = {
  textDecoration: 'line-through',
};

export default {
  ticketDetailsBackground,
  ticketDetailsContainer,
  ticketDetailsMobileControlContainer,
  ticketDetailsBackToTicket,
  ticketDetailsBackToEvent,
  ticketDetailsCss,
  ticketDetailsLabelCss,
  ticketActionsDetails,
  strikethrough,
};
