import React, { useEffect, useState } from 'react';

// A React hook based on: https://github.com/WICG/focus-visible

interface FocusVisibleInterface {
  hadKeyboardEvent: boolean;
  isInitialized: boolean;
}

const onPointerDown = ({ setHadKeyboardEvent }: { setHadKeyboardEvent: boolean }) => {
  setHadKeyboardEvent(false);
};

/**
 * When the polyfill first loads, assume the user is in keyboard modality.
 * If any event is received from a pointing device (e.g. mouse, pointer,
 * touch), turn off keyboard modality.
 * This accounts for situations where focus enters the page from the URL bar.
 * @param {Event} e
 */
const onInitialPointerMove = (e: Event, { setHadKeyboardEvent }: { setHadKeyboardEvent: boolean }) => {
  // Work around a Safari quirk that fires a mousemove on <html> whenever the
  // window blurs, even if you're tabbing out of the page. ¯\_(ツ)_/¯
  if (e.target.nodeName && e.target.nodeName.toLowerCase() === 'html') {
    return;
  }

  setHadKeyboardEvent(false);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  removeInitialPointerMoveListeners({ setHadKeyboardEvent });
};

/**
 * Add a group of listeners to detect usage of any pointing devices.
 * These listeners will be added when the polyfill first loads, and anytime
 * the window is blurred, so that they are active when the window regains
 * focus.
 */
const addInitialPointerMoveListeners = ({ setHadKeyboardEvent }: { setHadKeyboardEvent: boolean }) => {
  document.addEventListener('mousemove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('mousedown', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('mouseup', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('pointermove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('pointerdown', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('pointerup', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('touchmove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('touchstart', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.addEventListener('touchend', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
};

const removeInitialPointerMoveListeners = ({ setHadKeyboardEvent }: { setHadKeyboardEvent: boolean }) => {
  document.removeEventListener('mousemove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('mousedown', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('mouseup', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('pointermove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('pointerdown', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('pointerup', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('touchmove', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('touchstart', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
  document.removeEventListener('touchend', (event: Event) => {
    onInitialPointerMove(event, { setHadKeyboardEvent });
  });
};

/**
 * If the most recent user interaction was via the keyboard;
 * and the key press did not include a meta, alt/option, or control key;
 * then the modality is keyboard. Otherwise, the modality is not keyboard.
 * Apply `focus-visible` to any current active element and keep track
 * of our keyboard modality state with `hadKeyboardEvent`.
 * @param {KeyboardEvent} e
 */
const onKeyDown = (e: Event, { setHadKeyboardEvent }: { setHadKeyboardEvent: () => void }) => {
  if (e.metaKey || e.altKey || e.ctrlKey) {
    return;
  }

  setHadKeyboardEvent(true);
};
/**
 * If the user changes tabs, keep track of whether or not the previously
 * focused element had .focus-visible.
 * @param {Event} e
 */
const onVisibilityChange = (e: Event, { setHadKeyboardEvent }: { setHadKeyboardEvent: () => void }) => {
  if (document.visibilityState === 'hidden') {
    // If the tab becomes active again, the browser will handle calling focus
    // on the element (Safari actually calls it twice).
    // If this tab change caused a blur on an element with focus-visible,
    // re-apply the class when the user switches back to the tab.
    setHadKeyboardEvent(true);
    addInitialPointerMoveListeners({ setHadKeyboardEvent });
  }
};

export const FocusVisibleContext = React.createContext<FocusVisibleInterface>({
  hadKeyboardEvent: true,
  isInitialized: false,
});

export const FocusVisibleManager = (props: any) => {
  const [hadKeyboardEvent, setHadKeyboardEvent] = useState(true);

  useEffect(
    () => {
      // For some kinds of state, we are interested in changes at the global scope
      // only. For example, global pointer input, global key presses and global
      // visibility change should affect the state at every scope:
      document.addEventListener(
        'keydown',
        (event: Event) => {
          onKeyDown(event, { setHadKeyboardEvent });
        },
        true,
      );
      document.addEventListener(
        'mousedown',
        () => {
          onPointerDown({ setHadKeyboardEvent });
        },
        true,
      );
      document.addEventListener(
        'pointerdown',
        () => {
          onPointerDown({ setHadKeyboardEvent });
        },
        true,
      );
      document.addEventListener(
        'touchstart',
        () => {
          onPointerDown({ setHadKeyboardEvent });
        },
        true,
      );
      document.addEventListener(
        'visibilitychange',
        (event: Event) => {
          onVisibilityChange(event, { setHadKeyboardEvent });
        },
        true,
      );

      addInitialPointerMoveListeners({ setHadKeyboardEvent });

      return () => {
        document.removeEventListener(
          'keydown',
          (event: Event) => {
            onKeyDown(event, { setHadKeyboardEvent });
          },
          true,
        );
        document.removeEventListener(
          'mousedown',
          () => {
            onPointerDown({ setHadKeyboardEvent });
          },
          true,
        );
        document.removeEventListener(
          'pointerdown',
          () => {
            onPointerDown({ setHadKeyboardEvent });
          },
          true,
        );
        document.removeEventListener(
          'touchstart',
          () => {
            onPointerDown({ setHadKeyboardEvent });
          },
          true,
        );
        document.removeEventListener(
          'visibilitychange',
          () => {
            // eslint-disable-next-line
            onVisibilityChange(event, { setHadKeyboardEvent });
          },
          true,
        );

        removeInitialPointerMoveListeners({ setHadKeyboardEvent });
      };
    },
    [setHadKeyboardEvent],
  );

  return (
    <FocusVisibleContext.Provider value={{ hadKeyboardEvent, isInitialized: true }}>
      {props.children}
    </FocusVisibleContext.Provider>
  );
};
