import merge from 'lodash/merge';

import { pixelsToRem } from 'MoshtixShared/helper-pixels-to-rem';
import { resolveToObject } from 'MoshtixShared/helper-resolve-to-object';

import { DropdownMenuContentProps } from './index';

const containerCss: {} = ({ props }: { props: DropdownMenuContentProps }) =>
  merge(
    {
      padding: `${pixelsToRem(12)} ${pixelsToRem(16)}`,
      width: pixelsToRem(210),
      maxWidth: '100%',
      textAlign: 'left',
    },
    resolveToObject({ input: props.css, params: { props } }), // css passed to option
  );

export const styles: {} = {
  containerCss,
};
