import configDefault from './config.default';

const environmentVariables = {
  ...configDefault,
  graphqlUrl: 'https://api.moshtix.com/v1/graphql',
  adminOriginalUrl: 'admin.moshtix.com.au',
  controlRoomUrl: 'control-room.moshtix.com.au',
  publicWebsiteOriginalUrl: 'moshtix.co.nz',
  environment: 'production-nz',
  cookieDomain: '.moshtix.co.nz',
  myUrl: 'my.moshtix.co.nz',
};

export default environmentVariables;
